import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';

import './HxPopupSaveChanges.scss'

// props
//
// callbacks
//      onInitialized: callback
//      onClick: callback


class HxPopupSaveChanges extends React.Component {
    constructor(props) {
        super(props);

        this.popupInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onClick = (result) => {
        if (this.props.onClick) {
            this.props.onClick(result);  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    // BUTTONS
    buttonYes_OnClick = (e) => {
        this.onClick("yes");
        this.popupInstance.hide();
    }

    buttonNo_OnClick = (e) => {
        this.onClick("no");
        this.popupInstance.hide();
    }

    buttonCancel_OnClick = (e) => {
        this.onClick("cancel");
        this.popupInstance.hide();
    }

    // RENDERING
    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={false}
                    showTitle={true}
                    title='Save changes?'
                    width={250}
                    height={122}
                    onInitialized={this.popup_OnInitialized}
                >
                    <div>
                        <div className="hpsc-text-div">Save Changes?</div>
                        <div className="hpsc-buttons-div">
                            <div>
                                <Button
                                    text="Yes"
                                    onClick={this.buttonYes_OnClick}
                                />
                            </div>
                            <div className="hpsc-button-no">
                                <Button
                                    text="No"
                                    onClick={this.buttonNo_OnClick}
                                />
                            </div>
                            <div className="hpsc-button-cancel">
                                <Button
                                    text="Cancel"
                                    onClick={this.buttonCancel_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
            </React.Fragment>
        );
    }
}

export default HxPopupSaveChanges

