import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { CustomRule, ValidationRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';

import validFilename from 'valid-filename';


import './HxPopupExportExcel.scss'

// props
//      defaultName: default excel name
//
//      onInitialized: callback
//      onSave: callback

function createNewFileName(f) {
    let d = new Date();
    return f + '_' + d.getDate().toString().padStart(2, '0') + '_' + (d.getMonth() + 1).toString().padStart(2, '0') + '_' + d.getFullYear() + '.xlsx';
}

class HxPopupExportExcel extends React.Component {
    constructor(props) {
        super(props);

        this.popupInstance = null;

        this.textBoxNameInstance = null;

        this.validationGroupInstance = null;

        this.fileName = '';
    }

    textBoxAttributes = {
        id: 'textBoxFileNameInput',
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onSave = (fileName) => {
        if (this.props.onSave) {
            this.props.onSave(fileName);  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    popup_OnShowing = (e) => {
        let f = (this.props.defaultName ? this.props.defaultName : '');
        this.fileName = createNewFileName(f);
    }

    popup_OnShown = (e) => {
        this.textBoxNameInstance.option('value', this.fileName);

        document.getElementById('textBoxFileNameInput').select();

        this.textBoxNameInstance.focus();
    }

    // TEXTBOX
    textBoxName_OnInitialized = (e) => {
        this.textBoxNameInstance = e.component;
    }

    // BUTTON
    buttonSave_OnClick = async (e) => {
        await this.validationGroupInstance.validate();

        if (this.textBoxNameInstance.option('isValid') === true) {
            this.onSave(this.textBoxNameInstance.option('value').trim().replace(/.xlsx$/gi, '') + '.xlsx');
            this.popupInstance.hide();
        } else {
            this.textBoxNameInstance.focus();
        }
    }

    // VALIDATION
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateFileName = () => {
        let isValid = false;

        if (validFilename(this.fileName)) {
            isValid = true;
        }

        return isValid;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    title='Export as ...'
                    width={300}
                    height={139}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                >
                    <ValidationGroup onInitialized={this.validationGroup_OnInitialized}>
                        <div>
                            <div>
                                <TextBox
                                    onInitialized={this.textBoxName_OnInitialized}
                                    defaultValue={this.fileName}
                                    inputAttr={this.textBoxAttributes}
                                    hint='Enter name ...'
                                >
                                    <Validator>
                                        <ValidationRule type="required" message="Required" />
                                        <CustomRule validationCallback={this.validateFileName} message="Invalid file name" />
                                    </Validator>
                                </TextBox>
                            </div>

                            <div className='hx-popup-export-excel-button'>
                                <Button
                                     text="Export"
                                    onClick={this.buttonSave_OnClick}
                                />
                            </div>
                        </div>
                    </ValidationGroup>
                </Popup>
            </React.Fragment>
        );
    }
}

export default HxPopupExportExcel

