import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, MasterDetail } from 'devextreme-react/data-grid';

import HxDialog from '../../../../custom-components/hx-dialog/HxDialog'
import HxToast from '../../../../custom-components/hx-toast/HxToast';

import ServiceShuttleArtists from '../../../../../api/services/ServiceShuttlesArtists';

import './DataGridShuttlesArtistContacts.scss'

// callables
//
// callbacks
//      onInitialized
//      onDBError

const COLFIRST = <Column
    key='firstName'
    dataField='firstName'
    dataType='string'
    caption='First name'
    width='250px'
    allowEditing={false}
    allowExporting={true}
    allowFiltering={true}
    allowHeaderFiltering={true}
    showInColumnChooser={true}
/>;
const COLLAST = <Column
    key='lastName'
    dataField='lastName'
    dataType='string'
    caption='Last name'
    width='250px'
    allowEditing={false}
    allowExporting={true}
    allowFiltering={true}
    allowHeaderFiltering={true}
    showInColumnChooser={true}
/>;
class DataGridShuttlesArtistContacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceShuttlesArtists: [],
            artistId: -1,
            showFilter: false
        }

        this.gridInstance = null;
        this.popupInstance = null;
        this.popupExportExcelInstance = null;

        this.colsFirstLast = [COLFIRST, COLLAST];
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    // CALLABLES

    // EVENTS
    componentDidMount = async () => {
        this.setState({ dataSourceShuttlesArtists: this.props.data.data.artistContacts });
        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.colsFirstLast = userSettingsGeneral.reverseNameOrder === true ? [COLLAST, COLFIRST] : [COLFIRST, COLLAST];
    }

    // DATA
    loadDataSourceShuttlesArtistContacts = async () => {
        this.gridInstance.beginCustomLoading();

        var data = await ServiceShuttleArtists.getArtistContactsByArtistId(this.props.data.data.id);
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceShuttlesArtists: data });
        } else {
            this.onDBError('Database error!');  // callback
        }

        this.gridInstance.endCustomLoading();
    }

    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    deleteShuttlesArtist = async (id) => {
        var resultShuttleArtists = await ServiceShuttleArtists.deleteArtist(id);
        if (!resultShuttleArtists.hasOwnProperty("error")) {
            if (resultShuttleArtists === true) {
                await this.loadDataSourceShuttlesArtistContacts();
                HxToast.showToast('Artist deleted', 'success', 'top right', 'up-stack');
            } else {
                HxToast.showToast('Delete artist failed!', 'error', 'top right', 'up-stack');
            }
        } else {
            this.onDBError('Database error!'); // callback
            HxToast.showToast('Delete artist failed!', 'error', 'top right', 'up-stack');
        }
    }

    // POPUP CREATE/UPDATE
    popup_onInitialized = (instance) => {
        this.popupInstance = instance;
    }

    popup_onDBError = (message) => {
        this.onDBError(message)
    }

    popup_OnCreate = async (id) => {
        if (id !== -1) {
            await this.loadDataSourceShuttlesArtistContacts();
            HxToast.showToast('Artist created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create artist failed!', 'error', 'top right', 'up-stack');
        }
    }

    popup_OnUpdate = async (success) => {
        if (success === true) {
            await this.loadDataSourceShuttlesArtistContacts();
            HxToast.showToast('Artist updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update artist failed!', 'error', 'top right', 'up-stack');
        }
    }

    // RENDERING
    render() {
        return (
            <React.Fragment>
                <div className="dgsac-main-div">
                    <div className='dx-card dgsac-grid'>
                        <div className="dgsac-grid-div">
                            <DataGrid
                                ref={ref => this.refGrid = ref}
                                dataSource={this.state.dataSourceShuttlesArtists}
                                keyExpr='id'
                                height='100%'
                                width='100%'
                                allowColumnReordering={false}
                                allowColumnResizing={false}
                                cacheEnabled={true}
                                columnAutoWidth={false}
                                columnResizingMode='widget'
                                filterSyncEnabled={true}
                                focusedRowEnabled={false}
                                hoverStateEnabled={true}
                                noDataText='No contacts found.'
                                remoteOperations={true}
                                repaintChangesOnly={true}
                                showBorders={true}
                                showColumnLines={true}
                                showRowLines={true}
                                showColumnHeaders={false}
                                wordWrapEnabled={false}
                                onInitialized={this.gridOnInitialized}
                            >
                                <StateStoring enabled={false} type="localStorage" storageKey="storageShuttlesArtistContactsDataGrid" />
                                <Sorting mode='multiple' />
                                <ColumnChooser enabled={false} />
                                <Paging enabled={false} defaultPageSize={20} />
                                <FilterPanel visible={false} />
                                <FilterRow visible={false} />
                                <GroupPanel visible={false} />
                                <HeaderFilter visible={false} />
                                <SearchPanel visible={false} />
                                <Scrolling
                                    mode="standard"
                                    columnRenderingMode="standard"
                                    rowRenderingMode="standard"
                                    showScrollBar='onHover'
                                    preloadEnabled={true}
                                />
                                <Column
                                    dataField='id'
                                    dataType='number'
                                    caption='Id'
                                    fixed={true}
                                    showInColumnChooser={false}
                                    visible={false}
                                />
                                {this.colsFirstLast}
                                <Column
                                    dataField='email'
                                    dataType='string'
                                    caption='Email'
                                    width='250px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />
                                <Column
                                    dataField='tel'
                                    dataType='string'
                                    caption='Tel'
                                    width='200px'
                                    allowEditing={false}
                                    allowExporting={true}
                                    allowFiltering={true}
                                    allowHeaderFiltering={true}
                                    showInColumnChooser={true}
                                />

                            </DataGrid>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );

    }
}

export default DataGridShuttlesArtistContacts;

