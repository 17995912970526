import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import { NumberBox } from 'devextreme-react/number-box';

import globalIcons from '../../../../../config/globalIcons.js';

import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import ServiceFleet from '../../../../../api/services/ServiceFleet';

import './PopupFleet.scss'

// props
//
// callables
//
// callbacks
//      onInitialized
//      onDBError
//      onCreate
//      onUpdate

class PopupFleet extends React.Component {
    constructor(props) {
        super(props);

        this.fleetId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxTagInstance = null;
        this.textBoxTypeInstance = null;
        this.textBoxPaxInstance = null;
        this.textBoxLicencePlateInstance = null;
        this.dateBoxAvailableFromInstance = null;
        this.dateBoxAvailableToInstance = null;
        this.checkBoxTowBarInstance = null;

        this.fleetRecord = {};

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    } 
    
    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.fleetId = id;
        await this.fillPopup(id);
    }

    // EVENTS


    // DATA
    loadDataSourceVehicle = async (id) => {
        var data = await ServiceFleet.getVehicle(id);
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.fleetRecord = data;
            } else {
                this.onDBError('Vehicle not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.fleetId === -1) {
            // new event
            this.checkBoxKeepOpenInstance.option('visible', true)
            this.setPopupTitle();

        } else {
            // update event
            this.checkBoxKeepOpenInstance.option('visible', false)
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxTagInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (fleetId) => {
        await this.loadDataSourceVehicle(fleetId);

        this.textBoxTagInstance.option('value', this.fleetRecord.tag);
        this.textBoxTypeInstance.option('value', this.fleetRecord.type);
        this.textBoxPaxInstance.option('value', this.fleetRecord.pax);
        this.textBoxLicencePlateInstance.option('value', this.fleetRecord.licencePlate);
        this.dateBoxAvailableFromInstance.option('value', this.fleetRecord.availableFrom);
        this.dateBoxAvailableToInstance.option('value', this.fleetRecord.availableTo);
        this.checkBoxTowBarInstance.option('value', this.fleetRecord.towBar);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.fleetId = -1;

        await this.fillPopup(this.fleetId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.textBoxTagInstance.focus();
    }

    setPopupTitle = () => {
        if (this.fleetId === -1) {
            this.popupInstance.option('title', 'Create vehicle' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.fleetRecord.type + ' (' + this.fleetRecord.licencePlate + ')' + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateVehicle();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX TAG
    textBoxTag_OnInitialized = (e) => {
        this.textBoxTagInstance = e.component;
    }

    // TEXTBOX TYPE
    textBoxType_OnInitialized = (e) => {
        this.textBoxTypeInstance = e.component;
    }

    // TEXTBOX PAX
    textBoxPax_OnInitialized = (e) => {
        this.textBoxPaxInstance = e.component;
    }

    // TEXTBOX LICENCE PLATE
    textBoxLicencePlate_OnInitialized = (e) => {
        this.textBoxLicencePlateInstance = e.component;
    }

    // DATEBOX AVAILABLEFROM
    dateBoxAvailableFrom_OnInitialized = (e) => {
        this.dateBoxAvailableFromInstance = e.component;
    }

    // DATEBOX AVAILABLETO
    dateBoxAvailableTo_OnInitialized = (e) => {
        this.dateBoxAvailableToInstance = e.component;
    }

    // CHECKBOX TOWBAR
    checkBoxTowbar_OnInitialized = (e) => {
        this.checkBoxTowBarInstance = e.component;
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateVehicle();
    }

    updateVehicle = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resTag = await this.validateTag();
        var resLicencePlate = await this.validateLicencePlate();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resTag === true && resLicencePlate === true) {
            this.fleetRecord.tag = this.textBoxTagInstance.option('value');
            this.fleetRecord.type = this.textBoxTypeInstance.option('value');
            this.fleetRecord.pax = this.textBoxPaxInstance.option('value');
            this.fleetRecord.licencePlate = this.textBoxLicencePlateInstance.option('value');
            this.fleetRecord.availableFrom = this.dateBoxAvailableFromInstance.option('value');
            this.fleetRecord.availableTo = this.dateBoxAvailableToInstance.option('value');
            this.fleetRecord.towBar = this.checkBoxTowBarInstance.option('value');

            if (this.fleetId === -1) {
                // new vehicle
                var resultFleet = await ServiceFleet.createVehicle(this.fleetRecord);
                if (!resultFleet.hasOwnProperty("error")) {
                    this.onCreate(resultFleet); // callback
                    if (resultFleet !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update vehicle
                var resultVehicleUpdate = await ServiceFleet.updateVehicle(this.fleetRecord);
                if (!resultVehicleUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultVehicleUpdate); // callback
                    if (resultVehicleUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxTagInstance.focus();
        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateLicencePlate = async () => {
        var result = await ServiceFleet.doesLicencePlateExist(this.textBoxLicencePlateInstance.option('value'), this.fleetRecord.id);
        if (!result.hasOwnProperty("error")) {
            this.textBoxLicencePlateInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError("Database error!");  // callback
            return false;
        }
    }

    validateTag = async () => {
        var result = await ServiceFleet.doesTagExist(this.textBoxTagInstance.option('value'), this.fleetRecord.id);
        if (!result.hasOwnProperty("error")) {
            this.textBoxTagInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError("Database error!");  // callback
            return false;
        }
    }

    validateDates = () => {
        var valStart = this.dateBoxAvailableFromInstance.option("value");
        var valEnd = this.dateBoxAvailableToInstance.option("value");
        if (valStart !== null && valEnd !== null) {
            const startDate = new Date(valStart).getTime();
            const endDate = new Date(valEnd).getTime();

            return (startDate <= endDate);
        }

        return true;
    }

    validatePax = () => {
        return this.textBoxPaxInstance.option('value') !== null && this.textBoxPaxInstance.option('value') > 0;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    width={490}
                    height={415}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="dx-field">
                                    <div className="dx-field-label pf-field-label">Tag*</div>
                                    <div className="dx-field-value pf-field-value">
                                        <TextBox
                                            width='200px'
                                            onInitialized={this.textBoxTag_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <AsyncRule validationCallback={this.validateTag} message="Tag exists" />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pf-field-label">Licence plate*</div>
                                    <div className="dx-field-value pf-field-value">
                                        <TextBox
                                            width='200px'
                                            onInitialized={this.textBoxLicencePlate_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <AsyncRule validationCallback={this.validateLicencePlate} message="Licence plate exists" />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>

                                <div className="dx-field">
                                    <div className="pf-field-value-type-pax">
                                        <div className="dx-field-label pf-field-label">Type*</div>
                                        <div className="dx-field-value pf-field-value">
                                            <TextBox
                                                width='250px'
                                                onInitialized={this.textBoxType_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                                onValueChanged={this.input_ValueChanged}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="pf-field-value-type-pax">
                                        <div className="dx-field-label pf-field-label pf-field-label-pax">Pax*</div>
                                        <div className="dx-field-value pf-field-value">
                                            <NumberBox
                                                width='60px'
                                                onInitialized={this.textBoxPax_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onValueChanged={this.input_ValueChanged}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <CustomRule validationCallback={this.validatePax} message="Pax must be > 0" />
                                                </Validator>
                                            </NumberBox>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pf-field-label">Available from*</div>
                                    <div className="dx-field-value pf-field-value-date-boxes">
                                        <DateBox
                                            pickerType='native'
                                            onInitialized={this.dateBoxAvailableFrom_OnInitialized}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <CustomRule validationCallback={this.validateDates} message="Invalid dates" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pf-field-label">Available to*</div>
                                    <div className="dx-field-value pf-field-value-date-boxes">
                                        <DateBox
                                            pickerType='native'
                                            onInitialized={this.dateBoxAvailableTo_OnInitialized}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <CustomRule validationCallback={this.validateDates} message="Invalid dates" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                </div>
                                <div className="dx-field">
                                    <div className="dx-field-label pf-field-label">Towbar</div>
                                    <div className="dx-field-value pf-field-value-towbar">
                                        <CheckBox
                                            text=""
                                            onInitialized={this.checkBoxTowbar_OnInitialized}
                                            onValueChanged={this.input_ValueChanged}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="pf-buttons">
                                <div className="pf-buttons-checkbox">
                                    <CheckBox
                                        text="Keep open"
                                        onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                        onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                                    />
                                </div>
                                <div className='pf-buttons-update'>
                                    <div className="pf-button-update">
                                        <Button
                                            onInitialized={this.buttonUpdate_OnInitialized}
                                            icon={globalIcons.save}
                                            onClick={this.buttonUpdate_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                   </ValidationGroup>
                    <HxPopupSaveChanges
                        onInitialized={this.popupSaveChanges_onInitialized}
                        onClick={this.popupSaveChanges_onClick}
                    />
                </Popup>
            </React.Fragment>
        );

    }
}

export default PopupFleet

