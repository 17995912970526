import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, StringLengthRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';

import ServiceUsers from '../../../api/services/ServiceUsers';

import globalConfig from '../../../config/global.js';
import globalIcons from '../../../config/globalIcons.js';

import './PopupUsers.scss'

// props
//
// callables
//
// callbacks
//      onInitialized
//      onDBError
//      onCreate
//      onUpdate

class PopupUsers extends React.Component {
    constructor(props) {
        super(props);

        this.popupInstance = null;
        this.userId = -1;

        this.textBoxEmailInstance = null;
        this.textBoxPasswordInstance = null;
        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.checkBoxIsSuperAdminInstance = null;
        this.checkBoxIsRidersAdminInstance = null;
        this.checkBoxIsRidersUserInstance = null;
        this.checkBoxIsShuttlesAdminInstance = null;
        this.checkBoxIsShuttlesUserInstance = null;

        this.userRecord = {};
        this.userDefaultRecord = {
            id: -1,
            email: '',
            password: '',
            avatarUrl: null,
            firstName: null,
            lastName: null,
            isSuperAdmin: false,
            isRidersAdmin: false,
            isRidersUser: false,
            isShuttlesAdmin: false,
            isShuttlesUser: false,
            created: null,
            createdBy: null,
            updated: null,
            updatedBy: null,
            deleted: null,
            deletedBy: null
        }

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    } 
    
    // CALLABLES
    preload = async (id) => {
        this.userId = id;
    }

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceUser = async (id) => {
        var data = await ServiceUsers.getUserForUpdate(id);
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.userRecord = data;
            } else {
                this.onDBError('User not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }
  
    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.userId === -1) {
            // new event
            this.resetPopup();

            this.popupInstance.option('title', 'Create user');
        } else {
            // update event
            await this.loadDataSourceUser(this.userId);

            this.textBoxEmailInstance.option('value', this.userRecord.email);
            this.textBoxPasswordInstance.option('value', this.userRecord.password);
            this.textBoxFirstNameInstance.option('value', this.userRecord.firstName);
            this.textBoxLastNameInstance.option('value', this.userRecord.lastName);
            this.checkBoxIsSuperAdminInstance.option('value', this.userRecord.isSuperAdmin);
            this.checkBoxIsRidersAdminInstance.option('value', this.userRecord.isRidersAdmin);
            this.checkBoxIsRidersUserInstance.option('value', this.userRecord.isRidersUser);
            this.checkBoxIsShuttlesAdminInstance.option('value', this.userRecord.isShuttlesAdmin);
            this.checkBoxIsShuttlesUserInstance.option('value', this.userRecord.isShuttlesUser);
            var image = document.getElementById('userImage'); 
            if (this.userRecord.avatarUrl != null) {
                image.src = globalConfig.settings.images.userImagesPath + this.userRecord.avatarUrl;
            } else {
                image.src = globalConfig.settings.images.userImagesPath + "default_250.jpg";
            }
            this.checkBoxKeepOpenInstance.option('visible', false)

            this.popupInstance.option('title', 'Update ' + this.userRecord.email);
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
        this.dataHasChanged = false;

        this.textBoxEmailInstance.focus();
    }

    popup_OnHidden = (e) => {
        this.resetPopup();
    }

    resetPopup = async () => {
        this.userId = -1;
        this.userRecord = this.userDefaultRecord;

        this.textBoxEmailInstance.option('value', null);
        this.textBoxPasswordInstance.option('value', null);
        this.textBoxFirstNameInstance.option('value', null);
        this.textBoxLastNameInstance.option('value', null);
        this.checkBoxIsSuperAdminInstance.option('value', false);
        this.checkBoxIsRidersAdminInstance.option('value', false);
        this.checkBoxIsRidersUserInstance.option('value', false);
        this.checkBoxIsShuttlesAdminInstance.option('value', false);
        this.checkBoxIsShuttlesUserInstance.option('value', false);
        var image = document.getElementById('userImage');
        image.src = globalConfig.settings.images.userImagesPath + "defaultUser_250.jpg";

        this.checkBoxKeepOpenInstance.option('visible', true)

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;

        if (this.userId === -1) {
            this.popupInstance.option('title', 'Create user*');
        } else {
            this.popupInstance.option('title', 'Update ' + this.userRecord.email + '*');
        }
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX NAME
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX PASSWORD
    textBoxPassword_OnInitialized = (e) => {
        this.textBoxPasswordInstance = e.component;
    }

    // TEXTBOX FIRST NAME
    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // CHECKBOX IS SUPERADMIN
    checkBoxIsSuperAdmin_OnInitialized = (e) => {
        this.checkBoxIsSuperAdminInstance = e.component;
    }

    // CHECKBOX IS RIDERSADMIN
    checkBoxIsRidersAdmin_OnInitialized = (e) => {
        this.checkBoxIsRidersAdminInstance = e.component;
    }

    // CHECKBOX IS RIDERSUSER
    checkBoxIsRidersUser_OnInitialized = (e) => {
        this.checkBoxIsRidersUserInstance = e.component;
    }

    // CHECKBOX IS SHUTTLESADMIN
    checkBoxIsShuttlesAdmin_OnInitialized = (e) => {
        this.checkBoxIsShuttlesAdminInstance = e.component;
    }

    // CHECKBOX ISSHUTTLESUSER
    checkBoxIsShuttlesUser_OnInitialized = (e) => {
        this.checkBoxIsShuttlesUserInstance = e.component;
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateUser();
    }

    updateUser = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resEmail = await this.validateEmail();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resEmail === true) {
            this.userRecord.email = this.textBoxEmailInstance.option('value');
            this.userRecord.password = this.textBoxPasswordInstance.option('value');
            this.userRecord.firstName = this.textBoxFirstNameInstance.option('value');
            this.userRecord.lastName = this.textBoxLastNameInstance.option('value');
            this.userRecord.isSuperAdmin = this.checkBoxIsSuperAdminInstance.option('value');
            this.userRecord.isRidersAdmin = this.checkBoxIsRidersAdminInstance.option('value');
            this.userRecord.isRidersUser = this.checkBoxIsRidersUserInstance.option('value');
            this.userRecord.isShuttlesAdmin = this.checkBoxIsShuttlesAdminInstance.option('value');
            this.userRecord.isShuttlesUser = this.checkBoxIsShuttlesUserInstance.option('value');

            if (this.userId === -1) {
                // new user
                var resultUser = await ServiceUsers.createUser(this.userRecord);
                if (!resultUser.hasOwnProperty("error")) {
                    this.onCreate(resultUser); // callback
                    if (resultUser !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.popupInstance.option('title', 'Create event');
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // edit user
                var resultUserUpdate = await ServiceUsers.updateUser(this.userRecord);
                if (!resultUserUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultUserUpdate); // callback
                    if (resultUserUpdate === true) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.popupInstance.option('title', 'Create event');
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxEmailInstance.focus();
        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateEmail = async () => {
        var result = await ServiceUsers.doesEmailExist(this.textBoxEmailInstance.option('value'), this.userRecord.id);
        if (!result.hasOwnProperty("error")) {
            this.textBoxEmailInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError("Database error!");  // callback
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    width={586}
                    height={417}
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onHidden={this.popup_OnHidden}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="pu-info-div">
                                    <div className="pu-info-left-div">
                                        <div className="pu-avatar">
                                            <img id="userImage" src="" alt="" />
                                        </div>
                                    </div>
                                    <div className="pu-info-right-div">
                                        <div className="dx-field">
                                            <div className="dx-field-label pu-field-label">Email*</div>
                                            <div className="dx-field-value pu-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxEmail_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                        <AsyncRule validationCallback={this.validateEmail} message="Email exists" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label pu-field-label">Password*</div>
                                            <div className="dx-field-value pu-field-value">
                                                <TextBox
                                                    width='250px'
                                                    mode="password"
                                                    onInitialized={this.textBoxPassword_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                        <StringLengthRule min={8} message="Min 8 characters" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>

                                        <div className="dx-field">
                                            <div className="dx-field-label pu-field-label">First Name</div>
                                            <div className="dx-field-value pu-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field">
                                            <div className="dx-field-label pu-field-label">Last Name</div>
                                            <div className="dx-field-value pu-field-value">
                                                <TextBox
                                                    width='200px'
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="dx-card pu-roles">
                                    <div className="pu-checkboxes-title-div">
                                        <div className="pu-checkboxes-left-div"><b>Super</b></div>
                                        <div className="pu-checkboxes-center-div"><b>Riders</b></div>
                                        <div className="pu-checkboxes-right-div"><b>Shuttles</b></div>
                                    </div>
                                    <div className="pu-checkboxes-div">
                                        <div className="pu-checkboxes-left-div">
                                            <span className="pu-checkboxes-span">
                                                <CheckBox
                                                    text="Super Admin"
                                                    onInitialized={this.checkBoxIsSuperAdmin_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </span>
                                        </div>
                                        <div className="pu-checkboxes-center-div">
                                            <span className="pu-checkboxes-span">
                                                <CheckBox
                                                    text="Riders Admin"
                                                    onInitialized={this.checkBoxIsRidersAdmin_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </span>
                                            <span className="pu-checkboxes-span">
                                                <CheckBox
                                                    text="Riders User"
                                                    onInitialized={this.checkBoxIsRidersUser_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </span>
                                        </div>
                                        <div className="pu-checkboxes-right-div">
                                            <span className="pu-checkboxes-span">
                                                <CheckBox
                                                    text="Shuttles Admin"
                                                    onInitialized={this.checkBoxIsShuttlesAdmin_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </span>
                                            <span className="pu-checkboxes-span">
                                                <CheckBox
                                                    text="Shuttles User"
                                                    onInitialized={this.checkBoxIsShuttlesUser_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </span>
                                        </div>
                                    </div>      </div>


                            </div>
                            <div className="pu-buttons">
                                <div className="pu-buttons-checkbox">
                                    <CheckBox
                                        text="Keep open"
                                        onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                        onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                                    />
                                </div>
                                <div className='pu-buttons-update'>
                                    <div className="pu-button-update">
                                        <Button
                                            onInitialized={this.buttonUpdate_OnInitialized}
                                            icon={globalIcons.save}
                                            onClick={this.buttonUpdate_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                   </ValidationGroup>

                </Popup>
            </React.Fragment>
        );

    }
}

export default PopupUsers

