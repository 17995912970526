import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import List from 'devextreme-react/list';
import ServiceShuttlesArtists from '../../../../../api/services/ServiceShuttlesArtists'
import ServiceUserSettings from '../../../../../api/services/ServiceUserSettings'

import globalIcons from '../../../../../config/globalIcons.js';

import './PopupShuttlesArtistAvailableContacts.scss'

// props
//
// callables
//
// callbacks
//      onInitialized
//      onUpdate
//      onDBError

class PopupShuttlesArtistAvailableContacts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceAvailableArtistContacts: [],
        }

        this.popupInstance = null;
        this.listInstance = null;

        this.artistContactsToIgnore = '';
        this.reverseNameOrder = false;

        this.buttonUpdateInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onUpdate = (items) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(items);  // callback
        }
    }

    // CALLABLES
    preload = async (artistContactsToIgnore) => {
        this.artistContactsToIgnore = artistContactsToIgnore;
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.reverseNameOrder = this.userSettingsGeneral.reverseNameOrder;
        await this.loadDataSourceAvailableArtistContacts(artistContactsToIgnore, this.reverseNameOrder);
        this.listInstance.repaint();
    }

    // EVENTS


    // DATA
    loadDataSourceAvailableArtistContacts = async (artistContactsToIgnore, reverseNameOrder) => {
        var data = await ServiceShuttlesArtists.getAvailableArtistContactsForArtist(artistContactsToIgnore, reverseNameOrder);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceAvailableArtistContacts: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
        this.listInstance.unselectAll();
    }

    popup_OnShown = async (e) => {
        this.listInstance.focus();
    }

    // LIST
    list_OnInitialized = (e) => {
        this.listInstance = e.component;
    }

    // BUTTON SWITCH NAMES
    buttonSwitchNames_OnInitialized = (e) => {
        this.buttonSwitchNamesInstance = e.component;
    }

    buttonSwitchNames_OnClick = async (e) => {
        this.reverseNameOrder = !this.reverseNameOrder;
        await this.loadDataSourceAvailableArtistContacts(this.artistContactsToIgnore, this.reverseNameOrder);
        this.userSettingsGeneral.reverseNameOrder = this.reverseNameOrder;
        //var resultSettings = await ServiceUserSettings.updateUserSettingsGeneral(this.userSettingsGeneral);
        //if (!resultSettings.hasOwnProperty("error")) {
        //    if (resultSettings === true) {
        //        this.userSettingsGeneral.reverseNameOrder = this.reverseNameOrder;
        //        localStorage.setItem('userSettingsGeneral', JSON.stringify(this.userSettingsGeneral));
        //    }
        //} else {
        //    this.onDBError("Database error!");  // callback
        //}       
    }

    // BUTTON SELECT
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = (e) => {
        this.onUpdate(this.listInstance.option('selectedItems'))
        this.popupInstance.hide();
    }

    // RENDERING
    renderListItem = (itemData) => {

        if (this.reverseNameOrder) {
            return (
                <div>
                    <div>
                        {itemData.lastName} {itemData.firstName}
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>
                        {itemData.firstName} {itemData.lastName}
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={350}
                    height={508}
                    showCloseButton={true}
                    title='Available contacts'
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                >
                    <div className="paac-main-div">
                        <List
                            dataSource={this.state.dataSourceAvailableArtistContacts}
                            height='100%'
                            keyExpr='id'
                            hoverStateEnabled={true}
                            pageLoadMode='scrollBottom'
                            showSelectionControls={true}
                            selectionMode='multiple'
                            selectAllMode='allPages'
                            searchEnabled={true}
                            searchExpr={["firstName", "lastName"]}
                            itemRender={this.renderListItem}
                            onInitialized={this.list_OnInitialized}
                        >
                        </List>
                    </div>

                    <div className="paac-buttons">
                        <div className='paac-buttons-update'>
                            <div className="paac-button-switch-name-order">
                                <Button
                                    text='Switch names'
                                    width={120}
                                    onInitialized={this.buttonSwitchNames_OnInitialized}
                                    icon={globalIcons.switch_name_order}
                                    onClick={this.buttonSwitchNames_OnClick}
                                />
                            </div>
                            <div className="paac-button-update">
                                <Button
                                    text='Select'
                                    width={74}
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon={globalIcons.save}
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>

            </React.Fragment>
        );
    }

}

export default PopupShuttlesArtistAvailableContacts

