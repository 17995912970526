import React from 'react';
import { ShuttlesAdminDrivers } from '../../../../components';

class shuttlesAdminDrivers extends React.Component {
    render() {
        return (
            <ShuttlesAdminDrivers />
        );
    }
}

export default shuttlesAdminDrivers