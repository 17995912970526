import axiosapi from '../axiosapi.js';

class ServiceGeneral {

    static getCountriesDropDown = async () => {
        let returnValue = [];

        await axiosapi.get('/api/General/GetCountriesDropDown')
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

}

export default ServiceGeneral;
