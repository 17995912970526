import React from 'react';
import DataGrid, {
    RemoteOperations, Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, Export, StateStoring, Sorting, Toolbar, LoadPanel} from 'devextreme-react/data-grid';

import './DataGridEventGigDates.scss'

// callables
//      preload
// callbacks
//      onInitialized
//      onDBError

class DataGridEventGigDates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceGigDates: [],
        }

        this.gridInstance = null;
        this.popupInstance = null;
 
        this.highlightSelectedCells = true;
    
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    // CALLABLES
    updateDS = (ds) => {
        this.setState({ dataSourceGigDates: ds });
        this.gridInstance.repaint();
    }

    // EVENTS


    // DATA


    // GRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.onInitialized(e.component);  // callback
    }

    gridOnFocusedCellChanging = (e) => {
        // if we have a valid row and highlight is enabled and hoverstate is disabled then set  focus css class
        if (e.rowIndex !== -1 && this.highlightSelectedCells && this.gridInstance.option('hoverStateEnabled') === false) {
            let elements = document.getElementsByClassName("hx-focused");
            if (elements) {
                for (var i = 0; i < elements.length; i++) {
                    elements[i].classList.remove('hx-focused');
                }
            }
            e.cellElement[0].classList.add('hx-focused');
        }
    }

    gridOnKeyDown = (e) => {
        // if hoverstate is enabled or highlight is disabled then ignore keyboard navigation
        if (!this.highlightSelectedCells || this.gridInstance.option('hoverStateEnabled') === true) {
            e.handled = true;
        }
    }

    // RENDERING
    render() {
        return (
            <React.Fragment>
                <div className="dgegd-main-div">
                    <div className="dgegd-grid-div">
                        <DataGrid
                            ref={ref => this.refGrid = ref}
                            dataSource={this.state.dataSourceGigDates}
                            keyExpr='id'
                            height='100%'
                            allowEditing={true}
                            allowColumnReordering={false}
                            allowColumnResizing={false}
                            cacheEnabled={true}
                            columnAutoWidth={false}
                            columnResizingMode='widget'
                            filterSyncEnabled={true}
                            focusedRowEnabled={false}
                            hoverStateEnabled={false}
                            noDataText='No dates found.'
                            remoteOperations={true}
                            repaintChangesOnly={true}
                            showBorders={true}
                            showColumnLines={true}
                            showRowLines={true}
                            wordWrapEnabled={false}
                            onInitialized={this.gridOnInitialized}
                            onFocusedCellChanging={this.gridOnFocusedCellChanging}
                            onKeyDown={this.gridOnKeyDown}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                allowAdding={false}
                                allowDeleting={false} />
                            <KeyboardNavigation
                                enabled={false}
                                editOnKeyPress={true}
                                enterKeyAction='moveFocus'
                                enterKeyDirection='column' />
                            <LoadPanel enabled={false} />
                            <StateStoring enabled={false} type="localStorage" storageKey="storageEventGigDatesDataGrid" />
                            <Sorting mode='multiple' />
                            <ColumnChooser enabled={false} />
                            <Paging enabled={false} defaultPageSize={20} />
                            <FilterPanel visible={false} />
                            <FilterRow visible={false} />
                            <GroupPanel visible={false} />
                            <HeaderFilter visible={false} />
                            <SearchPanel visible={false} />
                            <Scrolling
                                mode="standard"
                                columnRenderingMode="standard"
                                rowRenderingMode="standard"
                                showScrollBar='onHover'
                                preloadEnabled={true}
                            />
                            <Column
                                dataField='id'
                                dataType='number'
                                caption='Id'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='eventId'
                                dataType='number'
                                caption='EventId'
                                fixed={true}
                                showInColumnChooser={false}
                                visible={false}
                            />
                            <Column
                                dataField='gigDate'
                                dataType='date'
                                caption='Date'
                                format='dd/MM/yyyy'
                                width='100px'
                                allowEditing={false}
                                allowExporting={true}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowSorting={false}
                                showInColumnChooser={true}
                            />
                            <Column
                                dataField='useInRiders'
                                dataType='boolean'
                                caption='Riders'
                                width='75px'
                                allowEditing={true}
                                allowExporting={true}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowSorting={false}
                                showInColumnChooser={true}
                            />
                            <Column
                                dataField='useInShuttles'
                                dataType='boolean'
                                caption='Shuttles'
                                width='75px'
                                allowEditing={true}
                                allowExporting={true}
                                allowFiltering={false}
                                allowHeaderFiltering={false}
                                allowSorting={false}
                                showInColumnChooser={true}
                            />
                        </DataGrid>
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

export default DataGridEventGigDates

