import React from 'react';

import DataGridShuttlesArtists from './sub-components/DataGridShuttlesArtists';
import HxToast from '../../../custom-components/hx-toast/HxToast';

import './ShuttlesAdminArtists.scss';

class ShuttlesAdminArtists extends React.Component {
    constructor(props) {
        super(props);

        // filters
        this.loadedUserFilterValue = [];
    }

    gridShuttlesArtists_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    render() {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        if (userAuth.isSuperAdmin === false && userAuth.isShuttlesUser === false && userAuth.isShuttlesAdmin === false) {
            window.location.href = '/home';
        }

        return (
            <React.Fragment>
                <div className='content-block'>
                    <DataGridShuttlesArtists
                        onDBError={this.gridShuttlesArtists_OnDBError}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ShuttlesAdminArtists
