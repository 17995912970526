import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import HtmlEditor from 'devextreme-react/html-editor';
import { NumberBox } from 'devextreme-react/number-box';

import HxMap from '../../../../custom-components/hx-map/HxMap';
import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import ServiceLocations from '../../../../../api/services/ServiceLocations'
import ServiceGeneral from '../../../../../api/services/ServiceGeneral'
import ServiceGeoCoding from '../../../../../api/services/ServiceGeoCoding'

import globalIcons from '../../../../../config/globalIcons.js';

import './PopupLocations.scss'

// props
//
// callables
//      preload

// callbacks
//      onInitialized
//      onCreate
//      onUpdate
//      onDBError

class PopupLocations extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceCountries: [],
            dataSourceLocationTypes: [],
        }

        this.locationId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxLocationNameInstance = null;
        this.textBoxStreetInstance = null;
        this.textBoxStreetNumberInstance = null;
        this.textBoxZipCodeInstance = null;
        this.textBoxCityInstance = null;
        this.selectBoxCountriesInstance = null;
        this.textBoxEmailInstance = null;
        this.textBoxTelInstance = null;
        this.selectBoxLocationTypesInstance = null;
        this.textBoxShortNameInstance = null;
        this.checkBoxIsFavouriteInstance = null;
        this.textBoxGpsLatInstance = null;
        this.textBoxGpsLngInstance = null;
        this.htmlEditorNotesInstance = null;
        this.buttonAutoGpsInstance = null;

        this.gridInstance = null;

        this.locationRecord = {};
 
        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;

        this.popupSaveChangesInstance = null;

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.locationId = id;
        await this.loadDataSourceCountries();
        await this.loadDataSourceLocationTypes();
        await this.fillPopup(id);
    }

    // EVENTS

    // DATA
    loadDataSourceLocation = async (locationId) => {
        var data = await ServiceLocations.getLocation(locationId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.locationRecord = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceCountries = async () => {
        var data = await ServiceGeneral.getCountriesDropDown();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceCountries: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceLocationTypes = async () => {
        var data = await ServiceLocations.getLocationTypesDropDown(true);
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceLocationTypes: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.locationId === -1) {
            // new location
            this.checkBoxKeepOpenInstance.option('visible', true);
            this.setPopupTitle();
        } else {
            // update location
            this.checkBoxKeepOpenInstance.option('visible', false);
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxLocationNameInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (locationId) => {
        await this.loadDataSourceLocation(locationId);

        this.textBoxLocationNameInstance.option('value', this.locationRecord.locationName);
        this.textBoxStreetInstance.option('value', this.locationRecord.street);
        this.textBoxStreetNumberInstance.option('value', this.locationRecord.streetNumber);
        this.textBoxZipCodeInstance.option('value', this.locationRecord.zipCode);
        this.textBoxCityInstance.option('value', this.locationRecord.city);
        this.selectBoxCountriesInstance.option('value', this.locationRecord.countryCode !== null ? this.locationRecord.countryCode : -1);
        this.textBoxEmailInstance.option('value', this.locationRecord.email);
        this.textBoxTelInstance.option('value', this.locationRecord.tel);
        this.selectBoxLocationTypesInstance.option('value', this.locationRecord.locationTypeId);
        this.textBoxShortNameInstance.option('value', this.locationRecord.shortName);
        this.checkBoxIsFavouriteInstance.option('value', this.locationRecord.isFavourite);
        this.textBoxGpsLatInstance.option('value', this.locationRecord.gpsLat);
        this.textBoxGpsLngInstance.option('value', this.locationRecord.gpsLng);
        this.htmlEditorNotesInstance.option('value', this.locationRecord.notes);

        const lat = this.locationRecord.gpsLat;
        const lng = this.locationRecord.gpsLng;
        const defaultCenter = { lat: lat !== null ? lat : 0, lng: lng !== null ? lng : 0 };
        const tooltip = this.locationRecord.locationName !== null ? this.locationRecord.locationName : '';
        this.refMap.initialize('466px', '292px', defaultCenter, [], 10, [{ location: defaultCenter, tooltip: { text: tooltip } }]);

        this.textBoxShortNameInstance.option('visible', this.locationRecord.isFavourite === true);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.locationId = -1;

        await this.fillPopup(this.locationId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.textBoxLocationNameInstance.focus();
    }

    setPopupTitle = () => {
        if (this.locationId === -1) {
            this.popupInstance.option('title', 'Create location' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.locationRecord.locationName + (this.dataHasChanged === true ? '*' : ''));
        }
    }


    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }
    
    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX LOCATION NAME
    textBoxLocationName_OnInitialized = (e) => {
        this.textBoxLocationNameInstance = e.component;
    }

    // TEXTBOX STREET
    textBoxStreet_OnInitialized = (e) => {
        this.textBoxStreetInstance = e.component;
    }

    // TEXTBOX STREET NUMBER
    textBoxStreetNumber_OnInitialized = (e) => {
        this.textBoxStreetNumberInstance = e.component;
    }

    // TEXTBOX ZIP CODE
    textBoxZipCode_OnInitialized = (e) => {
        this.textBoxZipCodeInstance = e.component;
    }

    // TEXTBOX CITY
    textBoxCity_OnInitialized = (e) => {
        this.textBoxCityInstance = e.component;
    }

    // SELECTBOX COUNTRIES
    selectBoxCountries_OnInitialized = (e) => {
        this.selectBoxCountriesInstance = e.component;
    }

    // TEXTBOX EMAIL
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX TEL
    textBoxTel_OnInitialized = (e) => {
        this.textBoxTelInstance = e.component;
    }

    // SELECTBOX LOCATION TYPES
    selectBoxLocationTypes_OnInitialized = (e) => {
        this.selectBoxLocationTypesInstance = e.component;
    }

    // TEXTBOX SHORT NAME
    textBoxShortName_OnInitialized = (e) => {
        this.textBoxShortNameInstance = e.component;
    }

    textBoxShortName_OnFocusOut = (e) => {
        e.component.option('value', e.component.option('value').toUpperCase());
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX IS FAVOURITE
    checkBoxIsFavourite_OnInitialized = (e) => {
        this.checkBoxIsFavouriteInstance = e.component;
    }

    checkBoxIsFavourite_ValueChanged = (e) => {
        this.input_ValueChanged(e);

        if (e.value === true) {
            this.textBoxShortNameInstance.option('visible', true);
        } else {
            this.textBoxShortNameInstance.option('visible', false);
        }
    }

    // TEXTBOX LATITIDE
    textBoxGpsLat_OnInitialized = (e) => {
        this.textBoxGpsLatInstance = e.component;
    }
    // TEXTBOX LONGITUDE
    textBoxGpsLng_OnInitialized = (e) => {
        this.textBoxGpsLngInstance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }
    
    // BUTTON AUTO GPS
    buttonAutoGpsInstance_OnClick = async (e) => {
        await this.calculateGps();

        const lat = this.textBoxGpsLatInstance.option('value');
        const lng = this.textBoxGpsLngInstance.option('value');
        const defaultCenter = { lat: lat !== null ? lat : 0, lng: lng !== null ? lng : 0 };
        const tooltip = this.textBoxLocationNameInstance.option('value') !== null ? this.textBoxLocationNameInstance.option('value') : '';
        this.refMap.setCenter(defaultCenter, [{ location: defaultCenter, tooltip: { text: tooltip } }]);
    }

    calculateGps = async () => {
        const street = this.textBoxStreetInstance.option('value');
        const streetNumber = this.textBoxStreetNumberInstance.option('value');
        const zipCode = this.textBoxZipCodeInstance.option('value');
        const city = this.textBoxCityInstance.option('value');
        const country = this.selectBoxCountriesInstance.option('displayValue');

        const postalCode = (zipCode !== null ? zipCode.toString().trim() : '');
        const locality = (city !== null ? city.toString().trim() : '');
        const countryRegion = (country !== null ? country.toString().trim() : '');

        let address = '';
        if (street !== null) {
            address = street + ' ' + (streetNumber != null ? streetNumber.toString().trim() : '');
        }

        var data = await ServiceGeoCoding.getCoordinatesByAddress(address, postalCode, locality, countryRegion);
        if (data !== null && !data.hasOwnProperty("error")) {
            if (data.statusCode === 200) {
                let lat = data.resourceSets[0].resources[0].point.coordinates[0];
                let lng = data.resourceSets[0].resources[0].point.coordinates[1];
                this.textBoxGpsLatInstance.option('value', lat);
                this.textBoxGpsLngInstance.option('value', lng);
            } else {
                this.textBoxGpsLatInstance.option('value', 0);
                this.textBoxGpsLngInstance.option('value', 0);
            }
        }
    }


    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateLocation();
    }

    updateLocation = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resLocationName = await this.validateLocationName();
        var resShortName = await this.validateShortName();
        var resShortNameRequired = await this.validateShortNameRequired();
        var resLocationType = await this.validateLocationType();

        var res = await this.validationGroupInstance.validate();
 
        if (res.isValid && resLocationName === true && resShortName === true && resShortNameRequired === true && resLocationType === true) {
            this.locationRecord.locationName = this.textBoxLocationNameInstance.option('value');
            this.locationRecord.street = this.textBoxStreetInstance.option('value');
            this.locationRecord.streetNumber = this.textBoxStreetNumberInstance.option('value');
            this.locationRecord.zipCode = this.textBoxZipCodeInstance.option('value');
            this.locationRecord.city = this.textBoxCityInstance.option('value');
            this.locationRecord.countryCode = this.selectBoxCountriesInstance.option('value') !== -1 ? this.selectBoxCountriesInstance.option('value') : null;
            this.locationRecord.email = this.textBoxEmailInstance.option('value');
            this.locationRecord.tel = this.textBoxTelInstance.option('value');
            this.locationRecord.locationTypeId = this.selectBoxLocationTypesInstance.option('value');
            this.locationRecord.shortName = this.checkBoxIsFavouriteInstance.option('value') === true ? this.textBoxShortNameInstance.option('value') : null;
            this.locationRecord.isFavourite = this.checkBoxIsFavouriteInstance.option('value');
            this.locationRecord.gpsLat = this.textBoxGpsLatInstance.option('value');
            this.locationRecord.gpsLng = this.textBoxGpsLngInstance.option('value');
            this.locationRecord.notes = this.htmlEditorNotesInstance.option('value');

            if (this.locationId === -1) {
                // new location
                var resultLocation = await ServiceLocations.createLocation(this.locationRecord);
                if (!resultLocation.hasOwnProperty("error")) {
                    this.onCreate(resultLocation); // callback
                    if (resultLocation !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            await this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update location
                var resultLocationUpdate = await ServiceLocations.updateLocation(this.locationRecord);
                if (!resultLocationUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultLocationUpdate); // callback
                    if (resultLocationUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxLocationNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateLocationName = async () => {
        var result = await ServiceLocations.doesLocationNameExist(this.textBoxLocationNameInstance.option('value'), this.locationRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxLocationNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateShortName = async () => {
        var retValue = false;

        if (this.checkBoxIsFavouriteInstance.option('value') === true) {
            var result = await ServiceLocations.doesShortNameExist(this.textBoxShortNameInstance.option('value'), this.locationRecord.id);
            if (result !== null && !result.hasOwnProperty("error")) {
                this.textBoxShortNameInstance.option('isValid', !result);
                retValue = !result;
            } else {
                this.onDBError();  // callback
                retValue = false;
            }
        } else {
            retValue = true;
        }

        return retValue;
    }

    validateShortNameRequired = () => {
        var retValue = false;

        if (this.checkBoxIsFavouriteInstance.option('value') === true) {
            if (this.textBoxShortNameInstance.option('value') !== null && this.textBoxShortNameInstance.option('value') !== '') {
                retValue = true;
            }
        } else {
            retValue = true;
        }

        return retValue;
    }

    validateLocationType = () => {
        return this.selectBoxLocationTypesInstance.option('value') !== null && this.selectBoxLocationTypesInstance.option('value') !== -1;
    }

    // RENDERING
     render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1041}
                    height={680}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="psal-top-div">
                                    <div className="psal-top-left-div">
                                        <div className="psal-dx-field">
                                            <div className="dx-field-label psal-top-left-label">Name*</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxLocationName_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateLocationName} message="Location exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psal-dx-field">
                                            <div className="dx-field-label psal-top-left-label">Type*</div>
                                            <div className="dx-field-value psal-field-value">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceLocationTypes}
                                                    valueExpr="id"
                                                    displayExpr="value"
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    minSearchLength={0}
                                                    width='150px'
                                                    onInitialized={this.selectBoxLocationTypes_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <CustomRule validationCallback={this.validateLocationType} message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="psal-dx-field">
                                            <div className="psal-favourite-div">
                                                <div className="dx-field-label psal-top-left-label">Favourite</div>
                                                <div className="dx-field-value psal-field-value psal-field-value-favourite">
                                                    <CheckBox
                                                        text=""
                                                        onInitialized={this.checkBoxIsFavourite_OnInitialized}
                                                        onValueChanged={this.checkBoxIsFavourite_ValueChanged}
                                                    />
                                                </div>
                                                <div className="dx-field-value psal-field-value">
                                                    <TextBox
                                                        width='100px'
                                                        maxLength={5}
                                                        onFocusIn={this.textBox_OnFocusIn}
                                                        onFocusOut={this.textBoxShortName_OnFocusOut}
                                                        onInitialized={this.textBoxShortName_OnInitialized}
                                                        onValueChanged={this.input_ValueChanged}
                                                    >
                                                        <Validator>
                                                            <CustomRule validationCallback={this.validateShortNameRequired} message="Required" />
                                                            <AsyncRule validationCallback={this.validateShortName} message="Tag exists" />
                                                        </Validator>
                                                    </TextBox>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="psal-dx-field">
                                            <div className="dx-field-label psal-top-left-label">Email</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxEmail_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="psal-dx-field">
                                            <div className="dx-field-label psal-top-left-label">Tel</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxTel_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psal-top-right-div">
                                        <div className="psal-dx-field-notes">
                                            <div className="dx-field-value psal-field-value-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='254px'
                                                    placeholder='Notes'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="psal-second-div">
                                <div className="dx-card psal-second-left-div">
                                    <div className="psal-street-div">
                                        <div className="dx-field">
                                            <div className="dx-field-label psal-second-left-label">Street</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxStreet_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field pe-dx-field-street-number">
                                            <div className="dx-field-label psal-second-left-label psal-field-label-street-number">Street No</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='100px'
                                                    onInitialized={this.textBoxStreetNumber_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psal-city-div">
                                        <div className="dx-field">
                                            <div className="dx-field-label psal-second-left-label">Zip Code</div>
                                            <div className="dx-field-value psal-field-value psal-field-value-zip-code">
                                                <TextBox
                                                    width='130px'
                                                    onInitialized={this.textBoxZipCode_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field psal-dx-field-city">
                                            <div className="dx-field-label psal-second-left-label psal-field-label-city">City</div>
                                            <div className="dx-field-value psal-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxCity_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="psal-dx-field">
                                        <div className="dx-field-label psal-second-left-label">Country</div>
                                        <div className="dx-field-value psal-field-value-countries">
                                            <SelectBox
                                                dataSource={this.state.dataSourceCountries}
                                                valueExpr="countryCode"
                                                displayExpr="value"
                                                searchEnabled={true}
                                                showClearButton={true}
                                                showDataBeforeSearch={false}
                                                minSearchLength={0}
                                                width='200px'
                                                onInitialized={this.selectBoxCountries_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                            />
                                        </div>
                                    </div>

                                    <div className="psal-lat-lng-main-div">
                                        <div>
                                            <div className="psal-dx-field">
                                                <div className="dx-field-label psal-second-left-label">Latitude</div>
                                                <div className="dx-field-value psal-field-value">
                                                    <NumberBox
                                                        width='100px'
                                                        onInitialized={this.textBoxGpsLat_OnInitialized}
                                                        onFocusIn={this.textBox_OnFocusIn}
                                                        onValueChanged={this.input_ValueChanged}
                                                    >
                                                        <Validator>
                                                            <ValidationRule type="required" message="Required" />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                            </div>
                                            <div className="psal-dx-field">
                                                <div className="dx-field-label psal-second-left-label">Longitude</div>
                                                <div className="dx-field-value psal-field-value">
                                                    <NumberBox
                                                        width='100px'
                                                        onInitialized={this.textBoxGpsLng_OnInitialized}
                                                        onFocusIn={this.textBox_OnFocusIn}
                                                        onValueChanged={this.input_ValueChanged}
                                                    >
                                                        <Validator>
                                                            <ValidationRule type="required" message="Required" />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dx-field-value psal-field-value-auto-gps">
                                            <Button
                                                text="Calc GPS"
                                                stylingMode="outlined"
                                                onClick={this.buttonAutoGpsInstance_OnClick}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="psal-map-div">
                                    <HxMap
                                        ref={ref => this.refMap = ref}
                                    />
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="psal-buttons">
                        <div className="psal-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psal-buttons-update'>
                            <div className="psal-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon={globalIcons.save}
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
                <HxPopupSaveChanges
                    onInitialized={this.popupSaveChanges_onInitialized}
                    onClick={this.popupSaveChanges_onClick}
                />

            </React.Fragment>
        );
    }

}

export default PopupLocations

