import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';


function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    // USED WHEN REFRESHING PAGE
    useEffect(() => {
        (async function () {
            const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
            if (userAuth !== null) {
                const result = await getUser(userAuth.id);
                if (result.isOk) {
                    setUser(undefined);
                    setUser(result.data);
                }
            }
            setLoading(false);
        })();
    }, []);

    const signIn = useCallback(async (email, password) => {
        const result = await sendSignInRequest(email, password);
        if (result.isOk) {
            setUser(undefined);
            setUser(result.data);
        } else {
            setUser(undefined);
            sessionStorage.removeItem('userAuth');
            localStorage.removeItem('userSettingsGeneral');
            localStorage.removeItem('userSettingsPages');
        }

        return result;
    }, []);

    const signOut = useCallback(() => {
        setUser(undefined);
        sessionStorage.removeItem('userAuth');
        localStorage.removeItem('userSettingsGeneral');
        localStorage.removeItem('userSettingsPages');
    }, []);


    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />
    );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
