import React from 'react';
import { Events } from '../../components';

class events extends React.Component {
    render() {
        return (
            <Events />
        );
    }
}

export default events