import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import DateBox from 'devextreme-react/date-box';
import { CheckBox } from 'devextreme-react/check-box';

import globalIcons from '../../../config/globalIcons.js';

import ServiceEvents from '../../../api/services/ServiceEvents';
import ServiceGeneral from '../../../api/services/ServiceGeneral';

import DataGridEventGigDates from './DataGridEventGigDates';


import './PopupEvents.scss'

// props
//
// callables
//      preload
// callbacks
//      onInitialized
//      onDBError
//      onCreate
//      onUpdate

class PopupEvents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceCountries: [],
        }
 
        this.popupInstance = null;
        this.eventId = -1;
        this.gridInstance = null;

        this.textBoxEventNameInstance = null;
        this.dateBoxStartDateInstance = null;
        this.dateBoxEndDateInstance = null;
        this.textBoxStreetInstance = null;
        this.textBoxStreetNumberInstance = null;
        this.textBoxZipCodeInstance = null;
        this.textBoxCityInstance = null;
        this.selectBoxCountriesInstance = null;
        this.checkBoxIsCurrentInstance = null;

        this.eventRecord = {};
        this.eventDefaultRecord = {
            id: -1,
            locationId: -1,
            eventName: '',
            locationName: '',
            startDate: null,
            endDate: null,
            street: null,
            streetNumber: null,
            zipCode: null,
            city: null,
            countryCode: null,
            gpsLng: null,
            gpsLat: null,
            isCurrent: false,
            gigDates: []
        }

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;

    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    } 
    
    // CALLABLES
    preload = async (id) => {
        this.eventId = id;
        await this.loadDataSourceCountries();
    }

    // EVENTS
    componentDidMount = async () => {
    }

    // DATA
    loadDataSourceEvent = async (id) => {
        var data = await ServiceEvents.getEvent(id);
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.eventRecord = data;
            } else {
                this.onDBError('Event not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceCountries = async () => {
        var data = await ServiceGeneral.getCountriesDropDown();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceCountries: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }
    
    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.eventId === -1) {
            // new event
            this.eventRecord = this.eventDefaultRecord;

            this.resetPopup();

            this.popupInstance.option('title', 'Create event');
        } else {
            // update event
            await this.loadDataSourceEvent(this.eventId);

            this.textBoxEventNameInstance.option('value', this.eventRecord.eventName);
            this.dateBoxStartDateInstance.option('value', this.eventRecord.startDate);
            this.dateBoxEndDateInstance.option('value', this.eventRecord.endDate);
            this.textBoxStreetInstance.option('value', this.eventRecord.street);
            this.textBoxStreetNumberInstance.option('value', this.eventRecord.streetNumber);
            this.textBoxZipCodeInstance.option('value', this.eventRecord.zipCode);
            this.textBoxCityInstance.option('value', this.eventRecord.city);
            this.selectBoxCountriesInstance.option('value', this.eventRecord.countryCode);
            this.checkBoxIsCurrentInstance.option('value', this.eventRecord.isCurrent);
            this.refGrid.updateDS(this.eventRecord.gigDates);

            this.dateBoxStartDateInstance.option('disabled', true);
            this.dateBoxEndDateInstance.option('disabled', true);

            this.checkBoxKeepOpenInstance.option('visible', false)

            this.popupInstance.option('title', 'Update ' + this.eventRecord.eventName);
        }
        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
        this.dataHasChanged = false;

        this.textBoxEventNameInstance.focus();
    }

    popup_OnHidden = (e) => {
        this.resetPopup();
    }

    resetPopup = async () => {
        this.eventId = -1;
        this.eventRecord = this.eventDefaultRecord;

        this.textBoxEventNameInstance.option('value', null);
        this.dateBoxStartDateInstance.option('value', null);
        this.dateBoxEndDateInstance.option('value', null);
        this.textBoxStreetInstance.option('value', null);
        this.textBoxStreetNumberInstance.option('value', null);
        this.textBoxZipCodeInstance.option('value', null);
        this.textBoxCityInstance.option('value', null);
        this.selectBoxCountriesInstance.option('value', null);
        this.checkBoxIsCurrentInstance.option('value', false);
        this.refGrid.updateDS([]);

        this.dateBoxStartDateInstance.option('disabled', false);
        this.dateBoxEndDateInstance.option('disabled', false);

        this.checkBoxKeepOpenInstance.option('visible', true)

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;

        if (this.eventId === -1) {
            this.popupInstance.option('title', 'Create event*');
        } else {
            this.popupInstance.option('title', 'Update ' + this.eventRecord.eventName + '*');
        }
    }

    date_ValueChanged = async (e) => {
        this.input_ValueChanged(e);

        if (this.eventId === -1 && this.dateBoxStartDateInstance.option("value") !== null && this.dateBoxEndDateInstance.option("value") !== null && this.validateDates()) {
           var result = await ServiceEvents.getTempGigDatesByDateInterval(new Date(this.dateBoxStartDateInstance.option("value")), new Date(this.dateBoxEndDateInstance.option("value")));
           this.refGrid.updateDS(result);
        }
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
      }

    // TEXTBOX NAME
    textBoxEventName_OnInitialized = (e) => {
        this.textBoxEventNameInstance = e.component;
    }

    // TEXTBOX STREET
    textBoxStreet_OnInitialized = (e) => {
        this.textBoxStreetInstance = e.component;
    }

    // TEXTBOX STREET NUMBER
    textBoxStreetNumber_OnInitialized = (e) => {
        this.textBoxStreetNumberInstance = e.component;
    }

    // TEXTBOX ZIP CODE
    textBoxZipCode_OnInitialized = (e) => {
        this.textBoxZipCodeInstance = e.component;
    }

    // TEXTBOX CITY
    textBoxCity_OnInitialized = (e) => {
        this.textBoxCityInstance = e.component;
    }

    // DATEBOX START DATE
    dateBoxStartDate_OnInitialized = (e) => {
        this.dateBoxStartDateInstance = e.component;
    }

    // DATEBOX END DATE
    dateBoxEndDate_OnInitialized = (e) => {
        this.dateBoxEndDateInstance = e.component;
    }

    // SELECTBOX COUNTRIES
    selectBoxCountries_OnInitialized = (e) => {
        this.selectBoxCountriesInstance = e.component;
    }

    
    // CHECKBOX IS CURRENT
    checkBoxIsCurrent_OnInitialized = (e) => {
        this.checkBoxIsCurrentInstance = e.component;
    }


    // GRID GIGDATES
    gridGigDates_OnDBError = (message) => {
        this.onDBError(message);  // callback
    }

    gridGigDates_OnInitialized = (instance) => {
        this.gridInstance = instance;
    }


    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateEvent();
    }

    updateEvent = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resEvent = await this.validateEventName();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resEvent === true) {
            this.eventRecord.eventName = this.textBoxEventNameInstance.option('value');
            this.eventRecord.locationName = this.eventRecord.eventName;
            this.eventRecord.startDate = this.dateBoxStartDateInstance.option('value');
            this.eventRecord.endDate = this.dateBoxEndDateInstance.option('value');
            this.eventRecord.street = this.textBoxStreetInstance.option('value');
            this.eventRecord.streetNumber = this.textBoxStreetNumberInstance.option('value');
            this.eventRecord.zipCode = this.textBoxZipCodeInstance.option('value');
            this.eventRecord.city = this.textBoxCityInstance.option('value');
            this.eventRecord.countryCode = this.selectBoxCountriesInstance.option('value');
            this.eventRecord.isCurrent = this.checkBoxIsCurrentInstance.option('value');
            this.eventRecord.gigDates = this.gridInstance.option('dataSource');

            if (this.eventId === -1) {
                // new event
                var resultEvent = await ServiceEvents.createEvent(this.eventRecord);
                if (!resultEvent.hasOwnProperty("error")) {
                    this.onCreate(resultEvent); // callback
                    if (resultEvent !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.popupInstance.option('title', 'Create event');
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update event
                var resultEventUpdate = await ServiceEvents.updateEvent(this.eventRecord);
                if (!resultEventUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultEventUpdate); // callback
                    if (resultEventUpdate === true) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.popupInstance.option('title', 'Create event');
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxEventNameInstance.focus();
        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateEventName = async () => {
        var result = await ServiceEvents.doesEventNameExist(this.textBoxEventNameInstance.option('value'), this.eventRecord.id);
        if (!result.hasOwnProperty("error")) {
            this.textBoxEventNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError("Database error!");  // callback
            return false;
        }
    }

    validateDates = () => {
        var valStart = this.dateBoxStartDateInstance.option("value");
        var valEnd = this.dateBoxEndDateInstance.option("value");
        if (valStart !== null && valEnd !== null) {
            const startDate = new Date(valStart).getTime();
            const endDate = new Date(valEnd).getTime();

            return (startDate <= endDate);
        }

        return true;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    width={799}  // 532
                    height={417} // 417
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    showTitle={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onHidden={this.popup_OnHidden}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="pe-main-div">
                                <div className="pe-left-div">
                                    <div className="dx-field">
                                        <div className="dx-field-label pe-field-label">Name*</div>
                                        <div className="dx-field-value pe-field-value">
                                            <TextBox
                                                width='250px'
                                                onInitialized={this.textBoxEventName_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                                onValueChanged={this.input_ValueChanged}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <AsyncRule validationCallback={this.validateEventName} message="Event exists" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                        <div className="dx-field-value pe-field-value-current">
                                            <CheckBox
                                                text="Current"
                                                onInitialized={this.checkBoxIsCurrent_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label pe-field-label">Start date*</div>
                                        <div className="dx-field-value pe-field-value-datebox">
                                            <DateBox
                                                pickerType='native'
                                                onInitialized={this.dateBoxStartDate_OnInitialized}
                                                onValueChanged={this.date_ValueChanged}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <CustomRule validationCallback={this.validateDates} message="Invalid dates" />
                                                </Validator>
                                            </DateBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label pe-field-label">End date*</div>
                                        <div className="dx-field-value pe-field-value-datebox">
                                            <DateBox
                                                pickerType='native'
                                                onInitialized={this.dateBoxEndDate_OnInitialized}
                                                onValueChanged={this.date_ValueChanged}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <CustomRule validationCallback={this.validateDates} message="Invalid dates" />
                                                </Validator>
                                            </DateBox>
                                        </div>
                                    </div>
                                    <div className="pe-street-div">
                                        <div className="dx-field">
                                            <div className="dx-field-label pe-field-label">Street</div>
                                            <div className="dx-field-value pe-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxStreet_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field pe-dx-field-street-number">
                                            <div className="dx-field-label pe-field-label pe-field-label-street-number">Street No</div>
                                            <div className="dx-field-value pe-field-value">
                                                <TextBox
                                                    width='100px'
                                                    onInitialized={this.textBoxStreetNumber_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pe-city-div">
                                        <div className="dx-field">
                                            <div className="dx-field-label pe-field-label">Zip Code</div>
                                            <div className="dx-field-value pe-field-value pe-field-value-zip-code">
                                                <TextBox
                                                    width='130px'
                                                    onInitialized={this.textBoxZipCode_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                        <div className="dx-field pe-dx-field-city">
                                            <div className="dx-field-label pe-field-label pe-field-label-city">City</div>
                                            <div className="dx-field-value pe-field-value">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxCity_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label pe-field-label">Country</div>
                                        <div className="dx-field-value pe-field-value-countries">
                                            <SelectBox
                                                dataSource={this.state.dataSourceCountries}
                                                valueExpr="countryCode"
                                                displayExpr="value"
                                                searchEnabled={true}
                                                showClearButton={true}
                                                showDataBeforeSearch={false}
                                                minSearchLength={0}
                                                width='250px'
                                                onInitialized={this.selectBoxCountries_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pe-right-div">
                                    <DataGridEventGigDates
                                        ref={ref => this.refGrid = ref}
                                        onInitialized={this.gridGigDates_OnInitialized}
                                        onDBError={this.gridGigDates_OnDBError}
                                    />
                                </div>
                            </div>

                            <div className="pe-buttons">
                                <div className="pe-buttons-checkbox">
                                    <CheckBox
                                        text="Keep open"
                                        onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                        onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                                    />
                                </div>
                                <div className='pe-buttons-update'>
                                    <div className="pe-button-update">
                                        <Button
                                            onInitialized={this.buttonUpdate_OnInitialized}
                                            icon={globalIcons.save}
                                            onClick={this.buttonUpdate_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                   </ValidationGroup>

                </Popup>
            </React.Fragment>
        );

    }
}

export default PopupEvents

