import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { NumberBox } from 'devextreme-react/number-box';
import { Popup } from 'devextreme-react/popup';
import SelectBox from 'devextreme-react/select-box';
import { ValidationGroup } from 'devextreme-react/validation-group';
import Validator, { ValidationRule, AsyncRule, CustomRule } from 'devextreme-react/validator';
import React from 'react';
import ServiceGeneral from '../../../api/services/ServiceGeneral';
import ServiceGeoCoding from '../../../api/services/ServiceGeoCoding';
import ServiceLocations from '../../../api/services/ServiceLocations';
import ServiceShuttles from '../../../api/services/ServiceShuttles';

import HxPopupSaveChanges from '../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import './PopupTripTimings.scss';

import globalConfig from '../../../config/global.js';

import Map from 'devextreme-react/map';

// props
//      locationDepartureId (required): departure id
//      locationArrivalId (required): arrival id
//
// callables
//      preload
// cllbacks
//      onInitialized: callback
//      onCreate: callback
//      onUpdate: callback
//      onDBError: callback


class PopupTripTimings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locationsDataSource: [],
            routes: [{
                weight: 3,
                color: 'blue',
                opacity: 0.5,
                mode: 'driving',
                locations: [[51.2325700000, 5.0766200000], [51.3150300000, 4.8882800000]],
            }],
            markers: [{
                location: [51.2325700000, 5.0766200000],
                tooltip: {
                    text: 'Times Square',
                },
            }, {
                location: [51.3150300000, 4.8882800000],
                tooltip: {
                    text: 'Central Park',
                },
            }]
        }
        this.locationDepartureId = -1;
        this.locationArrivalId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.selectBoxLocationDepartureInstance = null;
        this.selectBoxLocationArrivalInstance = null;
        this.textBoxDistanceInstance = null;
        this.textBoxMinutesMinInstance = null;
        this.textBoxMinutesMaxInstance = null;
        this.textBoxRealDistanceInstance = null;
        this.textBoxRealDurationInstance = null;

        this.mapInstance = null;
        this.refMap = React.createRef();
       
        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.buttonReCalcInstance = null

        this.tripTimingRecord = {};

        this.locationDepartureIdShuttlesEditMode = -1;
        this.locationArrivalIdShuttlesEditMode = -1;

        this.dataHasChanged = false;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = () => {
        if (this.props.onDBError) {
            this.props.onDBError();  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }
    // CALLABLES
    preload = async (locationDepartureId, locationArrivalId) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.locationDepartureId = locationDepartureId;
        this.locationArrivalId = locationArrivalId;
        await this.loadDataSourceLocations();

        await this.fillPopup(locationDepartureId, locationArrivalId);
    }

    // EVENTS


    // DATA
    loadDataSourceLocations = async () => {
        var data = await ServiceLocations.getLocationsInfo(true);
        if (!data.hasOwnProperty("error")) {
            this.setState({ locationsDataSource: data });
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceTripTiming = async (locationDepartureId, locationArrivalId) => {
        var data = await ServiceShuttles.getTripTimingByLocationIds(parseInt(locationDepartureId, 10), parseInt(locationArrivalId, 10));
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.tripTimingRecord = data;
            } else {
                this.onDBError('Triptiming not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }


    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.locationDepartureId === -1 && this.locationArrivalId === -1) {
            // new triptiming
            this.checkBoxKeepOpenInstance.option('visible', true);
            this.setPopupTitle();
        } else {
            // update triptiming
            this.checkBoxKeepOpenInstance.option('visible', false);
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (locationDepartureId, locationArrivalId) => {
        await this.loadDataSourceTripTiming(locationDepartureId, locationArrivalId);

        this.selectBoxLocationDepartureInstance.option('value', this.tripTimingRecord.locationDepartureId);
        this.selectBoxLocationArrivalInstance.option('value', this.tripTimingRecord.locationArrivalId);
        this.textBoxDistanceInstance.option('value', this.tripTimingRecord.distance);
        this.textBoxMinutesMinInstance.option('value', this.tripTimingRecord.minutesMin);
        this.textBoxMinutesMaxInstance.option('value', this.tripTimingRecord.minutesMax);
        this.textBoxRealDistanceInstance.option('value', this.tripTimingRecord.realDistance);
        this.textBoxRealDurationInstance.option('value', this.tripTimingRecord.realDuration);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    popup_OnShown = async (e) => {
        this.selectBoxLocationDepartureInstance.focus();

        if (this.locationDepartureId === -1 && this.locationArrivalId === -1) {
            // new triptiming
            this.selectBoxLocationDepartureInstance.option('disabled', false);
            this.selectBoxLocationArrivalInstance.option('disabled', false);
        } else {
            // update triptiming
            this.selectBoxLocationDepartureInstance.option('disabled', true);
            this.selectBoxLocationArrivalInstance.option('disabled', true);
        }
    }

    resetPopup = async () => {
        this.locationDepartureId = -1;
        this.locationArrivalId = -1;

        await this.fillPopup(this.locationDepartureId, this.locationArrivalId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.selectBoxLocationDepartureInstance.focus();
    }

    setPopupTitle = () => {
        if (this.locationDepartureId === -1 && this.locationArrivalId === -1) {
            this.popupInstance.option('title', 'Create triptiming' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.tripTimingRecord.locationDeparture + ' - ' + this.tripTimingRecord.locationArrival + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateTripTiming();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // SELECTBOX DEPARTURE
    selectBoxLocationDeparture_OnInitialized = (e) => {
        this.selectBoxLocationDepartureInstance = e.component;
    }

    selectBoxLocationDeparture_OnSelectionChanged = async (e) => {
        await this.calculateGeoCoding(true);
    }

    // SELECTBOX ARRIVAL
    selectBoxLocationArrival_OnInitialized = (e) => {
        this.selectBoxLocationArrivalInstance = e.component;
    }

    selectBoxLocationArrival_OnSelectionChanged = async (e) => {
        await this.calculateGeoCoding(true);
    }

    calculateGeoCoding = async (syncMap) => {
        let locations = [[0, 0], [0, 0.01]];
        let coordDep = [0, 0];
        let coordArr = [0, 0.01];
        let dep = null;
        let arr = null;
        if (this.selectBoxLocationDepartureInstance.option('value') !== null && this.selectBoxLocationArrivalInstance.option('value') !== null && this.selectBoxLocationDepartureInstance.option('value') !== -1 && this.selectBoxLocationArrivalInstance.option('value') !== -4) {
            if (this.selectBoxLocationDepartureInstance.option('value') !== this.selectBoxLocationArrivalInstance.option('value')) {
                dep = this.state.locationsDataSource.find(x => x.id === this.selectBoxLocationDepartureInstance.option('value'));
                arr = this.state.locationsDataSource.find(x => x.id === this.selectBoxLocationArrivalInstance.option('value'));

                if (dep.gpsLat !== null && dep.gpsLng !== null && arr.gpsLat !== null && arr.gpsLng !== null) {
                    var data = await ServiceGeoCoding.getRouteByDepartureArrival(dep.gpsLat, dep.gpsLng, arr.gpsLat, arr.gpsLng);
                    if (data !== null && !data.hasOwnProperty("error")) {
                        if (data.statusCode == 200) {
                            this.textBoxRealDurationInstance.option("value", Math.floor(data.resourceSets[0].resources[0].travelDuration / 60));
                            this.textBoxRealDistanceInstance.option("value", data.resourceSets[0].resources[0].travelDistance);

                            if (syncMap === true) {
                                let routesData = [];
                                for (var i = 0; i < data.resourceSets[0].resources[0].routeLegs[0].itineraryItems.length; i++) {
                                    routesData.push([data.resourceSets[0].resources[0].routeLegs[0].itineraryItems[i].maneuverPoint.coordinates[0], data.resourceSets[0].resources[0].routeLegs[0].itineraryItems[i].maneuverPoint.coordinates[1]]);
                                }
                                locations = routesData;

                                coordDep = routesData[0];
                                coordArr = routesData[routesData.length - 1];

                                this.setState({
                                    routes: this.state.routes.map((item) => {
                                        item.locations = locations;
                                        return item;
                                    }),
                                });


                                this.setState({
                                    markers: [{
                                        location: coordDep,
                                        tooltip: {
                                            text: 'Times Square',
                                        },
                                    }, {
                                        location: coordArr,
                                        tooltip: {
                                            text: 'Central',
                                        },
                                    }],
                                });
                            }
                        }
                    }
                }
            }
        }

        if (syncMap === true && dep !== null && arr != null && dep.gpsLat !== null && dep.gpsLng !== null && arr.gpsLat !== null && arr.gpsLng !== null) {
            this.mapInstance.repaint();
        }     
    }

    // MAP
    mapInstance_OnInitialized = (e) => {
        this.mapInstance = e.component;
    }

    // TEXTBOX DISTANCE
    textBoxDistance_OnInitialized = (e) => {
        this.textBoxDistanceInstance = e.component;
    }

    // TEXTBOX MINUTES MIN
    textBoxMinutesMin_OnInitialized = (e) => {
        this.textBoxMinutesMinInstance = e.component;
    }

    // TEXTBOX MINUTES MAX
    textBoxMinutesMax_OnInitialized = (e) => {
        this.textBoxMinutesMaxInstance = e.component;
    }

    // TEXTBOX REAL DISTANCE
    textBoxRealDistance_OnInitialized = (e) => {
        this.textBoxRealDistanceInstance = e.component;
    }

    // TEXTBOX REAL DURATION
    textBoxRealDuration_OnInitialized = (e) => {
        this.textBoxRealDurationInstance = e.component;
    }
   
    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateTripTiming();
    }

    // BUTTON RECALC
    buttonReCalc_OnInitialized = (e) => {
        this.buttonReCalcInstance = e.component;
    }

    buttonReCalc_OnClick = async (e) => {
        await this.calculateGeoCoding(true);
    }

    updateTripTiming = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resExistingTripTimings = await this.validateTripTimings();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resExistingTripTimings === true) {
            this.tripTimingRecord.locationDepartureId = this.selectBoxLocationDepartureInstance.option('value');
            this.tripTimingRecord.locationArrivalId = this.selectBoxLocationArrivalInstance.option('value');
            this.tripTimingRecord.distance = this.textBoxDistanceInstance.option('value');
            this.tripTimingRecord.minutesMin = this.textBoxMinutesMinInstance.option('value');
            this.tripTimingRecord.minutesMax = this.textBoxMinutesMaxInstance.option('value');
            this.tripTimingRecord.realDistance = this.textBoxRealDistanceInstance.option('value');
            this.tripTimingRecord.realDuration = this.textBoxRealDurationInstance.option('value');
            this.tripTimingRecord.locationDeparture = this.selectBoxLocationDepartureInstance.option('display');
            this.tripTimingRecord.locationArrival = this.selectBoxLocationArrivalInstance.option('display');

            if (this.locationDepartureId === -1 && this.locationArrivalId === -1) {
                // new triptiming
                var resultTripTiming = await ServiceShuttles.createTripTiming(this.tripTimingRecord);
                if (!resultTripTiming.hasOwnProperty("error")) {
                    this.onCreate(resultTripTiming); // callback
                    if (resultTripTiming !== [-1, -1]) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update triptiming
                var resultTripTimingUpdate = await ServiceShuttles.updateTripTiming(this.tripTimingRecord);
                if (!resultTripTimingUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultTripTimingUpdate); // callback
                    if (resultTripTimingUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.selectBoxLocationDepartureInstance.focus();
        }
    }

    // VALIDATION
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateTripTimings = async () => {
        if (this.locationDepartureId === -1 && this.locationArrivalId === -1) {
            if (this.selectBoxLocationDepartureInstance.option('value') !== null && this.selectBoxLocationArrivalInstance.option('value') !== null && this.selectBoxLocationDepartureInstance.option('value') !== -1 && this.selectBoxLocationArrivalInstance.option('value') !== -1) {
                if (this.selectBoxLocationDepartureInstance.option('value') === this.selectBoxLocationArrivalInstance.option('value')) {
                    this.selectBoxLocationDepartureInstance.option('isValid', false);
                    this.selectBoxLocationArrivalInstance.option('isValid', false);
                    return false;
                }
                var result = await ServiceShuttles.doesTripTimingExist(this.selectBoxLocationDepartureInstance.option('value'), this.selectBoxLocationArrivalInstance.option('value'), this.userSettingsGeneral.currentEventId);
                if (result !== null && !result.hasOwnProperty("error")) {
                    this.selectBoxLocationDepartureInstance.option('isValid', !result);
                    this.selectBoxLocationArrivalInstance.option('isValid', !result);
                    return !result;
                } else {
                    this.onDBError();  // callback
                    return false;
                }
            } else {
                return false;
            }
        }

        return true;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={819}
                    height={402}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="ptt-div">
                                    <div className="ptt-left-div">
                                        <div className="ptt-dx-field">
                                            <div className="dx-field-label ptt-label">Departure*</div>
                                            <div className="dx-field-value ptt-field-value">
                                                <SelectBox
                                                    dataSource={this.state.locationsDataSource}
                                                    valueExpr="id"
                                                    displayExpr="locationName"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationDeparture_OnInitialized}
                                                    onSelectionChanged={this.selectBoxLocationDeparture_OnSelectionChanged}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateTripTimings} message="Trip timing exists or invalid trip" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="ptt-dx-field">
                                            <div className="dx-field-label ptt-label">Arrival*</div>
                                            <div className="dx-field-value ptt-field-value">
                                                <SelectBox
                                                    dataSource={this.state.locationsDataSource}
                                                    valueExpr="id"
                                                    displayExpr="locationName"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival_OnInitialized}
                                                    onSelectionChanged={this.selectBoxLocationArrival_OnSelectionChanged}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateTripTimings} message="Trip timing exists or invalid trip" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="ptt-dx-field">
                                            <div className="dx-field-label ptt-label">Distance*</div>
                                            <div className="dx-field-value ptt-field-value">
                                                <NumberBox
                                                    width='75px'
                                                    step={0}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxDistance_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </NumberBox>
                                            </div>
                                        </div>
                                        <div className="ptt-dx-field-minutes">
                                            <div>
                                                <div className="ptt-dx-field">
                                                    <div className="dx-field-label ptt-label">Min minutes*</div>
                                                    <div className="dx-field-value ptt-field-value">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onFocusOut={this.textBox_OnFocusOut}
                                                            onInitialized={this.textBoxMinutesMin_OnInitialized}
                                                            onValueChanged={this.input_ValueChanged}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="ptt-minutes-right-div">
                                                <div className="ptt-dx-field">
                                                    <div className="dx-field-label ptt-label">Max minutes</div>
                                                    <div className="dx-field-value ptt-field-value">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onFocusOut={this.textBox_OnFocusOut}
                                                            onInitialized={this.textBoxMinutesMax_OnInitialized}
                                                            onValueChanged={this.input_ValueChanged}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ptt-dx-field-minutes">
                                            <div className="ptt-dx-field">
                                                <div className="dx-field-label ptt-label">Real distance*</div>
                                                <div className="dx-field-value ptt-field-value">
                                                    <NumberBox
                                                        width='75px'
                                                        step={0}
                                                        disabled={true}
                                                        onFocusIn={this.textBox_OnFocusIn}
                                                        onFocusOut={this.textBox_OnFocusOut}
                                                        onInitialized={this.textBoxRealDistance_OnInitialized}
                                                        onValueChanged={this.input_ValueChanged}
                                                    >
                                                        <Validator>
                                                            <ValidationRule type="required" message="Required" />
                                                        </Validator>
                                                    </NumberBox>
                                                </div>
                                            </div>
                                            <div className="ptt-real-right-div">
                                                <div className="ptt-dx-field">
                                                    <div className="dx-field-label ptt-label">Real duration*</div>
                                                    <div className="dx-field-value ptt-field-value">
                                                        <NumberBox
                                                            width='75px'
                                                            step={0}
                                                            disabled={true}
                                                            onFocusIn={this.textBox_OnFocusIn}
                                                            onFocusOut={this.textBox_OnFocusOut}
                                                            onInitialized={this.textBoxRealDuration_OnInitialized}
                                                            onValueChanged={this.input_ValueChanged}
                                                        >
                                                            <Validator>
                                                                <ValidationRule type="required" message="Required" />
                                                            </Validator>
                                                        </NumberBox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ptt-dx-field">
                                            <div className="dx-field-label ptt-label"></div>
                                            <div className="dx-field-value ptt-field-value">
                                                <Button
                                                    onInitialized={this.buttonReCalc_OnInitialized}
                                                    text="Recalc"
                                                    onClick={this.buttonReCalc_OnClick}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ptt-right-div">
                                        <div className="ptt-dx-field-map">
                                            <div className="dx-field-value">
                                                <Map
                                                    onInitialized={this.mapInstance_OnInitialized}
                                                    ref={this.refMap}
                                                    defaultZoom={14}
                                                    height={254}
                                                    width={440}
                                                    controls={false}
                                                    apiKey={globalConfig.settings.geoCoding.geoCodingMapKey}
                                                    provider="bing"
                                                    routes={this.state.routes}
                                                    markers={this.state.markers}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ptt-buttons">
                            <div className="ptt-buttons-checkbox">
                                <CheckBox
                                    text="Keep open"
                                    onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                    onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                                />
                            </div>
                            <div className='ptt-buttons-update'>
                                <div className="ptt-button-update">
                                    <Button
                                        onInitialized={this.buttonUpdate_OnInitialized}
                                        icon="save"
                                        onClick={this.buttonUpdate_OnClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>
                    <HxPopupSaveChanges
                        onInitialized={this.popupSaveChanges_onInitialized}
                        onClick={this.popupSaveChanges_onClick}
                    />
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupTripTimings

