import React from 'react';
import {
    CircularGauge, ValueIndicator, Scale, Label, Geometry, RangeContainer, Range, Export, Title, Font
} from 'devextreme-react/circular-gauge';
import { Chart, Series, Legend } from 'devextreme-react/chart';

import ServiceStats from '../../api/services/ServiceStats';

import './Home.scss';

class Shuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceDriversStats: [],
            tripsStatsFinished: 0,
            tripsStatsTotal: 0,
        }

        this.customizeText = "Finished trips"
    }

    // EVENTS
    componentDidMount = async () => {
        await this.loadDataSourceTripsStats();
        await this.loadDataSourceDriversStats();
    }

    // DATA
    loadDataSourceTripsStats = async () => {
        var data = await ServiceStats.getTripsStats();
        if (!data.hasOwnProperty('error')) {
            this.setState({ tripsStatsFinished: data.filter(t => t.statusName.toLowerCase() === 'finished').length });
            this.setState({ tripsStatsTotal: data.length });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceDriversStats = async () => {
        var data = await ServiceStats.getDriversStats();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceDriversStats: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // FUNCTIONS
    render() {
        return (
            <React.Fragment>
                <div className="stats-main-div">
                    <div className='dx-card stats-grid'>
                        <div className='stats-title'>Shuttle stats</div>
                        <div className="stats-div">
                             <CircularGauge
                                id="gauge"
                                value={this.state.tripsStatsFinished}
                            >
                                <Scale startValue={0} endValue={this.state.tripsStatsTotal} tickInterval={this.state.tripsStatsTotal >= 10 ? 10 : 1}>
                                    <Label customizeText={this.customizeText} />
                                </Scale>
                                <RangeContainer>
                                    <Range startValue={0} endValue={Math.floor(this.state.tripsStatsTotal / 3)} color="#CE2029" />
                                    <Range startValue={Math.floor(this.state.tripsStatsTotal / 3)} endValue={Math.floor(this.state.tripsStatsTotal / 3 * 2)} color="#FFD700" />
                                    <Range startValue={Math.floor(this.state.tripsStatsTotal / 3 * 2)} endValue={this.state.tripsStatsTotal} color="#228B22" />
                                </RangeContainer>
                                <Export enabled={true} />
                                <Title text={"Finished trips (" + this.state.tripsStatsFinished + "/" + this.state.tripsStatsTotal + ")"}>
                                    <Font size={28} />
                                </Title>
                                <Export enabled={false} />
                            </CircularGauge>
                        </div>
                        <div className="stats-div stats-driver-div">
                            <div className="stats-title">Driver stats</div>
                            <Chart id="chart" dataSource={this.state.dataSourceDriversStats}>
                                <Series
                                    valueField="tripsDone"
                                    argumentField="driverName"
                                    name=""
                                    type="bar"
                                    color="#ffaa66">
                                    <Label
                                        visible={true}
                                        backgroundColor="#03A9F4"
                                    />
                                </Series>
                                <Legend visible={false} />
                            </Chart>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Shuttles
