import React from 'react';
import { Popup } from 'devextreme-react/popup';
import Validator, { AsyncRule, ValidationRule, EmailRule, RangeRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import HtmlEditor, {
    Toolbar, MediaResizing, ImageUpload, Item,
} from 'devextreme-react/html-editor';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import { LoadPanel } from 'devextreme-react/load-panel';
import { NumberBox } from 'devextreme-react/number-box';

import ServiceShuttles from '../../../api/services/ServiceShuttles'
import ServiceDrivers from '../../../api/services/ServiceDrivers'
import ServiceLocations from '../../../api/services/ServiceLocations'
import ServiceFleet from '../../../api/services/ServiceFleet'
import ServiceShuttlesArtists from '../../../api/services/ServiceShuttlesArtists'
import ServiceEvents from '../../../api/services/ServiceEvents'

import HxPopupSaveChanges from '../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'
import HxToast from '../../custom-components/hx-toast/HxToast';

import PopupLocations from '../shuttles-admin/shuttles-admin-locations/sub-components/PopupLocations'
import ShuttlesPreview from './ShuttlesPreview';

import './PopupShuttles.scss'

// props
//
// callbacks
//      onInitialized
//      onUpdate
//      onDBError
//      onHiding

class PopupShuttles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceLocations: [],
            dataSourceDrivers: [],
            dataSourceArtists: [],
            dataSourceFleet: [],
            dataSourceTripTimings: [],
            dataSourceTripStatusses: [],
            loadPanelVisible: false,
            popupHeight: '755px',
        }

        this.tripId = -1;
        this.eventLocationId = -1;
        this.formClosing = false;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.selectBoxArtistsInstance = null;
        this.selectBoxLocationDepartureInstance = null;
        this.selectBoxLocationArrival1Instance = null;
        this.selectBoxLocationArrival2Instance = null;
        this.selectBoxFleetInstance = null;

        this.textBoxPaxInstance = null;
        this.checkBoxTrailerInstance = null;
        this.selectBoxDriverInstance = null;
        this.selectBoxTripStatussesInstance = null;
        this.htmlEditorNotesInstance = null;

        this.buttonCreateArrival2Instance = null;
        this.buttonRemoveArrival2Instance = null;
        this.buttonLocationInstance = null;

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.tripRecord = {};

        this.favouriteLocations = [];
        this.eventGigDates = [];

        this.doNotExecutePrepare = false;

        this.statusWaitingId = null;
        this.driverTbaId = null;
        this.crudDate = null;

        this.popupLocationsInstance = null;

        this.dataHasChanged = false;
        this.popupSaveChangesInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = () => {
        if (this.props.onDBError) {
            this.props.onDBError();  // callback
        }
    }

    onCreate = (success, crudDate) => {
        if (this.props.onCreate) {
            this.props.onCreate(success, crudDate);  // callback
        }
    }

    onUpdate = (success, crudDate) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success, crudDate);  // callback
        }
    }

    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.tripId = id;

        await this.loadDataSourceEventGigDates();
        await this.loadDataSourceTripStatusses();
        await this.loadDataSourceTripTimings();
        await this.loadDataSourceEventLocationId();
        await this.loadDataSourceLocations();
        await this.loadDataSourceDrivers();
        await this.loadDataSourceArtists();
        await this.loadDataSourceFleet();
    }

    // EVENTS

    // DATA
    loadDataSourceEventLocationId = async () => {
        var data = await ServiceEvents.getEventLocationId();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventLocationId = data;
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceEventGigDates = async () => {
        var data = await ServiceEvents.getEventGigDatesDropDown(true);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.eventGigDates = data;
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceLocations = async () => {
        var data = await ServiceLocations.getLocationsDropDown(true);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceLocations: data });
            this.favouriteLocations = data.filter((item) => {
                return item.isFavourite === true;
            });
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceDrivers = async () => {
        var data = await ServiceDrivers.getDriversDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceDrivers: data });
            let tba = data.find(s => s.name.toUpperCase() === 'TO BE ANNOUNCED');
            this.driverTbaId = (tba !== undefined ? tba.id : null);
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceArtists = async () => {
        var data = await ServiceShuttlesArtists.getArtistsDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceArtists: data });
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceFleet = async () => {
        var data = await ServiceFleet.getFleetDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceFleet: data });
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceTrip = async (id) => {
        var data = await ServiceShuttles.getTripInfo(id);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.tripRecord = data;
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceTripTimings = async () => {
        var data = await ServiceShuttles.getTripTimings();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTripTimings: data });
        } else {
            this.onDBError();  // callback
        }
    }

    loadDataSourceTripStatusses = async () => {
        var data = await ServiceShuttles.getTripStatusDropDown();
        if (data !== null && !data.hasOwnProperty("error")) {
            this.setState({ dataSourceTripStatusses: data });
            let waiting = data.find(s => s.name.toUpperCase() === 'WAITING');
            this.statusWaitingId = (waiting !== undefined ? waiting.id : null);
        } else {
            this.onDBError();  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        this.formClosing = false;

        this.refShuttlesPreview.clear();
        this.refShuttlesPreview.setEventDates(this.eventGigDates);

        await this.fillPopup(this.tripId);

        if (this.tripId === -1) {
            // new event
            this.checkBoxKeepOpenInstance.option('visible', true)
            this.setPopupTitle();

        } else {
            // update event
            this.checkBoxKeepOpenInstance.option('visible', false)
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.selectBoxArtistsInstance.focus();
    }

    popup_OnHiding = (e) => {
        this.formClosing = true;
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (tripId) => {
        this.setState({ loadPanelVisible: true });

        this.doNotExecutePrepare = true;

        await this.loadDataSourceTrip(tripId);

        let depId = -1;
        let arrIds = [];

        if (tripId !== -1) {
            for (var i = 0; i < this.tripRecord.subTrips.length; i++) {
                if (this.tripRecord.subTrips[i].isPickupDeparture === true) {
                    depId = this.tripRecord.subTrips[i].locationDepartureId;
                    break;
                }
            }

            for (var i = 0; i < this.tripRecord.subTrips.length; i++) {
                if (this.tripRecord.subTrips[i].isDropOffArrival === true) {
                    arrIds.push(this.tripRecord.subTrips[i].locationArrivalId);
                }
            }
        }
       
        this.setState({ popupHeight: (arrIds.length === 0 || arrIds.length === 1 ? '755px' : '840px') });

        this.selectBoxArtistsInstance.option('value', this.tripRecord.artistId);
        this.textBoxPaxInstance.option('value', this.tripRecord.pax);
        this.selectBoxLocationDepartureInstance.option('value', depId);
        this.selectBoxLocationArrival1Instance.option('value', arrIds[0]);

        if (arrIds.length > 1) {
            document.getElementById('arrival2Div').style.display = "flex";
            document.getElementById('arrival2ButtonsDiv').style.display = "flex";
            this.selectBoxLocationArrival2Instance.option('value', arrIds[1]);
        } else {
            document.getElementById('arrival2Div').style.display = "none";
            document.getElementById('arrival2ButtonsDiv').style.display = "none";
            this.selectBoxLocationArrival2Instance.option('value', null);
        }

        this.selectBoxFleetInstance.option('value', this.tripRecord.carId);
        this.checkBoxTrailerInstance.option('value', this.tripRecord.trailer)
        this.selectBoxDriverInstance.option('value', (this.tripId === -1 ? this.driverTbaId : this.tripRecord.driverId));
        this.selectBoxTripStatussesInstance.option('value', (this.tripId === -1 ? this.statusWaitingId : this.tripRecord.statusId));
        this.htmlEditorNotesInstance.option('value', this.tripRecord.notes);

        if (tripId !== -1) {
           this.refShuttlesPreview.refresh(this.tripRecord.subTrips);
           this.refShuttlesPreview.setSelectedEventDate(this.tripRecord.subTrips[0].dateTimeDeparture);
        }

        this.doNotExecutePrepare = false;

        //WHY I DON'T KNOW
        if (this.selectBoxArtistsInstance.option('value') === -1) {
            this.selectBoxArtistsInstance.option('value', null);
        }
        if (this.selectBoxLocationDepartureInstance.option('value') === -1) {
            this.selectBoxLocationDepartureInstance.option('value', null);
        }
        if (this.selectBoxLocationArrival1Instance.option('value') === -1) {
            this.selectBoxLocationArrival1Instance.option('value', null);
        }
        if (this.selectBoxLocationArrival2Instance.option('value') === -1) {
            this.selectBoxLocationArrival2Instance.option('value', null);
        }
        if (this.selectBoxFleetInstance.option('value') === -1) {
            this.selectBoxFleetInstance.option('value', null);
        }
        if (this.selectBoxDriverInstance.option('value') === -1) {
            this.selectBoxDriverInstance.option('value', null);
        }
        if (this.selectBoxTripStatussesInstance.option('value') === -1) {
            this.selectBoxTripStatussesInstance.option('value', null);
        }

        await this.validationGroupInstance.validate();

        this.setState({ loadPanelVisible: false });
    }

    resetPopup = async () => {
        this.tripId = -1;

        await this.fillPopup(this.tripId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.selectBoxArtistsInstance.focus();
    }

    setPopupTitle = () => {
        if (this.tripId === -1) {
            this.popupInstance.option('title', 'Create shuttle' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.tripRecord.artistName + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // PREVIEW
    preview_onTripTimingUpdate = async () => {
        await this.loadDataSourceTripTimings();
    //   setTimeout(this.prepareSubTrips(), 200);
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateLocation();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    getTripTiming = (locationDepartureId, locationArrivalId) => {
        const t = this.state.dataSourceTripTimings.find(element => ((element.locationDepartureId === locationDepartureId && element.locationArrivalId === locationArrivalId) || (element.locationDepartureId === locationArrivalId && element.locationArrivalId === locationDepartureId)));
        return t;
    }

    prepareSubTrips = () => {
        if (this.formClosing) {
            return;
        }
        if (this.doNotExecutePrepare === true) {
            return;
        }

        const locationDepartureId = this.selectBoxLocationDepartureInstance.option('value');
        const locationArrival1Id = this.selectBoxLocationArrival1Instance.option('value');
        const locationArrival2Id = this.selectBoxLocationArrival2Instance.option('value');

        let subTripsArr = [];
        let tripOrder = -1;
        let dt = new Date();
        dt.setHours(0, 0, 0, 0);

        if (locationDepartureId != null && (locationArrival1Id != null || locationArrival2Id != null)) {
            if (locationArrival2Id == null) {
                tripOrder += 1;
                const tripTiming = this.getTripTiming(locationDepartureId, locationArrival1Id);
                const subTrip = {
                    id: -1,
                    locationDepartureId: locationDepartureId,
                    locationArrivalId: locationArrival1Id,
                    dateTimeDeparture: dt,
                    dateTimeArrival: dt,
                    waitTimePickup: 0,
                    isPickupDeparture: true,
                    isDropOffArrival: true,
                    paxPickup: this.textBoxPaxInstance.option('value'),
                    paxDropOff: this.textBoxPaxInstance.option('value'),
                    minutesMin: tripTiming !== undefined ? tripTiming.minutesMin : 0,
                    minutesMax: tripTiming !== undefined ? tripTiming.minutesMax : 0,
                    tripOrder: tripOrder,
                    locationDeparture: this.selectBoxLocationDepartureInstance.option('text'),
                    locationArrival: this.selectBoxLocationArrival1Instance.option('text'),
                }
                subTripsArr.push(subTrip);
            } else {
                tripOrder += 1;
                const tripTiming = this.getTripTiming(locationDepartureId, locationArrival1Id);
                const subTrip = {
                    id: -1,
                    locationDepartureId: locationDepartureId,
                    locationArrivalId: locationArrival1Id,
                    dateTimeDeparture: dt,
                    dateTimeArrival: dt,
                    waitTimePickup: 0,
                    isPickupDeparture: true,
                    isDropOffArrival: true,
                    paxPickup: this.textBoxPaxInstance.option('value'),
                    paxDropOff: this.textBoxPaxInstance.option('value'),
                    minutesMin: tripTiming !== undefined ? tripTiming.minutesMin : 0,
                    minutesMax: tripTiming !== undefined ? tripTiming.minutesMax : 0,
                    tripOrder: tripOrder,
                    locationDeparture: this.selectBoxLocationDepartureInstance.option('text'),
                    locationArrival: this.selectBoxLocationArrival1Instance.option('text'),
                }
                subTripsArr.push(subTrip);

                tripOrder += 1;
                const tripTiming2 = this.getTripTiming(locationArrival1Id, locationArrival2Id);
                const subTrip2 = {
                    id: -1,
                    locationDepartureId: locationArrival1Id,
                    locationArrivalId: locationArrival2Id,
                    dateTimeDeparture: dt,
                    dateTimeArrival: dt,
                    waitTimePickup: 0,
                    isPickupDeparture: true,
                    isDropOffArrival: true,
                    paxPickup: this.textBoxPaxInstance.option('value'),
                    paxDropOff: this.textBoxPaxInstance.option('value'),
                    minutesMin: tripTiming2 !== undefined ? tripTiming2.minutesMin : 0,
                    minutesMax: tripTiming2 !== undefined ? tripTiming2.minutesMax : 0,
                    tripOrder: tripOrder,
                    locationDeparture: this.selectBoxLocationArrival1Instance.option('text'),
                    locationArrival: this.selectBoxLocationArrival2Instance.option('text'),
               }
                subTripsArr.push(subTrip2);
            }

            // First add trip to gmm if necessary
            if (locationArrival2Id == null) {
                if (locationArrival1Id !== this.eventLocationId) {
                    tripOrder += 1;
                    const tripTiming = this.getTripTiming(locationArrival1Id, this.eventLocationId);
                    const subTrip = {
                        id: -1,
                        locationDepartureId: locationArrival1Id,
                        locationArrivalId: this.eventLocationId,
                        dateTimeDeparture: dt,
                        dateTimeArrival: dt,
                        waitTimePickup: 0,
                        isPickupDeparture: false,
                        isDropOffArrival: false,
                        paxPickup: this.textBoxPaxInstance.option('value'),
                        paxDropOff: this.textBoxPaxInstance.option('value'),
                        minutesMin: tripTiming !== undefined ? tripTiming.minutesMin : 0,
                        minutesMax: tripTiming !== undefined ? tripTiming.minutesMax : 0,
                        tripOrder: tripOrder,
                        locationDeparture: this.selectBoxLocationArrival1Instance.option('text'),
                        locationArrival: 'GMM',
                    }
                    subTripsArr.push(subTrip);
                }
            } else {
                if (locationArrival2Id !== this.eventLocationId) {
                    tripOrder += 1;
                    const tripTiming = this.getTripTiming(locationArrival2Id, this.eventLocationId);
                    const subTrip = {
                        id: -1,
                        locationDepartureId: locationArrival2Id,
                        locationArrivalId: this.eventLocationId,
                        dateTimeDeparture: dt,
                        dateTimeArrival: dt,
                        waitTimePickup: 0,
                        isPickupDeparture: false,
                        isDropOffArrival: false,
                        paxPickup: this.textBoxPaxInstance.option('value'),
                        paxDropOff: this.textBoxPaxInstance.option('value'),
                        minutesMin: tripTiming !== undefined ? tripTiming.minutesMin : 0,
                        minutesMax: tripTiming !== undefined ? tripTiming.minutesMax : 0,
                        tripOrder: tripOrder,
                        locationDeparture: this.selectBoxLocationArrival2Instance.option('text'),
                        locationArrival: 'GMM',
                    }
                    subTripsArr.push(subTrip);
                }
            }

            // Then add from gmm if necessary
            if (locationDepartureId !== this.eventLocationId) {
                subTripsArr.forEach((item) => {
                    item.tripOrder += 1;
                });
                const tripTiming = this.getTripTiming(this.eventLocationId, locationDepartureId);
                const subTrip = {
                    id: -1,
                    locationDepartureId: this.eventLocationId,
                    locationArrivalId: locationDepartureId,
                    dateTimeDeparture: dt,
                    dateTimeArrival: dt,
                    waitTimePickup: 0,
                    isPickupDeparture: false,
                    isDropOffArrival: false,
                    paxPickup: this.textBoxPaxInstance.option('value'),
                    paxDropOff: this.textBoxPaxInstance.option('value'),
                    minutesMin: tripTiming != undefined ? tripTiming.minutesMin : 0,
                    minutesMax: tripTiming != undefined ? tripTiming.minutesMax : 0,
                    tripOrder: 0,
                    locationDeparture: 'GMM',
                    locationArrival: this.selectBoxLocationDepartureInstance.option('text'),
                }
                subTripsArr.unshift(subTrip);
            }

            this.tripRecord.subTrips = subTripsArr;

            this.refShuttlesPreview.refresh(subTripsArr);
        }
    }

    // POPUP LOCATION
    popupLocations_OnInitialized = (instance) => {
        this.popupLocationsInstance = instance;
    }

    popupLocations_onDBError = (message) => {
        this.onDBError(message)
    }

    popupLocations_OnCreate = async (id) => {
        if (id !== -1) {
            HxToast.showToast('Location created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create location failed!', 'error', 'top right', 'up-stack');
        }
    }

    popupLocations_OnUpdate = async (success) => {
        if (success === true) {
            HxToast.showToast('Location updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update location failed!', 'error', 'top right', 'up-stack');
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        if (this.doNotExecutePrepare === true) {
            return;
        }
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // SELECTBOX ARTISTS
    selectBoxArtistsAttributes = {
        id: 'selectBoxArtistsInstance',
    }
    selectBoxArtists_OnInitialized = (e) => {
        this.selectBoxArtistsInstance = e.component;
    }
    
    // SELECTBOX LOCATION DEPARTURE
    selectBoxLocationDeparture_OnInitialized = (e) => {
        this.selectBoxLocationDepartureInstance = e.component;
    }

    selectBoxLocationDeparture_OnValueChanged = (e) => {
        if (this.doNotExecutePrepare === true) {
            return;
        }
        this.prepareSubTrips();
    }

    // SELECTBOX LOCATION ARRIVAL1
    selectBoxLocationArrival1_OnInitialized = (e) => {
        this.selectBoxLocationArrival1Instance = e.component;
    }

    selectBoxLocationArrival1_OnValueChanged = (e) => {
        if (this.doNotExecutePrepare === true) {
            return;
        }
        this.prepareSubTrips();
    }

    // SELECTBOX LOCATION ARRIVAL2
    selectBoxLocationArrival2_OnInitialized = (e) => {
        this.selectBoxLocationArrival2Instance = e.component;
    }

    selectBoxLocationArrival2_OnValueChanged = (e) => {
        if (this.doNotExecutePrepare === true) {
            return;
        }
        this.prepareSubTrips();
    }

    // TEXTBOX PAX
    textBoxPax_OnInitialized = (e) => {
        this.textBoxPaxInstance = e.component;
    }

    // CHECKBOX TRAILER
    checkBoxTrailer_OnInitialized = (e) => {
        this.checkBoxTrailerInstance = e.component;
    }

    checkBoxTrailer_OnValueChanged = (e) => {
    }

    // SELECTBOX FLEET
    selectBoxFleet_OnInitialized = (e) => {
        this.selectBoxFleetInstance = e.component;
    }

    // SELECTBOX DRIVERS
    selectBoxDriver_OnInitialized = (e) => {
        this.selectBoxDriverInstance = e.component;
    }

    // SELECTBOX TRIP STATUS
    selectBoxTripStatusses_OnInitialized = (e) => {
        this.selectBoxTripStatussesInstance = e.component;
    }
    // HTMLEDITOR NOTES
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // BUTTON ADD ARRIVAL 2
    buttonCreateArrival2_OnInitialized = (e) => {
        this.buttonCreateArrival2Instance = e.component;
    }

    buttonCreateArrival2_OnClick = async (e) => {
        this.selectBoxLocationArrival2Instance.option('value', null);
        document.getElementById('arrival2Div').style.display = "flex";
        document.getElementById('arrival2ButtonsDiv').style.display = "flex";
        this.buttonCreateArrival2Instance.option('disabled', true)
        this.setState({ popupHeight: '840px' });

    }

    // BUTTON REMOVE ARRIVAL 2
    buttonRemoveArrival2_OnInitialized = (e) => {
        this.buttonRemoveArrival2Instance = e.component;
    }

    buttonRemoveArrival2_OnClick = async (e) => {
        this.selectBoxLocationArrival2Instance.option('value', null);
        document.getElementById('arrival2Div').style.display = "none";
        document.getElementById('arrival2ButtonsDiv').style.display = "none";
        this.buttonCreateArrival2Instance.option('disabled', false)
        this.setState({ popupHeight: '755px'});
    }

    buttonCreateLocation__OnInitialized = (e) => {
        this.buttonLocationInstance = e.component;

    }

    buttonCreateLocation_OnClick = async (e) => {
        await this.refPopupLocations.preload(-1);
        this.popupLocationsInstance.show();
    }

    // BUTTONS LOCATION
    buttonLocations_OnClick = (e) => {
        var idArr = e.currentTarget.id.split('-');
        if (idArr.length === 3) {
            switch (idArr[0].toUpperCase()) {
                case "DEP":
                    this.selectBoxLocationDepartureInstance.option('value', parseInt(idArr[1], 10))
                    break;
                case "ARR":
                    if (parseInt(idArr[2], 10) === 1) {
                        this.selectBoxLocationArrival1Instance.option('value', parseInt(idArr[1], 10))
                        break;
                    } else if (parseInt(idArr[2], 10) === 2) {
                        this.selectBoxLocationArrival2Instance.option('value', parseInt(idArr[1], 10))
                        break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateTrip();
    }

    updateTrip = async () => {
        const isPreviewValid = this.refShuttlesPreview.validatePreview();

        var res = await this.validationGroupInstance.validate();
       
        if (res.isValid && isPreviewValid === true) {
            const subTrips = this.refShuttlesPreview.getSubTrips();

            this.tripRecord.artistId = this.selectBoxArtistsInstance.option('value');
            this.tripRecord.driverId = this.selectBoxDriverInstance.option('value');
            this.tripRecord.carId = this.selectBoxFleetInstance.option('value');
            this.tripRecord.statusId = this.selectBoxTripStatussesInstance.option('value');
            this.tripRecord.pax = this.textBoxPaxInstance.option('value');
            this.tripRecord.trailer = this.checkBoxTrailerInstance.option('value');
            this.tripRecord.notes = this.htmlEditorNotesInstance.option('value');
            this.tripRecord.subTrips = subTrips;

            let tripModel = {
                id: this.tripRecord.id,
                eventId: this.tripRecord.eventId,
                artistId: this.tripRecord.artistId,
                driverId: this.tripRecord.driverId,
                carId: this.tripRecord.carId,
                statusId: this.tripRecord.statusId,
                pax: this.tripRecord.pax,
                trailer: this.tripRecord.trailer,
                notes: this.tripRecord.notes,
            }

            let subTripsModel = [];
            for (var i = 0; i < subTrips.length; i++) {
                if (i === 0) {
                    this.crudDate = subTrips[i].dateTimeDeparture;
                }
                let model = {
                    id: -1,
                    locationDepartureId: subTrips[i].locationDepartureId,
                    locationArrivalId: subTrips[i].locationArrivalId,
                    dateTimeDeparture: subTrips[i].dateTimeDeparture,
                    dateTimeArrival: subTrips[i].dateTimeArrival,
                    waitTimePickup: subTrips[i].waitTimePickup,
                    isPickupDeparture: subTrips[i].isPickupDeparture,
                    isDropOffArrival: subTrips[i].isDropOffArrival,
                    paxPickup: this.textBoxPaxInstance.option('value'),
                    paxDropOff: this.textBoxPaxInstance.option('value'),
                    minutesMin: subTrips[i].minutesMin,
                    minutesMax: subTrips[i].minutesMax,
                    tripOrder: subTrips[i].tripOrder,
                }
                subTripsModel.push(model);
            }
            tripModel.subTrips = subTripsModel;
            
            if (this.tripId === -1) {
                var resultTrip = await ServiceShuttles.createTrip(tripModel);
                if (!resultTrip.hasOwnProperty("error")) {
                    this.onCreate(resultTrip, this.crudDate); // callback
                    if (resultTrip !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            await this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                var resultTripUpdate = await ServiceShuttles.updateTrip(tripModel);
                if (!resultTripUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultTripUpdate, this.crudDate); // callback
                    if (resultTripUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.selectBoxArtistsInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // RENDERING
    renderLocationDepartureDivs = () =>{
        let uiItems = [];

        const first = this.favouriteLocations.find(f => f.id === this.eventLocationId);
        if (first !== undefined) {
            var locKey = "dep-" + first.id + "-0";
            uiItems.push(
                <div id={locKey} key={locKey} className="pss-button-location" onClick={this.buttonLocations_OnClick}>{first.shortName}</div>
            )
        }

        this.favouriteLocations.forEach((item) => {
            if (item.id !== this.eventLocationId) {
                var locKey = "dep-" + item.id + "-0";
                uiItems.push(
                    <div id={locKey} key={locKey} className="pss-button-location" onClick={this.buttonLocations_OnClick}>{item.shortName}</div>
                )
            }
        });

        return uiItems;
    }

    renderLocationArrivalDivs = (divNo) => {
        let uiItems = [];

        const first = this.favouriteLocations.find(f => f.id === this.eventLocationId);
        if (first !== undefined) {
            var locKey = "arr-" + first.id + "-" + divNo;
            uiItems.push(
                <div id={locKey} key={locKey} className="pss-button-location" onClick={this.buttonLocations_OnClick}>{first.shortName}</div>
            )
        }
        this.favouriteLocations.forEach((item) => {
            if (item.id !== this.eventLocationId) {
                var locKey = "arr-" + item.id + "-" + divNo;
                uiItems.push(
                    <div id={locKey} key={locKey} className="pss-button-location" onClick={this.buttonLocations_OnClick}>{item.shortName}</div>
                )
            }
        });

        return uiItems;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1310}
                    height={this.state.popupHeight}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShown={this.popup_OnShown}
                    onShowing={this.popup_OnShowing}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="pss-top-div">
                                    <div className="pss-top-left-div">
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label-artist">Artist*</div>
                                            <div className="dx-field-value pss-field-value">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceArtists}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxArtists_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                            <div className="dx-field-label pss-top-left-label-pax">Pax*</div>
                                            <div className="dx-field-value pss-field-value">
                                                <NumberBox
                                                    width='60px'
                                                    step={1}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBoxShort_OnFocusOut}
                                                    onInitialized={this.textBoxPax_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <RangeRule min={1} max={8} />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </NumberBox>
                                            </div>
                                        </div>
                                        <div className="pss-dx-field-location-dropdowns">
                                            <div className="dx-field-label pss-top-left-label">Departure*</div>
                                            <div className="dx-field-value pss-field-value-dep">
                                                <div>
                                                    <SelectBox
                                                        dataSource={this.state.dataSourceLocations}
                                                        valueExpr="id"
                                                        displayExpr="name"
                                                        dropDownOptions={{ maxHeight: '200px' }}
                                                        minSearchLength={0}
                                                        searchEnabled={true}
                                                        showClearButton={true}
                                                        showDataBeforeSearch={false}
                                                        width={250}
                                                        onInitialized={this.selectBoxLocationDeparture_OnInitialized}
                                                        onFocusIn={this.selectBox_OnFocusIn}
                                                        onValueChanged={this.selectBoxLocationDeparture_OnValueChanged}
                                                    >
                                                        <Validator>
                                                            <ValidationRule type="required" message="Required" />
                                                        </Validator>
                                                    </SelectBox>
                                                </div>
                                                <div className="pss-button-add-location">
                                                    <Button
                                                        onInitialized={this.buttonCreateLocation_OnInitialized}
                                                        icon="home"
                                                        onClick={this.buttonCreateLocation_OnClick}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label"></div>
                                            <div className="dx-field-value pss-field-value-buttons">
                                                {this.renderLocationDepartureDivs()}
                                            </div>
                                        </div>
                                        <div className="pss-dx-field-location-dropdowns">
                                            <div className="dx-field-label pss-top-left-label">Arrival*</div>
                                            <div className="dx-field-value pss-field-value-arr">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceLocations}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival1_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.selectBoxLocationArrival1_OnValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                                <span className="pss-button-add-arrival">
                                                    <Button
                                                    onInitialized={this.buttonCreateArrival2_OnInitialized}
                                                    icon="add"
                                                    onClick={this.buttonCreateArrival2_OnClick}
                                                    />
                                                </span>
                                                
                                            </div>
                                        </div>
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label"></div>
                                            <div className="dx-field-value pss-field-value-buttons">
                                                {this.renderLocationArrivalDivs(1)}
                                            </div>
                                        </div>
                                        <div id="arrival2Div" className="pss-dx-field-location-dropdowns" style={{ display: 'none' }}>
                                            <div className="dx-field-label pss-top-left-label">Arrival*</div>
                                            <div className="dx-field-value pss-field-value-arr-2">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceLocations}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={true}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxLocationArrival2_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.selectBoxLocationArrival2_OnValueChanged}
                                                >
  
                                                </SelectBox>
                                                <span className="pss-button-remove-arrival">
                                                    <Button
                                                        onInitialized={this.buttonRemoveArrival2_OnInitialized}
                                                        icon="trash"
                                                        onClick={this.buttonRemoveArrival2_OnClick}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div id="arrival2ButtonsDiv" className="pss-dx-field" style={{ display: 'none' }} >
                                            <div className="dx-field-label pss-top-left-label"></div>
                                            <div className="dx-field-value pss-field-value-buttons">
                                                {this.renderLocationArrivalDivs(2)}
                                            </div>
                                        </div>
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label">Vehicle*</div>
                                            <div className="dx-field-value pss-field-value-vehicle">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceFleet}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                     minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxFleet_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                                <div className="pss-trailer-div">
                                                    <CheckBox
                                                        text="Trailer"
                                                        onInitialized={this.checkBoxTrailer_OnInitialized}
                                                        onValueChanged={this.checkBoxTrailer_OnValueChanged}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label">Driver*</div>
                                            <div className="dx-field-value pss-field-value">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceDrivers}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxDriver_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                        <div className="pss-dx-field">
                                            <div className="dx-field-label pss-top-left-label">Status*</div>
                                            <div className="dx-field-value pss-field-value">
                                                <SelectBox
                                                    dataSource={this.state.dataSourceTripStatusses}
                                                    valueExpr="id"
                                                    displayExpr="name"
                                                    dropDownOptions={{ maxHeight: '200px' }}
                                                    minSearchLength={0}
                                                    searchEnabled={true}
                                                    showClearButton={false}
                                                    showDataBeforeSearch={false}
                                                    width={250}
                                                    onInitialized={this.selectBoxTripStatusses_OnInitialized}
                                                    onFocusIn={this.selectBox_OnFocusIn}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </SelectBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pss-top-right-div">
                                        <div className="pss-dx-field pss-dx-field-notes">
                                            <div className="dx-field-value pss-field-value-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='100%'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dx-card pss-bottom-card">
                                <ShuttlesPreview
                                    ref={ref => this.refShuttlesPreview = ref}
                                    tripTimings={this.state.dataSourceTripTimings}
                                    onTripTimingUpdate={this.preview_onTripTimingUpdate }
                                />
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="pss-buttons">
                        <div className="pss-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='pss-buttons-update'>
                            <div className="pss-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon="save"
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                    <PopupLocations
                        ref={ref => this.refPopupLocations = ref}
                        onInitialized={this.popupLocations_OnInitialized}
                        onDBError={this.popupLocations_onDBError}
                        onCreate={this.popupLocations_OnUpdate}
                        onUpdate={this.popupLocations_OnUpdate}
                    />
                    <HxPopupSaveChanges
                        onInitialized={this.popupSaveChanges_onInitialized}
                        onClick={this.popupSaveChanges_onClick}
                    />
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        visible={this.state.loadPanelVisible}
                        showIndicator={true}
                        shading={false}
                        showPane={false}
                        hideOnOutsideClick={false}
                    />
                </Popup>
            </React.Fragment>
        );
    }

}

export default PopupShuttles

