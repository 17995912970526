import axiosapi from '../axiosapi.js';

class ServiceDrivers {

    static getDriversInfo = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/GetDriversInfo', {
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
    static getDriversDropDown = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/GetDriversDropDown', {
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getDriver = async (id) => {
        let returnValue = null;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/GetDriver', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createDriver = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.post('/api/Drivers/CreateDriver', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }


    static updateDriver = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.put('/api/Drivers/UpdateDriver', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteDriver = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.delete('/api/Drivers/DeleteDriver', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static canDriverBeDeleted = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/CanDriverBeDeleted', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesShortNameExist = async (shortName, driverIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/DoesShortNameExist', {
            params: {
                shortName: shortName,
                driverIdToExclude: driverIdToExclude,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesDriverFirstNameExist = async (firstName, lastName, driverIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/DoesDriverNameExist', {
            params: {
                firstName: firstName,
                lastName: lastName,
                driverIdToExclude: driverIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesDriverLastNameExist = async (firstName, lastName, driverIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Drivers/DoesDriverNameExist', {
            params: {
                firstName: firstName,
                lastName: lastName,
                driverIdToExclude: driverIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceDrivers;
