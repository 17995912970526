import React from 'react';
import DateBox from 'devextreme-react/date-box';
import Validator, { AsyncRule, ValidationRule, EmailRule, RangeRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup, dxValidationGroupResult } from 'devextreme-react/validation-group';
import { NumberBox } from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import Converters from '../../../helpers/Converters'

import PopupTripTimings from './PopupTripTimings'

import './ShuttlesPreview.scss';

// props
//      subtrips (required): array of subtrips
//
//      refresh: callable
//      clear: callable
//      setSelectedEventDate: callable
//      setEventDates: callable
//      getSubTrips: callable
//      validatePreview: callable
//
//      callbacks
//          onTripTimingUpdate

class ShuttlesPreview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            render: [],
         }

        this.subTrips = [];

        this.validationGroupInstance = null;

        this.selectBoxEventDateInstance = null;

        this.numberBoxTripTiming1MinInstance = null;
        this.numberBoxTripTiming1MaxInstance = null;
        this.numberBoxTripTiming2MinInstance = null;
        this.numberBoxTripTiming2MaxInstance = null;
        this.numberBoxTripTiming3MinInstance = null;
        this.numberBoxTripTiming3MaxInstance = null;
        this.numberBoxTripTiming4MinInstance = null;
        this.numberBoxTripTiming4MaxInstance = null;

        this.buttonTripTiming1AutoInstance = null;
        this.buttonTripTiming2AutoInstance = null;
        this.buttonTripTiming3AutoInstance = null;
        this.buttonTripTiming4AutoInstance = null;

        this.dateBoxDeparture1Instance = null;
        this.dateBoxDeparture2Instance = null;
        this.dateBoxDeparture3Instance = null;
        this.dateBoxDeparture4Instance = null;
        this.dateBoxDeparture5Instance = null;

        this.numberBoxWaitTime2Instance = null;
        this.numberBoxWaitTime3Instance = null;
        this.numberBoxWaitTime4Instance = null;

        this.displayedLocations = 0;
        this.doNotReCalculate = false;
        this.eventDates = [];
        this.eventDatesDropDown = [];

        this.popupTripTimingsInstance = null;
    }

    // CALLBACKS
    onTripTimingUpdate = () => {
        if (this.props.onTripTimingUpdate) {
            this.props.onTripTimingUpdate();  // callback
        }
    }

    // CALLABLE
    clear = () => {
        this.doNotReCalculate = true;

        let dt = new Date();
        dt.setHours(0, 0, 0, 0);

        document.getElementById('block1').classList.remove('sp-block-visible');
        document.getElementById('block1').classList.add('sp-block-hidden');
        document.getElementById('panel1').classList.remove('sp-panel-pickup');
        document.getElementById('panel1').classList.remove('sp-panel-dropoff');
        document.getElementById('panel1').classList.add('sp-panel-default');
        document.getElementById('panel1Location').innerText = '';
        this.dateBoxDeparture1Instance.option('value', dt);
        document.getElementById('panel1TripTiming').classList.remove('sp-panel-trip-timing-visible');
        document.getElementById('panel1TripTiming').classList.add('sp-panel-trip-timing-hidden');
        this.numberBoxTripTiming1MinInstance.option('value', 0);
        this.numberBoxTripTiming1MaxInstance.option('value', 0);

        document.getElementById('block2').classList.remove('sp-block-visible');
        document.getElementById('block2').classList.add('sp-block-hidden');
        document.getElementById('panel2').classList.remove('sp-panel-pickup');
        document.getElementById('panel2').classList.remove('sp-panel-dropoff');
        document.getElementById('panel2').classList.add('sp-panel-default');
        document.getElementById('panel2Location').innerText = '';
        this.dateBoxDeparture2Instance.option('value', dt);
        this.numberBoxWaitTime2Instance.option('value', 0);
        document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-visible');
        document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-hidden');
        this.numberBoxTripTiming2MinInstance.option('value', 0);
        this.numberBoxTripTiming2MaxInstance.option('value', 0);

        document.getElementById('block3').classList.remove('sp-block-visible');
        document.getElementById('block3').classList.add('sp-block-hidden');
        document.getElementById('panel3').classList.remove('sp-panel-pickup');
        document.getElementById('panel3').classList.remove('sp-panel-dropoff');
        document.getElementById('panel3').classList.add('sp-panel-default');
        document.getElementById('panel3Location').innerText = '';
        this.dateBoxDeparture3Instance.option('value', dt);
        this.numberBoxWaitTime3Instance.option('value', 0);
        document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-visible');
        document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-hidden');
        this.numberBoxTripTiming3MinInstance.option('value', 0);
        this.numberBoxTripTiming3MaxInstance.option('value', 0);

        document.getElementById('block4').classList.remove('sp-block-visible');
        document.getElementById('block4').classList.add('sp-block-hidden');
        document.getElementById('panel4').classList.remove('sp-panel-pickup');
        document.getElementById('panel4').classList.remove('sp-panel-dropoff');
        document.getElementById('panel4').classList.add('sp-panel-default');
        document.getElementById('panel4Location').innerText = '';
        this.dateBoxDeparture4Instance.option('value', dt);
        this.numberBoxWaitTime4Instance.option('value', 0);
        document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-visible');
        document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-hidden');
        this.numberBoxTripTiming4MinInstance.option('value', 0);
        this.numberBoxTripTiming4MaxInstance.option('value', 0);

        document.getElementById('block5').classList.remove('sp-block-visible');
        document.getElementById('block5').classList.add('sp-block-hidden');
        document.getElementById('panel5').classList.remove('sp-panel-pickup');
        document.getElementById('panel5').classList.remove('sp-panel-dropoff');
        document.getElementById('panel5').classList.add('sp-panel-default');
        document.getElementById('panel5Location').innerText = '';
        this.dateBoxDeparture5Instance.option('value', dt);

        this.doNotReCalculate = false;

    }

    // CALLABLE
    refresh = (subTripsArr) => {
        this.subTrips = subTripsArr;
        this.doNotReCalculate = true;

        let countPanel = 0;

        if (subTripsArr[0].isPickupDeparture) {
            document.getElementById('block1').classList.remove('sp-block-hidden');
            document.getElementById('block1').classList.remove('sp-block-visible');
            document.getElementById('block1').classList.add('sp-block-visible');
            document.getElementById('panel1').classList.remove('sp-panel-default');
            document.getElementById('panel1').classList.remove('sp-panel-pickup');
            document.getElementById('panel1').classList.remove('sp-panel-dropoff');
            document.getElementById('panel1').classList.add('sp-panel-pickup');
            document.getElementById('panel1Location').innerText = subTripsArr[0].locationDeparture;
            this.dateBoxDeparture1Instance.option('value', subTripsArr[0].dateTimeDeparture);
            document.getElementById('panel1TripTiming').classList.remove('sp-panel-trip-timing-hidden');
            document.getElementById('panel1TripTiming').classList.add('sp-panel-trip-timing-visible');
            this.numberBoxTripTiming1MinInstance.option('value', subTripsArr[0].minutesMin);
            this.numberBoxTripTiming1MaxInstance.option('value', subTripsArr[0].minutesMax);
            countPanel += 1;
        } else {
            document.getElementById('block1').classList.remove('sp-block-hidden');
            document.getElementById('block1').classList.remove('sp-block-visible');
            document.getElementById('block1').classList.add('sp-block-visible');
            document.getElementById('panel1').classList.remove('sp-panel-default');
            document.getElementById('panel1').classList.remove('sp-panel-pickup');
            document.getElementById('panel1').classList.remove('sp-panel-dropoff');
            document.getElementById('panel1').classList.add('sp-panel-default');
            document.getElementById('panel1Location').innerText = subTripsArr[0].locationDeparture;
            this.dateBoxDeparture1Instance.option('value', subTripsArr[0].dateTimeDeparture);
            document.getElementById('panel1TripTiming').classList.remove('sp-panel-trip-timing-hidden');
            document.getElementById('panel1TripTiming').classList.add('sp-panel-trip-timing-visible');
            this.numberBoxTripTiming1MinInstance.option('value', subTripsArr[0].minutesMin);
            this.numberBoxTripTiming1MaxInstance.option('value', subTripsArr[0].minutesMax);
            countPanel += 1;
        }

        for (var i = 1; i < subTripsArr.length; i++) {
            if (subTripsArr[i].isPickupDeparture && !subTripsArr[i - 1].isDropOffArrival) {
                switch (i) {
                    case 1:
                        document.getElementById('block2').classList.remove('sp-block-hidden');
                        document.getElementById('block2').classList.remove('sp-block-visible');
                        document.getElementById('block2').classList.add('sp-block-visible');
                        document.getElementById('panel2').classList.remove('sp-panel-default');
                        document.getElementById('panel2').classList.remove('sp-panel-pickup');
                        document.getElementById('panel2').classList.remove('sp-panel-dropoff');
                        document.getElementById('panel2').classList.add('sp-panel-pickup');
                        document.getElementById('panel2Location').innerText = subTripsArr[1].locationDeparture;
                        this.dateBoxDeparture2Instance.option('value', subTripsArr[1].dateTimeDeparture);
                        document.getElementById('panel2WaitTime').classList.remove('sp-panel-wait-time-hidden');
                        document.getElementById('panel2WaitTime').classList.add('sp-panel-wait-time-visible');
                        this.numberBoxWaitTime2Instance.option('value', subTripsArr[1].waitTimePickup);
                        document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                        document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-visible');
                        this.numberBoxTripTiming2MinInstance.option('value', subTripsArr[1].minutesMin);
                        this.numberBoxTripTiming2MaxInstance.option('value', subTripsArr[1].minutesMax);
                        break;
                    case 2:
                        document.getElementById('block3').classList.remove('sp-block-hidden');
                        document.getElementById('block3').classList.remove('sp-block-visible');
                        document.getElementById('block3').classList.add('sp-block-visible');
                        document.getElementById('panel3').classList.remove('sp-panel-default');
                        document.getElementById('panel3').classList.remove('sp-panel-pickup');
                        document.getElementById('panel3').classList.remove('sp-panel-dropoff');
                        document.getElementById('panel3').classList.add('sp-panel-pickup');
                        document.getElementById('panel3Location').innerText = subTripsArr[2].locationDeparture;
                        this.dateBoxDeparture3Instance.option('value', subTripsArr[2].dateTimeDeparture);
                        document.getElementById('panel3WaitTime').classList.remove('sp-panel-wait-time-hidden');
                        document.getElementById('panel3WaitTime').classList.add('sp-panel-wait-time-visible');
                        this.numberBoxWaitTime3Instance.option('value', subTripsArr[2].waitTimePickup);
                        document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                        document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-visible');
                        this.numberBoxTripTiming3MinInstance.option('value', subTripsArr[2].minutesMin);
                        this.numberBoxTripTiming3MaxInstance.option('value', subTripsArr[2].minutesMax);
                        break;
                    case 3:
                        document.getElementById('block4').classList.remove('sp-block-hidden');
                        document.getElementById('block4').classList.remove('sp-block-visible');
                        document.getElementById('block4').classList.add('sp-block-visible');
                        document.getElementById('panel4').classList.remove('sp-panel-default');
                        document.getElementById('panel4').classList.remove('sp-panel-pickup');
                        document.getElementById('panel4').classList.remove('sp-panel-dropoff');
                        document.getElementById('panel4').classList.add('sp-panel-pickup');
                        document.getElementById('panel4Location').innerText = subTripsArr[3].locationDeparture;
                        this.dateBoxDeparture4Instance.option('value', subTripsArr[3].dateTimeDeparture);
                        document.getElementById('panel4WaitTime').classList.remove('sp-panel-wait-time-hidden');
                        document.getElementById('panel4WaitTime').classList.add('sp-panel-wait-time-visible');
                        this.numberBoxWaitTime4Instance.option('value', subTripsArr[3].waitTimePickup);
                        document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                        document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-visible');
                        this.numberBoxTripTiming4MinInstance.option('value', subTripsArr[3].minutesMin);
                        this.numberBoxTripTiming4MaxInstance.option('value', subTripsArr[3].minutesMax);
                        break;
                    case 4:
                        document.getElementById('block5').classList.remove('sp-block-hidden');
                        document.getElementById('block5').classList.remove('sp-block-visible');
                        document.getElementById('block5').classList.add('sp-block-visible');
                        document.getElementById('panel5').classList.remove('sp-panel-default');
                        document.getElementById('panel5').classList.remove('sp-panel-pickup');
                        document.getElementById('panel5').classList.remove('sp-panel-dropoff');
                        document.getElementById('panel5').classList.add('sp-panel-pickup');
                        document.getElementById('panel5Location').innerText = subTripsArr[4].locationDeparture;
                        this.dateBoxDeparture5Instance.option('value', subTripsArr[4].dateTimeDeparture);
                        break;
                   default:
                        break;
                }
                countPanel += 1;
            } else {
                if (i < subTripsArr.length && subTripsArr[i - 1].isDropOffArrival) {
                    switch (i) {
                        case 1:
                            document.getElementById('block2').classList.remove('sp-block-hidden');
                            document.getElementById('block2').classList.remove('sp-block-visible');
                            document.getElementById('block2').classList.add('sp-block-visible');
                            document.getElementById('panel2').classList.remove('sp-panel-default');
                            document.getElementById('panel2').classList.remove('sp-panel-pickup');
                            document.getElementById('panel2').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel2').classList.add('sp-panel-dropoff');
                            document.getElementById('panel2Location').innerText = subTripsArr[1].locationDeparture;
                            this.dateBoxDeparture2Instance.option('value', subTripsArr[1].dateTimeDeparture);
                            document.getElementById('panel2WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel2WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime2Instance.option('value', subTripsArr[1].waitTimePickup);
                            document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming2MinInstance.option('value', subTripsArr[1].minutesMin);
                            this.numberBoxTripTiming2MaxInstance.option('value', subTripsArr[1].minutesMax);
                            break;
                        case 2:
                            document.getElementById('block3').classList.remove('sp-block-hidden');
                            document.getElementById('block3').classList.remove('sp-block-visible');
                            document.getElementById('block3').classList.add('sp-block-visible');
                            document.getElementById('panel3').classList.remove('sp-panel-default');
                            document.getElementById('panel3').classList.remove('sp-panel-pickup');
                            document.getElementById('panel3').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel3').classList.add('sp-panel-dropoff');
                            document.getElementById('panel3Location').innerText = subTripsArr[2].locationDeparture;
                            this.dateBoxDeparture3Instance.option('value', subTripsArr[2].dateTimeDeparture);
                            document.getElementById('panel3WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel3WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime3Instance.option('value', subTripsArr[2].waitTimePickup);
                            document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming3MinInstance.option('value', subTripsArr[2].minutesMin);
                            this.numberBoxTripTiming3MaxInstance.option('value', subTripsArr[2].minutesMax);
                            break;
                        case 3:
                            document.getElementById('block4').classList.remove('sp-block-hidden');
                            document.getElementById('block4').classList.remove('sp-block-visible');
                            document.getElementById('block4').classList.add('sp-block-visible');
                            document.getElementById('panel4').classList.remove('sp-panel-default');
                            document.getElementById('panel4').classList.remove('sp-panel-pickup');
                            document.getElementById('panel4').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel4').classList.add('sp-panel-dropoff');
                            document.getElementById('panel4Location').innerText = subTripsArr[3].locationDeparture;
                            this.dateBoxDeparture4Instance.option('value', subTripsArr[3].dateTimeDeparture);
                            document.getElementById('panel4WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel4WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime4Instance.option('value', subTripsArr[3].waitTimePickup);
                            document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming4MinInstance.option('value', subTripsArr[3].minutesMin);
                            this.numberBoxTripTiming4MaxInstance.option('value', subTripsArr[3].minutesMax);
                            break;
                        case 4:
                            document.getElementById('block5').classList.remove('sp-block-hidden');
                            document.getElementById('block5').classList.remove('sp-block-visible');
                            document.getElementById('block5').classList.add('sp-block-visible');
                            document.getElementById('panel5').classList.remove('sp-panel-default');
                            document.getElementById('panel5').classList.remove('sp-panel-pickup');
                            document.getElementById('panel5').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel5').classList.add('sp-panel-dropoff');
                            document.getElementById('panel5Location').innerText = subTripsArr[4].locationDeparture;
                            this.dateBoxDeparture5Instance.option('value', subTripsArr[4].dateTimeDeparture);
                            break;
                        default:
                            break;
                    }
                    countPanel += 1;
                } else {
                   switch (i) {
                        case 1:
                            document.getElementById('block2').classList.remove('sp-block-hidden');
                            document.getElementById('block2').classList.remove('sp-block-visible');
                            document.getElementById('block2').classList.add('sp-block-visible');
                            document.getElementById('panel2').classList.remove('sp-panel-default');
                            document.getElementById('panel2').classList.remove('sp-panel-pickup');
                            document.getElementById('panel2').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel2').classList.add('sp-panel-default');
                            document.getElementById('panel2Location').innerText = subTripsArr[1].locationDeparture;
                            this.dateBoxDeparture2Instance.option('value', subTripsArr[1].dateTimeDeparture);
                            document.getElementById('panel2WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel2WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime2Instance.option('value', subTripsArr[1].waitTimePickup);
                            document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming2MinInstance.option('value', subTripsArr[1].minutesMin);
                            this.numberBoxTripTiming2MaxInstance.option('value', subTripsArr[1].minutesMax);
                            break;
                        case 2:
                            document.getElementById('block3').classList.remove('sp-block-hidden');
                            document.getElementById('block3').classList.remove('sp-block-visible');
                            document.getElementById('block3').classList.add('sp-block-visible');
                            document.getElementById('panel3').classList.remove('sp-panel-default');
                            document.getElementById('panel3').classList.remove('sp-panel-pickup');
                            document.getElementById('panel3').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel3').classList.add('sp-panel-default');
                            document.getElementById('panel3Location').innerText = subTripsArr[2].locationDeparture;
                            this.dateBoxDeparture3Instance.option('value', subTripsArr[2].dateTimeDeparture);
                            document.getElementById('panel3WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel3WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime3Instance.option('value', subTripsArr[2].waitTimePickup);
                            document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming3MinInstance.option('value', subTripsArr[2].minutesMin);
                            this.numberBoxTripTiming3MaxInstance.option('value', subTripsArr[2].minutesMax);
                            break;
                        case 3:
                            document.getElementById('block4').classList.remove('sp-block-hidden');
                            document.getElementById('block4').classList.remove('sp-block-visible');
                            document.getElementById('block4').classList.add('sp-block-visible');
                            document.getElementById('panel4').classList.remove('sp-panel-default');
                            document.getElementById('panel4').classList.remove('sp-panel-pickup');
                            document.getElementById('panel4').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel4').classList.add('sp-panel-default');
                            document.getElementById('panel4Location').innerText = subTripsArr[3].locationDeparture;
                            this.dateBoxDeparture4Instance.option('value', subTripsArr[3].dateTimeDeparture);
                            document.getElementById('panel4WaitTime').classList.remove('sp-panel-wait-time-hidden');
                            document.getElementById('panel4WaitTime').classList.add('sp-panel-wait-time-visible');
                            this.numberBoxWaitTime4Instance.option('value', subTripsArr[3].waitTimePickup);
                            document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-hidden');
                            document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-visible');
                            this.numberBoxTripTiming4MinInstance.option('value', subTripsArr[3].minutesMin);
                            this.numberBoxTripTiming4MaxInstance.option('value', subTripsArr[3].minutesMax);
                            break;
                        case 4:
                            document.getElementById('block5').classList.remove('sp-block-hidden');
                            document.getElementById('block5').classList.remove('sp-block-visible');
                            document.getElementById('block5').classList.add('sp-block-visible');
                            document.getElementById('panel5').classList.remove('sp-panel-default');
                            document.getElementById('panel5').classList.remove('sp-panel-pickup');
                            document.getElementById('panel5').classList.remove('sp-panel-dropoff');
                            document.getElementById('panel5').classList.add('sp-panel-default');
                            document.getElementById('panel5Location').innerText = subTripsArr[4].locationDeparture;
                            this.dateBoxDeparture5Instance.option('value', subTripsArr[4].dateTimeDeparture);
                            break;
                        default:
                            break;
                    }
                    countPanel += 1;
                }
            }
        }

        if (subTripsArr[subTripsArr.length - 1].isDropOffArrival) {
            // GMM DROPOFF
            switch (subTripsArr.length - 1) {
                case 0:
                    document.getElementById('block2').classList.remove('sp-block-hidden');
                    document.getElementById('block2').classList.remove('sp-block-visible');
                    document.getElementById('block2').classList.add('sp-block-visible');
                    document.getElementById('panel2').classList.remove('sp-panel-default');
                    document.getElementById('panel2').classList.remove('sp-panel-pickup');
                    document.getElementById('panel2').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel2').classList.add('sp-panel-dropoff');
                    document.getElementById('panel2Location').innerText = subTripsArr[0].locationArrival;
                    this.dateBoxDeparture2Instance.option('value', subTripsArr[0].dateTimeDeparture);
                    document.getElementById('panel2WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel2WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-c');
                    document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming2MinInstance.option('value', subTripsArr[0].minutesMin);
                    this.numberBoxTripTiming2MaxInstance.option('value', subTripsArr[0].minutesMax);
                    break;
                case 1:
                    document.getElementById('block3').classList.remove('sp-block-hidden');
                    document.getElementById('block3').classList.remove('sp-block-visible');
                    document.getElementById('block3').classList.add('sp-block-visible');
                    document.getElementById('panel3').classList.remove('sp-panel-default');
                    document.getElementById('panel3').classList.remove('sp-panel-pickup');
                    document.getElementById('panel3').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel3').classList.add('sp-panel-dropoff');
                    document.getElementById('panel3Location').innerText = subTripsArr[1].locationArrival;
                    this.dateBoxDeparture3Instance.option('value', subTripsArr[1].dateTimeDeparture);
                    document.getElementById('panel3WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel3WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-visible');
                    document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming3MinInstance.option('value', subTripsArr[1].minutesMin);
                    this.numberBoxTripTiming3MaxInstance.option('value', subTripsArr[1].minutesMax);
                    break;
                case 2:
                    document.getElementById('block4').classList.remove('sp-block-hidden');
                    document.getElementById('block4').classList.remove('sp-block-visible');
                    document.getElementById('block4').classList.add('sp-block-visible');
                    document.getElementById('panel4').classList.remove('sp-panel-default');
                    document.getElementById('panel4').classList.remove('sp-panel-pickup');
                    document.getElementById('panel4').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel4').classList.add('sp-panel-dropoff');
                    document.getElementById('panel4Location').innerText = subTripsArr[2].locationArrival;
                    this.dateBoxDeparture4Instance.option('value', subTripsArr[2].dateTimeDeparture);
                    document.getElementById('panel4WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel4WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-visible');
                    document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming4MinInstance.option('value', subTripsArr[2].minutesMin);
                    this.numberBoxTripTiming4MaxInstance.option('value', subTripsArr[2].minutesMax);
                    break;
                case 3:
                    document.getElementById('block5').classList.remove('sp-block-hidden');
                    document.getElementById('block5').classList.remove('sp-block-visible');
                    document.getElementById('block5').classList.add('sp-block-visible');
                    document.getElementById('panel5').classList.remove('sp-panel-default');
                    document.getElementById('panel5').classList.remove('sp-panel-pickup');
                    document.getElementById('panel5').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel5').classList.add('sp-panel-dropoff');
                    document.getElementById('panel5Location').innerText = subTripsArr[3].locationArrival;
                    this.dateBoxDeparture5Instance.option('value', subTripsArr[3].dateTimeDeparture);
                    break;
                default:
                    break;
            }
            countPanel += 1;
        } else {
            // ADD GMM DROPOFF
            switch (subTripsArr.length - 1) {
                case 0:
                    document.getElementById('block2').classList.remove('sp-block-hidden');
                    document.getElementById('block2').classList.remove('sp-block-visible');
                    document.getElementById('block2').classList.add('sp-block-visible');
                    document.getElementById('panel2').classList.remove('sp-panel-default');
                    document.getElementById('panel2').classList.remove('sp-panel-pickup');
                    document.getElementById('panel2').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel2').classList.add('sp-panel-default');
                    document.getElementById('panel2Location').innerText = subTripsArr[0].locationArrival;
                    this.dateBoxDeparture2Instance.option('value', subTripsArr[0].dateTimeArrival);
                    document.getElementById('panel2WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel2WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel2TripTiming').classList.remove('sp-panel-trip-timing-visible');
                    document.getElementById('panel2TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming2MinInstance.option('value', subTripsArr[0].minutesMin);
                    this.numberBoxTripTiming2MaxInstance.option('value', subTripsArr[0].minutesMax);
                    break;
                case 1:
                    document.getElementById('block3').classList.remove('sp-block-hidden');
                    document.getElementById('block3').classList.remove('sp-block-visible');
                    document.getElementById('block3').classList.add('sp-block-visible');
                    document.getElementById('panel3').classList.remove('sp-panel-default');
                    document.getElementById('panel3').classList.remove('sp-panel-pickup');
                    document.getElementById('panel3').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel3').classList.add('sp-panel-default');
                    document.getElementById('panel3Location').innerText = subTripsArr[1].locationArrival;
                    this.dateBoxDeparture3Instance.option('value', subTripsArr[1].dateTimeArrival);
                    document.getElementById('panel3WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel3WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel3TripTiming').classList.remove('sp-panel-trip-timing-visible');
                    document.getElementById('panel3TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming3MinInstance.option('value', subTripsArr[1].minutesMin);
                    this.numberBoxTripTiming3MaxInstance.option('value', subTripsArr[1].minutesMax);
                    break;
                case 2:
                    document.getElementById('block4').classList.remove('sp-block-hidden');
                    document.getElementById('block4').classList.remove('sp-block-visible');
                    document.getElementById('block4').classList.add('sp-block-visible');
                    document.getElementById('panel4').classList.remove('sp-panel-default');
                    document.getElementById('panel4').classList.remove('sp-panel-pickup');
                    document.getElementById('panel4').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel4').classList.add('sp-panel-default');
                    document.getElementById('panel4Location').innerText = subTripsArr[2].locationArrival;
                    this.dateBoxDeparture4Instance.option('value', subTripsArr[2].dateTimeArrival);
                    document.getElementById('panel4WaitTime').classList.remove('sp-panel-wait-time-hidden');
                    document.getElementById('panel4WaitTime').classList.add('sp-panel-wait-time-hidden');
                    document.getElementById('panel4TripTiming').classList.remove('sp-panel-trip-timing-visible');
                    document.getElementById('panel4TripTiming').classList.add('sp-panel-trip-timing-hidden');
                    this.numberBoxTripTiming4MinInstance.option('value', subTripsArr[2].minutesMin);
                    this.numberBoxTripTiming4MaxInstance.option('value', subTripsArr[2].minutesMax);
                    break;
                case 3:
                    document.getElementById('block5').classList.remove('sp-block-hidden');
                    document.getElementById('block5').classList.remove('sp-block-visible');
                    document.getElementById('block5').classList.add('sp-block-visible');
                    document.getElementById('panel5').classList.remove('sp-panel-default');
                    document.getElementById('panel5').classList.remove('sp-panel-pickup');
                    document.getElementById('panel5').classList.remove('sp-panel-dropoff');
                    document.getElementById('panel5').classList.add('sp-panel-default');
                    document.getElementById('panel5Location').innerText = subTripsArr[3].locationArrival;
                    this.dateBoxDeparture5Instance.option('value', subTripsArr[3].dateTimeArrival);
                    break;
                default:
                    break;
            }
            countPanel += 1;
        }

        for (var i = countPanel; i < 5; i++) {
            switch (i) {
                case 1:
                    document.getElementById('block2').classList.remove('sp-block-hidden');
                    document.getElementById('block2').classList.remove('sp-block-visible');
                    document.getElementById('block2').classList.add('sp-block-hidden');
                    break;
                case 2:
                    document.getElementById('block3').classList.remove('sp-block-hidden');
                    document.getElementById('block3').classList.remove('sp-block-visible');
                    document.getElementById('block3').classList.add('sp-block-hidden');
                    break;
                case 3:
                    document.getElementById('block4').classList.remove('sp-block-hidden');
                    document.getElementById('block4').classList.remove('sp-block-visible');
                    document.getElementById('block4').classList.add('sp-block-hidden');
                    break;
                case 4:
                    document.getElementById('block5').classList.remove('sp-block-hidden');
                    document.getElementById('block5').classList.remove('sp-block-visible');
                    document.getElementById('block5').classList.add('sp-block-hidden');
                    break;
                default:
                    break;
            }
        }

        this.displayedLocations = countPanel;

        this.doNotReCalculate = false;
    }

    // CALLABLE
    setEventDates = (eventDates) => {
        this.eventDates = eventDates;
        this.eventDatesDropDown = [];
        eventDates.forEach((item) => {
            const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            let dt = new Date(item.gigDate);
            this.eventDatesDropDown.push({ id: item.id, gigDate: weekday[dt.getDay()] + ' ' + dt.getDate() + '-' + month[dt.getMonth()] + '-' + dt.getFullYear() });
        });
    }

    // CALLABLE
    setSelectedEventDate = (eventDate) => {
        if (eventDate === null) {
            // new trip
            this.selectBoxEventDateInstance.option('value', null);
        } else {
            // existing trip
            let dtEventDate = new Date(eventDate).setHours(0,0,0,0);
            for (var i = 0; i < this.eventDates.length; i++) {
                let dt = new Date(this.eventDates[i].gigDate).setHours(0,0,0,0);
                if (dt === dtEventDate) {
                    this.selectBoxEventDateInstance.option('value', this.eventDates[i].id);
                    break;
                }
            }
        }
    }

    // CALLABLE
    getSubTrips = () => {
        if (this.dateBoxDeparture1Instance.option('value') !== null && this.selectBoxEventDateInstance.option('value') !== null) {
            var dtEvent = new Date(this.eventDates.filter((item) => {
                return item.id === this.selectBoxEventDateInstance.option('value');
            })[0].gigDate);
            let dtDep = new Date(this.dateBoxDeparture1Instance.option('value'));
            this.dateBoxDeparture1Instance.option('value', new Date(dtDep.setFullYear(dtEvent.getFullYear(), dtEvent.getMonth(), dtEvent.getDate())));
            this.recalculateTimings();
        }

        switch (this.subTrips.length) {
            case 4:
                let dtDep5 = new Date(this.dateBoxDeparture5Instance.option('value'));
                this.subTrips[3].dateTimeDeparture = Converters.ConvertDateToSqlDate(new Date(this.dateBoxDeparture4Instance.option('value')));
                this.subTrips[3].dateTimeArrival = Converters.ConvertDateToSqlDate(dtDep5);
                this.subTrips[3].waitTimePickup = this.numberBoxWaitTime4Instance.option('value');
                this.subTrips[3].minutesMin = this.numberBoxTripTiming4MinInstance.option('value');
                this.subTrips[3].minutesMax = this.numberBoxTripTiming4MaxInstance.option('value');
            case 3:
                let dtDep4 = new Date(this.dateBoxDeparture4Instance.option('value'));
                this.subTrips[2].dateTimeDeparture = Converters.ConvertDateToSqlDate(new Date(this.dateBoxDeparture3Instance.option('value')));
                this.subTrips[2].dateTimeArrival = Converters.ConvertDateToSqlDate(dtDep4);
                this.subTrips[2].waitTimePickup = this.numberBoxWaitTime3Instance.option('value');
                this.subTrips[2].minutesMin = this.numberBoxTripTiming3MinInstance.option('value');
                this.subTrips[2].minutesMax = this.numberBoxTripTiming3MaxInstance.option('value');
            case 2:
                let dtDep3 = new Date(this.dateBoxDeparture3Instance.option('value'));
                this.subTrips[1].dateTimeDeparture = Converters.ConvertDateToSqlDate(new Date(this.dateBoxDeparture2Instance.option('value')));
                this.subTrips[1].dateTimeArrival = Converters.ConvertDateToSqlDate(dtDep3);
                this.subTrips[1].waitTimePickup = this.numberBoxWaitTime2Instance.option('value');
                this.subTrips[1].minutesMin = this.numberBoxTripTiming2MinInstance.option('value');
                this.subTrips[1].minutesMax = this.numberBoxTripTiming2MaxInstance.option('value');
            case 1:
                let dtDep2 = new Date(this.dateBoxDeparture2Instance.option('value'));
                this.subTrips[0].dateTimeDeparture = Converters.ConvertDateToSqlDate(new Date(this.dateBoxDeparture1Instance.option('value')));
                this.subTrips[0].dateTimeArrival = Converters.ConvertDateToSqlDate(dtDep2);
                this.subTrips[0].minutesMin = this.numberBoxTripTiming1MinInstance.option('value');
                this.subTrips[0].minutesMax = this.numberBoxTripTiming1MaxInstance.option('value');
            default:
                break;
        }

        return this.subTrips;
    }

    // CALLABLE
    validatePreview = () => {
        this.validationGroupInstance.validate();

        let isValid = false;

        switch (this.displayedLocations) {
            case 5:
                isValid = this.dateBoxDeparture5Instance.option('isValid') === true
                    && this.dateBoxDeparture4Instance.option('isValid') === true && this.numberBoxTripTiming4MinInstance.option('isValid') === true && this.numberBoxTripTiming4MaxInstance.option('isValid') === true && this.numberBoxWaitTime4Instance.option('isValid') === true
                    && this.dateBoxDeparture3Instance.option('isValid') === true && this.numberBoxTripTiming3MinInstance.option('isValid') === true && this.numberBoxTripTiming3MaxInstance.option('isValid') === true && this.numberBoxWaitTime3Instance.option('isValid') === true
                    && this.dateBoxDeparture2Instance.option('isValid') === true && this.numberBoxTripTiming2MinInstance.option('isValid') === true && this.numberBoxTripTiming2MaxInstance.option('isValid') === true && this.numberBoxWaitTime2Instance.option('isValid') === true
                    && this.dateBoxDeparture1Instance.option('isValid') === true && this.numberBoxTripTiming1MinInstance.option('isValid') === true && this.numberBoxTripTiming1MaxInstance.option('isValid') === true
                break;
            case 4:
                isValid = this.dateBoxDeparture4Instance.option('isValid') === true
                    && this.dateBoxDeparture3Instance.option('isValid') === true && this.numberBoxTripTiming3MinInstance.option('isValid') === true && this.numberBoxTripTiming3MaxInstance.option('isValid') === true && this.numberBoxWaitTime3Instance.option('isValid') === true
                    && this.dateBoxDeparture2Instance.option('isValid') === true && this.numberBoxTripTiming2MinInstance.option('isValid') === true && this.numberBoxTripTiming2MaxInstance.option('isValid') === true && this.numberBoxWaitTime2Instance.option('isValid') === true
                    && this.dateBoxDeparture1Instance.option('isValid') === true && this.numberBoxTripTiming1MinInstance.option('isValid') === true && this.numberBoxTripTiming1MaxInstance.option('isValid') === true
                break;
            case 3:
                isValid = this.dateBoxDeparture3Instance.option('isValid') === true
                    && this.dateBoxDeparture2Instance.option('isValid') === true && this.numberBoxTripTiming2MinInstance.option('isValid') === true && this.numberBoxTripTiming2MaxInstance.option('isValid') === true && this.numberBoxWaitTime2Instance.option('isValid') === true
                    && this.dateBoxDeparture1Instance.option('isValid') === true && this.numberBoxTripTiming1MinInstance.option('isValid') === true && this.numberBoxTripTiming1MaxInstance.option('isValid') === true
                break;
            case 2:
                isValid = this.dateBoxDeparture2Instance.option('isValid') === true
                    && this.dateBoxDeparture1Instance.option('isValid') === true && this.numberBoxTripTiming1MinInstance.option('isValid') === true && this.numberBoxTripTiming1MaxInstance.option('isValid') === true
                break;
            default:
                break;
        }

        return (this.selectBoxEventDateInstance.option('isValid') === true
            && isValid === true
            && this.validateAllTimings() === true);
    }

    // EVENTS


    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    numberBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    selectBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    // NUMBERBOX TRIPTIMING 1 MIN
    numberBoxTripTiming1Min_OnInitialized = (e) => {
        this.numberBoxTripTiming1MinInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 1 MAX
    numberBoxTripTiming1Max_OnInitialized = (e) => {
        this.numberBoxTripTiming1MaxInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 2 MIN
    numberBoxTripTiming2Min_OnInitialized = (e) => {
        this.numberBoxTripTiming2MinInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 2 MAX
    numberBoxTripTiming2Max_OnInitialized = (e) => {
        this.numberBoxTripTiming2MaxInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 3 MIN
    numberBoxTripTiming3Min_OnInitialized = (e) => {
        this.numberBoxTripTiming3MinInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 3 MAX
    numberBoxTripTiming3Max_OnInitialized = (e) => {
        this.numberBoxTripTiming3MaxInstance = e.component;
    }
    // NUMBERBOX TRIPTIMING 4 MIN
    numberBoxTripTiming4Min_OnInitialized = (e) => {
        this.numberBoxTripTiming4MinInstance = e.component;
    }

    // NUMBERBOX TRIPTIMING 4 MAX
    numberBoxTripTiming4Max_OnInitialized = (e) => {
        this.numberBoxTripTiming4MaxInstance = e.component;
    }

    // BUTTON TRIP TIMING 1 AUTO
    buttonTripTiming1Auto_OnInitialized = (e) => {
        this.buttonTripTiming1AutoInstance = e.component;
    }

    buttonTripTiming1Auto_OnClick = async (e) => {
        if (this.props.tripTimings !== undefined) {
            const tripTiming = this.props.tripTimings.find(element => ((element.locationDepartureId === this.subTrips[0].locationDepartureId && element.locationArrivalId === this.subTrips[0].locationArrivalId) || (element.locationDepartureId === this.subTrips[0].locationArrivalId && element.locationArrivalId === this.subTrips[0].locationDepartureId)));
            this.numberBoxTripTiming1MinInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMin : 0)
            this.numberBoxTripTiming1MaxInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMax : 0)

            if (tripTiming === undefined) {
                this.refPopupTripTimings.preload(this.subTrips[0].locationDepartureId, this.subTrips[0].locationArrivalId);
                this.popupTripTimingsInstance.show();
            }
        }
    }

    // BUTTON TRIP TIMING 2 AUTO
    buttonTripTiming2Auto_OnInitialized = (e) => {
        this.buttonTripTiming2AutoInstance = e.component;
    }

    buttonTripTiming2Auto_OnClick = async (e) => {
        if (this.props.tripTimings !== undefined) {
            const tripTiming = this.props.tripTimings.find(element => ((element.locationDepartureId === this.subTrips[1].locationDepartureId && element.locationArrivalId === this.subTrips[1].locationArrivalId) || (element.locationDepartureId === this.subTrips[1].locationArrivalId && element.locationArrivalId === this.subTrips[1].locationDepartureId)));
            this.numberBoxTripTiming2MinInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMin : 0)
            this.numberBoxTripTiming2MaxInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMax : 0)

            if (tripTiming === undefined) {
                this.refPopupTripTimings.preload(this.subTrips[1].locationDepartureId, this.subTrips[1].locationArrivalId);
                this.popupTripTimingsInstance.show();
            }
        }
    }

    // BUTTON TRIP TIMING 3 AUTO
    buttonTripTiming3Auto_OnInitialized = (e) => {
        this.buttonTripTiming3AutoInstance = e.component;
    }

    buttonTripTiming3Auto_OnClick = async (e) => {
        if (this.props.tripTimings !== undefined) {
            const tripTiming = this.props.tripTimings.find(element => ((element.locationDepartureId === this.subTrips[2].locationDepartureId && element.locationArrivalId === this.subTrips[2].locationArrivalId) || (element.locationDepartureId === this.subTrips[2].locationArrivalId && element.locationArrivalId === this.subTrips[2].locationDepartureId)));
            this.numberBoxTripTiming3MinInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMin : 0)
            this.numberBoxTripTiming3MaxInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMax : 0)

            if (tripTiming === undefined) {
                this.refPopupTripTimings.preload(this.subTrips[2].locationDepartureId, this.subTrips[2].locationArrivalId);
                this.popupTripTimingsInstance.show();
            }
        }
    }

    // BUTTON TRIP TIMING 4 AUTO
    buttonTripTiming4Auto_OnInitialized = (e) => {
        this.buttonTripTiming4AutoInstance = e.component;
    }

    buttonTripTiming4Auto_OnClick = async (e) => {
        if (this.props.tripTimings !== undefined) {
            const tripTiming = this.props.tripTimings.find(element => ((element.locationDepartureId === this.subTrips[3].locationDepartureId && element.locationArrivalId === this.subTrips[3].locationArrivalId) || (element.locationDepartureId === this.subTrips[3].locationArrivalId && element.locationArrivalId === this.subTrips[3].locationDepartureId)));
            this.numberBoxTripTiming4MinInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMin : 0)
            this.numberBoxTripTiming4MaxInstance.option('value', tripTiming !== undefined ? tripTiming.minutesMax : 0)

            if (tripTiming === undefined) {
                this.refPopupTripTimings.preload(this.subTrips[3].locationDepartureId, this.subTrips[3].locationArrivalId);
                this.popupTripTimingsInstance.show();
            }
        }
    }

    // SELECTBOX EVENT DATE
    selectBoxEventDate_OnInitialized = (e) => {
        this.selectBoxEventDateInstance = e.component;
    }

    selectBoxEventDate_OnValueChanged = (e) => {
        if (e.value !== null) {
            if (this.dateBoxDeparture1Instance.option('value') !== null) {
                var dtEvent = new Date(this.eventDates.filter((item) => {
                    return item.id === e.value;
                })[0].gigDate);
                let dtDep = new Date(this.dateBoxDeparture1Instance.option('value'));
                this.dateBoxDeparture1Instance.option('value', new Date(dtDep.setFullYear(dtEvent.getFullYear(), dtEvent.getMonth(), dtEvent.getDate())));
                this.recalculateTimings();
            }
        }
    }

    // BUTTONS EVENT DATE
    buttonEventDate_OnClick = (e) => {
        var idArr = e.currentTarget.id.split('-');
        if (idArr.length === 2) {
            this.selectBoxEventDateInstance.option('value', parseInt(idArr[1], 10))
        }
    }

    // DATEBOX DEPARTURE 1
    dateBoxDeparture1_OnInitialized = (e) => {
        this.dateBoxDeparture1Instance = e.component;
    }

    // DATEBOX DEPARTURE 2
    dateBoxDeparture2_OnInitialized = (e) => {
        this.dateBoxDeparture2Instance = e.component;
    }
    
    // DATEBOX DEPARTURE 3
    dateBoxDeparture3_OnInitialized = (e) => {
        this.dateBoxDeparture3Instance = e.component;
    }

    // DATEBOX DEPARTURE 4
    dateBoxDeparture4_OnInitialized = (e) => {
        this.dateBoxDeparture4Instance = e.component;
    }

     // DATEBOX DEPARTURE 5
    dateBoxDeparture5_OnInitialized = (e) => {
        this.dateBoxDeparture5Instance = e.component;
    }

    // NUMBERBOX WAIT TIME 2
    numberBoxWaitTime2_OnInitialized = (e) => {
        this.numberBoxWaitTime2Instance = e.component;
    }

    // NUMBERBOX WAIT TIME 3
    numberBoxWaitTime3_OnInitialized = (e) => {
        this.numberBoxWaitTime3Instance = e.component;
    }

    // NUMBERBOX WAIT TIME 4
    numberBoxWaitTime4_OnInitialized = (e) => {
        this.numberBoxWaitTime4Instance = e.component;
    }

    // POPUP triptiming
    popupTripTimings_OnInitialized = (instance) => {
        this.popupTripTimingsInstance = instance;
    }

    popupTripTimings_OnUpdate = async (success) => {
        this.onTripTimingUpdate();
    }

    // ALL VALUECHANGED
    preview_OnValueChanged = (e) => {
        if (this.doNotReCalculate === true) {
            return;
        }

        if (e.value !== undefined) {
            this.recalculateTimings();
        }
    }

    preview_OnValueChangedDateBoxDeparture2 = (e) => {
        if (this.doNotReCalculate === true) {
            return;
        }

        if (e.value !== undefined) {
            this.dateBoxDeparture1Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), -this.numberBoxTripTiming1MaxInstance.option('value')));
            this.recalculateTimings();
        }
    }

    preview_OnValueChangedDateBoxDeparture3 = (e) => {
        if (this.doNotReCalculate === true) {
            return;
        }

        if (e.value !== undefined) {
            this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture3Instance.option('value'), -(this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value')) ));
            this.dateBoxDeparture1Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), -(this.numberBoxTripTiming1MaxInstance.option('value')) ));
            this.recalculateTimings();
        }
    }

    preview_OnValueChangedDateBoxDeparture4 = (e) => {
        if (this.doNotReCalculate === true) {
            return;
        }

        if (e.value !== undefined) {
            this.dateBoxDeparture3Instance.option('value', this.addMinutes(this.dateBoxDeparture4Instance.option('value'), -(this.numberBoxTripTiming3MaxInstance.option('value') + this.numberBoxWaitTime3Instance.option('value'))));
            this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture3Instance.option('value'), -(this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value'))));
            this.dateBoxDeparture1Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), -(this.numberBoxTripTiming1MaxInstance.option('value'))));
            this.recalculateTimings();
        }
    }

    preview_OnValueChangedDateBoxDeparture5 = (e) => {
        if (this.doNotReCalculate === true) {
            return;
        }

        if (e.value !== undefined) {
            this.dateBoxDeparture4Instance.option('value', this.addMinutes(this.dateBoxDeparture5Instance.option('value'), -(this.numberBoxTripTiming4MaxInstance.option('value') + this.numberBoxWaitTime4Instance.option('value'))));
            this.dateBoxDeparture3Instance.option('value', this.addMinutes(this.dateBoxDeparture4Instance.option('value'), -(this.numberBoxTripTiming3MaxInstance.option('value') + this.numberBoxWaitTime3Instance.option('value'))));
            this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture3Instance.option('value'), -(this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value'))));
            this.dateBoxDeparture1Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), -(this.numberBoxTripTiming1MaxInstance.option('value'))));
            this.recalculateTimings();
        }
    }

    addMinutes = (date, minutes) => {
        var dt = new Date(date);
        return new Date(dt.getTime() + minutes * 60000);
    }

    subtractMinutes = (date, minutes) => {
        var dt = new Date(date);
        return new Date(dt.getTime() - minutes * 60000);
    }

    recalculateTimings = () => {
        if (this.doNotReCalculate === true) {
            return;
        }
        this.doNotReCalculate = true;

        switch (this.displayedLocations) {
            case 2:
                this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture1Instance.option('value'), this.numberBoxTripTiming1MaxInstance.option('value')));
                break;
            case 3:
                this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture1Instance.option('value'), this.numberBoxTripTiming1MaxInstance.option('value')));
                this.dateBoxDeparture3Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value')));
                break;
            case 4:
                this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture1Instance.option('value'), this.numberBoxTripTiming1MaxInstance.option('value')));
                this.dateBoxDeparture3Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value')));
                this.dateBoxDeparture4Instance.option('value', this.addMinutes(this.dateBoxDeparture3Instance.option('value'), this.numberBoxTripTiming3MaxInstance.option('value') + this.numberBoxWaitTime3Instance.option('value')));
                break;
            case 5:
                this.dateBoxDeparture2Instance.option('value', this.addMinutes(this.dateBoxDeparture1Instance.option('value'), this.numberBoxTripTiming1MaxInstance.option('value')));
                this.dateBoxDeparture3Instance.option('value', this.addMinutes(this.dateBoxDeparture2Instance.option('value'), this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value')));
                this.dateBoxDeparture4Instance.option('value', this.addMinutes(this.dateBoxDeparture3Instance.option('value'), this.numberBoxTripTiming3MaxInstance.option('value') + this.numberBoxWaitTime3Instance.option('value')));
                this.dateBoxDeparture5Instance.option('value', this.addMinutes(this.dateBoxDeparture4Instance.option('value'), this.numberBoxTripTiming4MaxInstance.option('value') + this.numberBoxWaitTime4Instance.option('value')));
                break;
            default:
                break;
        }

        this.doNotReCalculate = false;
    }

    validateAllTimings = () => {
        let retValue = true;

        switch (this.displayedLocations) {
            case 5:
                if (new Date(this.dateBoxDeparture5Instance.option('value')).getTime() !== this.addMinutes(new Date(this.dateBoxDeparture4Instance.option('value')).getTime(), this.numberBoxTripTiming4MaxInstance.option('value') + this.numberBoxWaitTime4Instance.option('value')).getTime()) {
                    retValue = false;
                    break;
                }
            case 4:
                if (new Date(this.dateBoxDeparture4Instance.option('value')).getTime() !== this.addMinutes(new Date(this.dateBoxDeparture3Instance.option('value')).getTime(), this.numberBoxTripTiming3MaxInstance.option('value') + this.numberBoxWaitTime3Instance.option('value')).getTime()) {
                    retValue = false;
                    break;
                }
            case 3:
                if (new Date(this.dateBoxDeparture3Instance.option('value')).getTime() !== this.addMinutes(new Date(this.dateBoxDeparture2Instance.option('value')).getTime(), this.numberBoxTripTiming2MaxInstance.option('value') + this.numberBoxWaitTime2Instance.option('value')).getTime()) {
                    retValue = false;
                    break;
                }
            case 2:
                if (new Date(this.dateBoxDeparture2Instance.option('value')).getTime() !== this.addMinutes(new Date(this.dateBoxDeparture1Instance.option('value')).getTime(), this.numberBoxTripTiming1MaxInstance.option('value')).getTime()) {
                    retValue = false;
                    break;
                }
            default:
                break;
        }

        return retValue;
    }

    // RENDERING
    renderEventDatesDivs = () => {
        let uiItems = [];

        this.eventDates.forEach((item) => {
            let dt = new Date(item.gigDate);
            const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            let day = weekday[dt.getDay()];
            var locKey = "eventdate-" + item.id;
            uiItems.push(
                <div id={locKey} key={locKey} className="sp-button-event" onClick={this.buttonEventDate_OnClick}>{day}</div>
            )
        });

        return uiItems;
    }

    render() {
        return (
            <React.Fragment>
                <ValidationGroup
                    onInitialized={this.validationGroup_OnInitialized}
                >
                    <div className="sp-main-div">
                        <div className="sp-event-date-div">
                            <div className="sp-event-date-date-box">
                                <SelectBox
                                    dataSource={this.eventDatesDropDown}
                                    valueExpr="id"
                                    displayExpr="gigDate"
                                    dropDownOptions={{ maxHeight: '200px' }}
                                    minSearchLength={0}
                                    searchEnabled={true}
                                    showClearButton={true}
                                    showDataBeforeSearch={false}
                                    width={250}
                                    onInitialized={this.selectBoxEventDate_OnInitialized}
                                    onFocusIn={this.selectBox_OnFocusIn}
                                    onValueChanged={this.selectBoxEventDate_OnValueChanged}
                                >
                                    <Validator>
                                        <ValidationRule type="required" message="Required" />
                                    </Validator>
                                </SelectBox>
                             </div>
                            <div className="sp-event-date-buttons">
                                {this.renderEventDatesDivs()}
                            </div>
                        </div>
                        <div className="sp-preview-div">
                            <div id="block1" className="sp-main-div-render-html sp-block-hidden">
                                <div id="panel1" className="sp-panel-div sp-panel-default">
                                    <div id="panel1Location" className="sp-panel-location">

                                    </div>
                                    <div className="sp-panel-time">
                                        <DateBox
                                            type='time'
                                            displayFormat='HH:mm'
                                            pickerType='native'
                                            inputAttr={{ id: 'dateBoxDeparture1' }}
                                            onInitialized={this.dateBoxDeparture1_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                </div>
                                <div id='panel1TripTiming' className="sp-panel-trip-timing sp-panel-trip-timing-hidden">
                                    <div id="panel1TripTimingDivs">
                                        <NumberBox
                                            width='70px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming1Min' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming1Min_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>&nbsp;
                                        <NumberBox
                                            width='70px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming1Max' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming1Max_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                    <div id="panel1TripTimingAutoButton">
                                        <Button
                                            onInitialized={this.buttonTripTiming1Auto_OnInitialized}
                                            icon='car'
                                            text=""
                                            onClick={this.buttonTripTiming1Auto_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id="block2" className="sp-main-div-render-html sp-block-hidden">
                                <div id="panel2" className="sp-panel-div sp-panel-default">
                                    <div id="panel2Location" className="sp-panel-location">

                                    </div>
                                    <div className="sp-panel-time">
                                        <DateBox
                                            type='time'
                                            displayFormat='HH:mm'
                                            pickerType='native'
                                            inputAttr={{ id: 'dateBoxDeparture2' }}
                                            onInitialized={this.dateBoxDeparture2_OnInitialized}
                                            onValueChanged={this.preview_OnValueChangedDateBoxDeparture2}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                    <div id='panel2WaitTime' className="sp-panel-wait-time">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'waitTime2' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxWaitTime2_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                                <div id='panel2TripTiming' className="sp-panel-trip-timing sp-panel-trip-timing-hidden">
                                    <div id="panel2TripTimingDivs">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming2Min' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming2Min_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>&nbsp;
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming2Max' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming2Max_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                    <div id="panel2TripTimingAutoButton">
                                        <Button
                                            onInitialized={this.buttonTripTiming2Auto_OnInitialized}
                                            icon='car'
                                            text=""
                                            onClick={this.buttonTripTiming2Auto_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id="block3" className="sp-main-div-render-html sp-block-hidden">
                                <div id="panel3" className="sp-panel-div sp-panel-default">
                                    <div id="panel3Location" className="sp-panel-location">

                                    </div>
                                    <div className="sp-panel-time">
                                        <DateBox
                                            type='time'
                                            displayFormat='HH:mm'
                                            pickerType='native'
                                            inputAttr={{ id: 'dateBoxDeparture3' }}
                                            onInitialized={this.dateBoxDeparture3_OnInitialized}
                                            onValueChanged={this.preview_OnValueChangedDateBoxDeparture3}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                    <div id='panel3WaitTime' className="sp-panel-wait-time">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'waitTime3' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxWaitTime3_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                                <div id='panel3TripTiming' className="sp-panel-trip-timing sp-panel-trip-timing-hidden">
                                    <div id="panel3TripTimingDivs">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming3Min' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming3Min_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>&nbsp;
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming3Max' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming3Max_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                    <div id="panel3TripTimingAutoButton">
                                        <Button
                                            onInitialized={this.buttonTripTiming3Auto_OnInitialized}
                                            icon='car'
                                            text=""
                                            onClick={this.buttonTripTiming3Auto_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id="block4" className="sp-main-div-render-html sp-block-hidden">
                                <div id="panel4" className="sp-panel-div sp-panel-default">
                                    <div id="panel4Location" className="sp-panel-location">

                                    </div>
                                    <div className="sp-panel-time">
                                        <DateBox
                                            type='time'
                                            displayFormat='HH:mm'
                                            pickerType='native'
                                            inputAttr={{ id: 'dateBoxDeparture4' }}
                                            onInitialized={this.dateBoxDeparture4_OnInitialized}
                                            onValueChanged={this.preview_OnValueChangedDateBoxDeparture4}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                    <div id='panel4WaitTime' className="sp-panel-wait-time">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'waitTime4' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxWaitTime4_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                </div>
                                <div id='panel4TripTiming' className="sp-panel-trip-timing sp-panel-trip-timing-hidden">
                                    <div id="panel4TripTimingDivs">
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming4Min' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming4Min_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>&nbsp;
                                        <NumberBox
                                            width='60px'
                                            step={1}
                                            inputAttr={{ id: 'tripTiming4Max' }}
                                            onFocusIn={this.numberBox_OnFocusIn}
                                            onInitialized={this.numberBoxTripTiming4Max_OnInitialized}
                                            onValueChanged={this.preview_OnValueChanged}
                                        >
                                            <Validator>
                                                <RangeRule min={1} />
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </NumberBox>
                                    </div>
                                    <div id="panel4TripTimingAutoButton">
                                        <Button
                                            onInitialized={this.buttonTripTiming4Auto_OnInitialized}
                                            icon='car'
                                            text=""
                                            onClick={this.buttonTripTiming4Auto_OnClick}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div id="block5" className="sp-main-div-render-html sp-block-hidden">
                                <div id="panel5" className="sp-panel-div sp-panel-default">
                                    <div id="panel5Location" className="sp-panel-location">

                                    </div>
                                    <div className="sp-panel-time">
                                        <DateBox
                                            type='time'
                                            displayFormat='HH:mm'
                                            pickerType='native'
                                            inputAttr={{ id: 'dateBoxDeparture5' }}
                                            onInitialized={this.dateBoxDeparture5_OnInitialized}
                                            onValueChanged={this.preview_OnValueChangedDateBoxDeparture5}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </DateBox>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </ValidationGroup>

                <PopupTripTimings
                    ref={ref => this.refPopupTripTimings = ref}
                    locationDepartureId={-1}
                    locationArrivalId={-1}
                    onInitialized={this.popupTripTimings_OnInitialized}
                    onUpdate={this.popupTripTimings_OnUpdate}
                />

            </React.Fragment>
        );
    }
}

export default ShuttlesPreview
