import React from 'react';
import { ShuttlesUpcoming } from '../../../components';

class shuttlesUpcoming extends React.Component {
    render() {
        return (
            <ShuttlesUpcoming />
        );
    }
}

export default shuttlesUpcoming