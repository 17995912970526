import React from 'react';
import Validator, { AsyncRule, ValidationRule, CustomRule, StringLengthRule } from 'devextreme-react/validator';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { Button } from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';

import ServiceUsers from '../../../api/services/ServiceUsers';

import HxToast from '../../custom-components/hx-toast/HxToast';

import globalConfig from '../../../config/global.js';
import globalIcons from '../../../config/globalIcons.js';

import './UserProfile.scss'

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.textBoxEmailInstance = null;
        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxPasswordInstance = null;
        this.textBoxConfirmPasswordInstance = null;

        this.userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        this.userId = -1;
        this.userRecord = {};
    }

    // EVENTS
    componentDidMount = async () => {
        this.userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        this.userId = this.userAuth.id;
        await this.loadDataSourceUser(this.userId);
        this.textBoxFirstNameInstance.focus();
    }

    // DATA
    loadDataSourceUser = async (id) => {
        var data = await ServiceUsers.getUserForUpdate(id);
        if (!data.hasOwnProperty('error')) {
            if (data !== null) {
                this.userRecord = data;
                var image = document.getElementById('userImage');
                if (this.userRecord.avatarUrl != null) {
                    image.src = globalConfig.settings.images.userImagesPath + this.userRecord.avatarUrl;
                } else {
                    image.src = globalConfig.settings.images.userImagesPath + "default_250.jpg";
                }
                this.textBoxEmailInstance.option('value', this.userRecord.email);
                this.textBoxFirstNameInstance.option('value', this.userRecord.firstName);
                this.textBoxLastNameInstance.option('value', this.userRecord.lastName);
                this.textBoxPasswordInstance.option('value', this.userRecord.password);
                this.textBoxConfirmPasswordInstance.option('value', this.userRecord.password);
            } else {
                this.onDBError('User not found!');  // callback
            }
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        // select all text
        e.element.querySelector('.dx-texteditor-input').select();
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
        var check = this.textBoxPasswordInstance.option('value') === this.textBoxConfirmPasswordInstance.option('value');
        this.textBoxPasswordInstance.option('isValid', check)
        this.textBoxConfirmPasswordInstance.option('isValid', check)
    }

    // TEXTBOX FIRST NAME
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX FIRST NAME
    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX PASSWORD
    textBoxPassword_OnInitialized = (e) => {
        this.textBoxPasswordInstance = e.component;
    }

    // TEXTBOX PASSWORD
    textBoxConfirmPassword_OnInitialized = (e) => {
        this.textBoxConfirmPasswordInstance = e.component;
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateUser();
    }

    updateUser = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var checkPassword = this.textBoxPasswordInstance.option('value') === this.textBoxConfirmPasswordInstance.option('value');
        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true &&  checkPassword === true) {
            this.userRecord.firstName = this.textBoxFirstNameInstance.option('value');
            this.userRecord.lastName = this.textBoxLastNameInstance.option('value');
            this.userRecord.password = this.textBoxPasswordInstance.option('value');

            // edit user
            var resultUserUpdate = await ServiceUsers.updateUser(this.userRecord);
            if (!resultUserUpdate.hasOwnProperty("error")) {
                if (resultUserUpdate === true) {
                    HxToast.showToast('User updated', 'success', 'top right', 'up-stack');
                } else {
                    HxToast.showToast('Update user failed!', 'error', 'top right', 'up-stack');
                }
            } else {
                HxToast.showToast('Database error!', 'error', 'top center', 'up-stack');
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATIONGROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    render() {
        return (
            <React.Fragment>
                <ValidationGroup
                    onInitialized={this.validationGroup_OnInitialized}
                >
                    <div>
                        <div className="up-content">
                            <div className="dx-card up-card">
                                <div className='up-title'>Profile</div>
                                <div className="up-fields">
                                    <div className="up-avatar-wrapper">
                                        <div className="up-avatar">
                                            <img id="userImage" src="" alt="" />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label up-field-label">Email*</div>
                                        <div className="dx-field-value up-field-value">
                                            <TextBox
                                                width='250px'
                                                disabled={true}
                                                onInitialized={this.textBoxEmail_OnInitialized}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <AsyncRule validationCallback={this.validateEmail} message="Email exists" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label up-field-label">First Name</div>
                                        <div className="dx-field-value up-field-value">
                                            <TextBox
                                                width='250px'
                                                onInitialized={this.textBoxFirstName_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                            />
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label up-field-label">Last Name</div>
                                        <div className="dx-field-value up-field-value">
                                            <TextBox
                                                width='200px'
                                                onInitialized={this.textBoxLastName_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                            />
                                        </div>
                                    </div>

                                    <div className="dx-field">
                                        <div className="dx-field-label up-field-label">Password*</div>
                                        <div className="dx-field-value up-field-value">
                                            <TextBox
                                                width='250px'
                                                mode="password"
                                                onInitialized={this.textBoxPassword_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <StringLengthRule min={8} message="Min 8 characters" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>
                                    <div className="dx-field">
                                        <div className="dx-field-label up-field-label">Confirm*</div>
                                        <div className="dx-field-value up-field-value">
                                            <TextBox
                                                width='250px'
                                                mode="password"
                                                onInitialized={this.textBoxConfirmPassword_OnInitialized}
                                                onFocusIn={this.textBox_OnFocusIn}
                                                onFocusOut={this.textBox_OnFocusOut}
                                            >
                                                <Validator>
                                                    <ValidationRule type="required" message="Required" />
                                                    <StringLengthRule min={8} message="Min 8 characters" />
                                                </Validator>
                                            </TextBox>
                                        </div>
                                    </div>

                                    <div className="up-button-update">
                                        <Button
                                            text="Save"
                                            width="80px"
                                            onInitialized={this.buttonUpdate_OnInitialized}
                                            icon={globalIcons.save}
                                            onClick={this.buttonUpdate_OnClick}
                                        />
                                    </div>

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </ValidationGroup>
            </React.Fragment>
        );
    }
}

export default UserProfile

