import React from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import List from 'devextreme-react/list';

import ServiceShuttles from '../../../api/services/ServiceShuttles';

import globalConfig from '../../../config/global.js';

import './ShuttlesUpcoming.scss';

class ShuttlesUpcoming extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceTrips: []
        }

        this.hubConnection = null;

        this.rowCounter = 0;
    }

    // EVENTS
    componentDidMount = async () => {
        this.hubInit();
        await this.refresh();
       // setInterval(await this.refresh, 5 * 60 * 1000)
    }

    refresh = async () => {
        await this.loadDataSourceTrips()
    }

    // HUB
    hubInit = async () => {
        this.hubConnection = new HubConnectionBuilder().withUrl(globalConfig.settings.apihost + "/shuttlesHub").build();
        await this.hubConnection.start()
            .then(function () {

            })
            .catch(function (err) {
                return console.error(err.toString());
            });
        this.hubConnection.on("ReceiveMessage", await this.hubReceiveMessage);
    }

    hubReceiveMessage = async () => {
        await this.refresh();
        setTimeout(this.repaintGrid, 200);
    }

    // DATA
    loadDataSourceTrips = async () => {
        var data = await ServiceShuttles.getUpcomingTrips();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceTrips: data });
        } else {
            this.onDBError('Database error!');  // callback
        }

        this.rowCounter = 0;
    }

    checkUrgency = (e) => {
        let bg = "#4CFF00";
        if (e.statusName.toLowerCase() === 'waiting') {
            const dtDep = new Date(e.dateTimeDeparture);
            const dtNow = new Date();
            if (dtDep.getFullYear() === dtNow.getFullYear() && dtDep.getMonth() === dtNow.getMonth() && dtDep.getDate() === dtNow.getDate()) {
                let diffMinutes = (dtDep.getHours() * 60 + dtDep.getMinutes()) - (dtNow.getHours() * 60 + dtNow.getMinutes())
                if (diffMinutes > 0 && diffMinutes <= 15) {
                    if (diffMinutes <= 5) {
                        bg = '#FF0000';
                    } else {
                        bg = '#FFA500';
                    }
                } else if (diffMinutes <= 0) {
                    bg = '#FF0000';
                }
            }
        }

        return bg;
    }

    renderItemTemplate = (e) => {
        const urgency = this.checkUrgency(e);
        let cssUrgency = { backgroundColor: 'transparent' };
        if (urgency !== 'NOCOLOR') {
            cssUrgency = { backgroundColor: urgency };
        }

        let dt = new Date(e.dateTimeDeparture);
        const t = dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0')

        const searchRegExp = /\[rgba\]/gi;
        var patternColor = (e.patternColorHex !== null ? e.patternColorHex : '#00000033');
        var css = { display: 'flex', justifyContent: 'center', alignItems: 'center', color: e.foreColorHex };
        css.backgroundColor = e.colorHex
        if (e.cssBackGround !== null) {
            css.background = e.cssBackGround.replace(searchRegExp, patternColor);
        }
        if (e.cssBackGroundImage !== null) {
            css.backgroundImage = e.cssBackGroundImage.replace(searchRegExp, patternColor);
        }
        if (e.cssBackGroundSize !== null) {
            css.backgroundSize = e.cssBackGroundSize;
        }
        if (e.cssBackGroundPosition !== null) {
            css.backgroundPosition = e.cssBackGroundPosition;
        }

        // workaround because background color is sometimes not set for patterns with background set
        var cssMain = { backgroundColor: e.colorHex };
        cssMain.height = '100%';
        cssMain.width = '100%';

        this.rowCounter+= 1;
        let rowColor = (this.rowCounter % 2 === 0 ? '#1E4BFE' : '#0126B2');
        return <div className="su-list-item" style={{ backgroundColor: rowColor }}>
            <div className="su-list-item-status" style={cssUrgency}></div>
            <div className="su-list-item-time">{t}</div>
            <div className="su-list-item-trip">{e.locationDepartureName + " - " + e.locationArrivalName}</div>
            <div className="su-list-item-driver">
                <div style={cssMain}>
                    <div style={css}>
                        <div>{e.driverName}</div>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <div className="su-list-container">
                    <List
                        dataSource={this.state.dataSourceTrips}
                        height="100%"
                        activeStateEnabled={false}
                        itemRender={this.renderItemTemplate}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default ShuttlesUpcoming
