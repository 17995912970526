import React from 'react';
import { Home } from '../../components';
import './home.scss';

class home extends React.Component {
    render() {
        return (
            <Home />
        );
    }
}

export default home
