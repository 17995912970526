import axiosapi from '../axiosapi.js';

class ServiceLocations {

    static getLocationsInfo = async (forShuttles) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/GetLocationsInfo', {
            params: {
                forShuttles: forShuttles,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getLocationsDropDown = async (forShuttles) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/GetLocationsDropDown', {
            params: {
                forShuttles: forShuttles,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getLocation = async (id) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/GetLocation', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createLocation = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.post('/api/Locations/CreateLocation', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }

    static updateLocation = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.put('/api/Locations/UpdateLocation', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteLocation = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.delete('/api/Locations/DeleteLocation', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static canLocationBeDeleted = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/CanLocationBeDeleted', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesLocationNameExist = async (locationName, locationIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/DoesLocationNameExist', {
            params: {
                locationName: locationName,
                locationIdToExclude: locationIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesShortNameExist = async (shortName, locationIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/DoesShortNameExist', {
            params: {
                shortName: shortName,
                locationIdToExclude: locationIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }



    static getLocationTypesDropDown = async (forShuttles) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Locations/GetLocationTypesDropDown', {
            params: {
                forShuttles: forShuttles,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }
}

export default ServiceLocations;
