import React from 'react';
import { UserSettings } from '../../../components';

class userSettings extends React.Component {
    render() {
        return (
            <UserSettings />
        );
    }
}

export default userSettings