class Converters {

    // converts js date to sql date format (can have sql date as input or null))
    static ConvertDateToSqlDate = (date) => {
        let returnValue = date;

        if (date !== null && Object.prototype.toString.call(date) === "[object Date]") {
            returnValue = new Date(date - date.getTimezoneOffset() * (60 * 1000)).toISOString().slice(0, 19)
        }

        return returnValue;
    }

    static SqlDateToJsDate = (date) => {
        return new Date(date);
    }

    static JsDateToSqlDate = (date) => {

        return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
            + ' ' + date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0'); // + ':' + date.getMilliseconds().toString().padStart(3, '0')
    }
}

export default Converters;
