import axiosapi from '../axiosapi.js';

class ServiceUsers {

    static getUsersInfo = async () => {
        let returnValue = [];

        await axiosapi.get('/api/Users/GetUsersInfo')
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUser = async (id) => {
        let returnValue = null;

        await axiosapi.get('/api/Users/GetUser', {
            params: {
                id: id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUserForUpdate = async (id) => {
        let returnValue = null;

        await axiosapi.get('/api/Users/GetUserForUpdate', {
            params: {
                id: id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUserByEmailPassword = async (email, password) => {
        let returnValue = null;

        await axiosapi.get('/api/Users/GetUserByEmailPassword', {
            params: {
                email: email,
                password: password
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getUserAuthorizationByEmail = async (email) => {
        let returnValue = {};

        await axiosapi.get('/api/Users/GetUserAuthorizationByEmail', {
            params: {
                email: email
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createUser = async (model) => {
        let returnValue = -1;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.post('/api/Users/CreateUser', JSON.stringify(model), {
            params: {
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json'
            }

        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static updateUser = async (model) => {
        let returnValue = false;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.put('/api/Users/UpdateUser', JSON.stringify(model), {
            params: {
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteUser = async (id) => {
        let returnValue = false;

        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.delete('/api/Users/DeleteUser', {
            params: {
                id: id,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesEmailExist = async (email, userIdToExclude) => {
        let returnValue = false;

        await axiosapi.get('/api/Users/DoesEmailExist', {
            params: {
                email: email,
                userIdToExclude: userIdToExclude,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }


 
}

export default ServiceUsers;
