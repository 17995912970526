import axiosapi from '../axiosapi.js';

class ServiceFleet {

    static getFleetInfo = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/GetFleetInfo', {
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getFleetDropDown = async () => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/GetFleetDropDown', {
            params: {
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static getVehicle = async (id) => {
        let returnValue = {};

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/GetVehicle', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static createVehicle = async (model) => {
        let returnValue = -1;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.post('/api/Fleet/CreateVehicle', JSON.stringify(model), {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });
        return returnValue;
    }

    static updateVehicle = async (model) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.put('/api/Fleet/UpdateVehicle', JSON.stringify(model) , {
            params: {
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            },
           headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static deleteVehicle = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));

        await axiosapi.delete('/api/Fleet/DeleteVehicle', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId,
                userAuthId: userAuth.id
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static canVehicleBeDeleted = async (id) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/CanVehicleBeDeleted', {
            params: {
                id: id,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesTagExist = async (tag, vehicleIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/DoesTagExist', {
            params: {
                tag: tag,
                vehicleIdToExclude: vehicleIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

    static doesLicencePlateExist = async (licencePlate, vehicleIdToExclude) => {
        let returnValue = false;

        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));

        await axiosapi.get('/api/Fleet/DoesLicencePlateExist', {
            params: {
                licencePlate: licencePlate,
                vehicleIdToExclude: vehicleIdToExclude,
                eventId: userSettingsGeneral.currentEventId
            }
        })
            .then(function (response) {
                returnValue = response.data;
            })
            .catch(function (error) {
                returnValue = { "error": true };
            });

        return returnValue;
    }

 }

export default ServiceFleet;
