import React from 'react';
import Validator, { AsyncRule, ValidationRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import SelectBox from 'devextreme-react/select-box';
import { CheckBox } from 'devextreme-react/check-box';
import RadioGroup from 'devextreme-react/radio-group';

import HxToast from '../../../custom-components/hx-toast/HxToast';

import globalIcons from '../../../../config/globalIcons.js';

import ServiceEvents from '../../../../api/services/ServiceEvents';
import ServiceUserSettings from '../../../../api/services/ServiceUserSettings';

import './UserSettingsGeneral.scss'

const focusInOptions = ['End of text', 'Select all text'];
const reverseNameOrderOptions = ['First-Last', 'Last-First'];

class UserSettingsGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSourceEvents: [],
            disabled: true,
            buttonLockedIcon: globalIcons.locked,
            header: 'General'
        };

        this.userSettingsRecord = {};

        this.buttonLockedInstance = null;
        this.selectBoxEventsInstance = null;
        this.radiogroupFocusInstance = null;
        this.checkBoxPopupWarningInstance = null;
        this.checkBoxDoubleClickInstance = null;
        this.radiogroupReverseNameOrderInstance = null;

        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;
    }

    //CALLBACKS
    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    // CALLABLES


    // EVENTS
    componentDidMount = async () => {
        const userAuth = JSON.parse(sessionStorage.getItem('userAuth'));
        await this.loadDataSourceEvents();
        await this.loadDataSourceUserSettingsGeneral(userAuth.id);

        this.selectBoxEventsInstance.option('value', this.userSettingsRecord.currentEventId);
        this.radiogroupFocusInstance.option('value', this.userSettingsRecord.inputFocusInSelectAll === true ? focusInOptions[1] : focusInOptions[0]);
        this.checkBoxPopupWarningInstance.option('value', this.userSettingsRecord.popupWarnIfDataHasChanged);
        this.checkBoxDoubleClickInstance.option('value', this.userSettingsRecord.allowDoubleClickOnGrid);
        this.radiogroupReverseNameOrderInstance.option('value', this.userSettingsRecord.reverseNameOrder === true ? reverseNameOrderOptions[1] : reverseNameOrderOptions[0]);

        this.setState({ header: 'General' })

        this.dataHasChanged = false;
    }

    // DATA
    loadDataSourceEvents = async () => {
        var data = await ServiceEvents.getEventsInfo();
        if (!data.hasOwnProperty('error')) {
            this.setState({ dataSourceEvents: data });
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceUserSettingsGeneral = async (userId) => {
        var data = await ServiceUserSettings.getUsersSettingsGeneralInfoByUserId(userId);
        if (!data.hasOwnProperty('error')) {
            this.userSettingsRecord = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }
    
    // BUTTON LOCKED
    buttonLocked_OnInitialized = (e) => {
        this.buttonLockedInstance = e.component;
    }

    buttonLocked_OnClick = async (e) => {
        this.setState(prevState => ({
            disabled: !prevState.disabled,
            buttonLockedIcon: prevState.disabled === true ? globalIcons.unlocked : globalIcons.locked
        }));
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;

        this.setState({ header: this.dataHasChanged === true ? 'General*': 'General' })
    }

    // SELECTBOX EVENTS
    selectBoxEvents_OnInitialized = (e) => {
        this.selectBoxEventsInstance = e.component;
    }

    // RADIOGROUP FOCUS
    radiogroupFocus_OnInitialized = (e) => {
        this.radiogroupFocusInstance = e.component;
    }

    // CHECKBOX POPUPWARNING
    checkBoxPopupWarning_OnInitialized = (e) => {
        this.checkBoxPopupWarningInstance = e.component;
    }

    // CHECKBOX DOUBLE CLICK
    checkBoxDoubleClick_OnInitialized = (e) => {
        this.checkBoxDoubleClickInstance = e.component;
    }

    // CHECKBOX DOUBLE CLICK
    radiogroupReverseNameOrder_OnInitialized = (e) => {
        this.radiogroupReverseNameOrderInstance = e.component;
    }

    // BUTTON UPDATE
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateUserSettingsGeneral();
    }

    updateUserSettingsGeneral = async () => {
        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true) {
            this.userSettingsRecord.currentEventId = this.selectBoxEventsInstance.option('value');
            this.userSettingsRecord.inputFocusInSelectAll = this.radiogroupFocusInstance.option('value') === focusInOptions[1];
            this.userSettingsRecord.popupWarnIfDataHasChanged = this.checkBoxPopupWarningInstance.option('value');
            this.userSettingsRecord.allowDoubleClickOnGrid = this.checkBoxDoubleClickInstance.option('value');
            this.userSettingsRecord.reverseNameOrder = this.radiogroupReverseNameOrderInstance.option('value') === reverseNameOrderOptions[1];

            var result = await ServiceUserSettings.updateUserSettingsGeneral(this.userSettingsRecord);
            if (!result.hasOwnProperty("error")) {
                if (result === true) {
                    HxToast.showToast('General settings updated', 'success', 'top right', 'up-stack');
                    var resultUSG = await ServiceUserSettings.getUsersSettingsGeneralInfoByUserId(this.userSettingsRecord.userId);
                    localStorage.setItem('userSettingsGeneral', JSON.stringify(resultUSG));
                    this.setState({ header: 'General' })
                    this.dataHasChanged = false;
                    this.setState(prevState => ({
                        disabled: !prevState.disabled,
                        buttonLockedIcon: prevState.disabled === true ? globalIcons.unlocked : globalIcons.locked
                    }));
                } else {
                    HxToast.showToast('Update general settings failed! ', 'error', 'top right', 'up-stack');
                }
            } else {
                this.onDBError("Database error!");  // callback
            }
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    // RENDERING
    render() {
        return (
            <React.Fragment>
                <ValidationGroup
                    onInitialized={this.validationGroup_OnInitialized}
                >
                    <div className='content-block'>
                        <div className="usg-main-div">
                            <div className="dx-card usg-card-general">
                                <div className="usg-card-general-header">{this.state.header} &nbsp;&nbsp;&nbsp;
                                    <Button
                                        icon={this.state.buttonLockedIcon}
                                        disabled={!this.state.disabled}
                                        onInitialized={this.buttonLocked_OnInitialized}
                                        onClick={this.buttonLocked_OnClick}
                                    />
                                </div>
                                <div className="dx-field usg-field-general">
                                    <div className="dx-field-label usg-field-general-label">Current event</div>
                                    <div className="dx-field-value usg-field-general-value" disabled={this.state.disabled}>
                                        <SelectBox
                                            dataSource={this.state.dataSourceEvents}
                                            valueExpr="id"
                                            displayExpr="eventName"
                                            searchEnabled={true}
                                            showClearButton={true}
                                            showDataBeforeSearch={false}
                                            minSearchLength={0}
                                            width='250px'
                                            onInitialized={this.selectBoxEvents_OnInitialized}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                            </Validator>
                                        </SelectBox>
                                    </div>
                                </div>
                                <div disabled={this.state.disabled}>
                                    <div className="dx-field usg-field-general usg-field-general-radio">
                                        <div className="dx-field-label usg-field-general-label">Text focus</div>
                                        <div className="dx-field-value usg-field-general-value">
                                            <RadioGroup
                                                items={focusInOptions}
                                                defaultValue={focusInOptions[0]}
                                                onInitialized={this.radiogroupFocus_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                                layout="horizontal"
                                            />
                                        </div>
                                    </div>
                                    <div className="dx-field usg-field-general usg-field-general-radio">
                                        <div className="dx-field-label usg-field-general-label">Name order</div>
                                        <div className="dx-field-value usg-field-general-value">
                                            <RadioGroup
                                                items={reverseNameOrderOptions}
                                                defaultValue={reverseNameOrderOptions[0]}
                                                onInitialized={this.radiogroupReverseNameOrder_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                                layout="horizontal"
                                            />
                                        </div>
                                    </div>       <div className="dx-field usg-field-general usg-field-general-checkbox">
                                        <div className="dx-field-value usg-field-general-value">
                                            <CheckBox
                                                text="Warn if popup is closed and data has changed"
                                                onInitialized={this.checkBoxPopupWarning_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="dx-field usg-field-general usg-field-general-checkbox">
                                        <div className="dx-field-value usg-field-general-value">
                                            <CheckBox
                                                text="Allow double click on grids"
                                                onInitialized={this.checkBoxDoubleClick_OnInitialized}
                                                onValueChanged={this.input_ValueChanged}
                                            />
                                        </div>
                                    </div>
                                    <div className="usg-buttons">
                                        <div className="usg-button-update">
                                            <Button
                                                icon={globalIcons.save}
                                                text='Save'
                                                width='80px'
                                                onInitialized={this.buttonUpdate_OnInitialized}
                                                onClick={this.buttonUpdate_OnClick}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </ValidationGroup>

            </React.Fragment>
        );
    }
}

export default UserSettingsGeneral

