import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule, CustomRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';
import HtmlEditor from 'devextreme-react/html-editor';

import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'

import ServiceShuttlesArtists from '../../../../../api/services/ServiceShuttlesArtists'

import globalConfig from '../../../../../config/global.js';
import globalIcons from '../../../../../config/globalIcons.js';

import './PopupShuttlesArtistContacts.scss'

// props
//
// callables
//      preload
//
// callbacks
//      onInitialized
//      onInitialized
//      onUpdate
//      onDBError

class PopupShuttlesArtistContacts extends React.Component {
    constructor(props) {
        super(props);

        this.artistContactId = -1;

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxFirstNameInstance = null;
        this.textBoxLastNameInstance = null;
        this.textBoxEmailInstance = null;
        this.textBoxTelInstance = null;
        this.htmlEditorNotesInstance = null;

        this.artistContactRecord = {};
 
        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;

        this.popupSaveChangesInstance = null;
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success, id) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success, id);  // callback
        }
    }

    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.artistContactId = id;

        await this.fillPopup(id);
    }

    // EVENTS


    // DATA
    loadDataSourceArtistContact = async (artistContactId) => {
        var data = await ServiceShuttlesArtists.getArtistContact(artistContactId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.artistContactRecord = data;
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        // set image here, record is loaded on preload
        var image = document.getElementById('artistContactImage');
        if (this.artistContactRecord.avatarUrl != null) {
            image.src = globalConfig.settings.images.artistContactImagesPath + this.artistContactRecord.avatarUrl;
        } else {
            image.src = globalConfig.settings.images.artistContactImagesPath + "default_250.jpg";
        }

        if (this.artistContactId === -1) {
            // new driver
            this.checkBoxKeepOpenInstance.option('visible', true);
            this.setPopupTitle();
        } else {
            // update driver
            this.checkBoxKeepOpenInstance.option('visible', false);
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxFirstNameInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    fillPopup = async (artistContactId) => {
        await this.loadDataSourceArtistContact(artistContactId);

        this.textBoxFirstNameInstance.option('value', this.artistContactRecord.firstName);
        this.textBoxLastNameInstance.option('value', this.artistContactRecord.lastName);
        this.textBoxEmailInstance.option('value', this.artistContactRecord.email);
        this.textBoxTelInstance.option('value', this.artistContactRecord.tel);
        this.htmlEditorNotesInstance.option('value', this.artistContactRecord.notes);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.artistContactId = -1;

        await this.fillPopup(this.artistContactId);

        this.setPopupTitle();

        this.checkBoxKeepOpenInstance.option('visible', true)

        await this.validationGroupInstance.validate();

        this.textBoxFirstNameInstance.focus();
    }

    setPopupTitle = () => {
        if (this.artistContactId === -1) {
            this.popupInstance.option('title', 'Create artistcontact' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.artistContactRecord.firstName + ' ' + this.artistContactRecord.lastName + (this.dataHasChanged === true ? '*' : ''));
        }
    }

    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateArtistContact();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX FIRST NAME
    textBoxFirstName_OnInitialized = (e) => {
        this.textBoxFirstNameInstance = e.component;
    }

    // TEXTBOX LAST NAME
    textBoxLastName_OnInitialized = (e) => {
        this.textBoxLastNameInstance = e.component;
    }

    // TEXTBOX EMAIL
    textBoxEmail_OnInitialized = (e) => {
        this.textBoxEmailInstance = e.component;
    }

    // TEXTBOX TEL
    textBoxTel_OnInitialized = (e) => {
        this.textBoxTelInstance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }


  
    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateArtistContact();
    }

    updateArtistContact = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resFirstName = await this.validateArtistContactFirstName();
        var resLastName = await this.validateArtistContactLastName();
        var resEmail = await this.validateArtistContactEmail();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid
            && resFirstName === true
            && resLastName === true
            && resEmail === true) {
            this.artistContactRecord.firstName = this.textBoxFirstNameInstance.option('value');
            this.artistContactRecord.lastName = this.textBoxLastNameInstance.option('value');
            this.artistContactRecord.email = this.textBoxEmailInstance.option('value');
            this.artistContactRecord.tel = this.textBoxTelInstance.option('value');
            this.artistContactRecord.notes = this.htmlEditorNotesInstance.option('value');

            if (this.artistContactId === -1) {
                // new artistcontact
                var resultArtistContact = await ServiceShuttlesArtists.createArtistContact(this.artistContactRecord);
                if (!resultArtistContact.hasOwnProperty("error")) {
                    this.onCreate(resultArtistContact); // callback
                    if (resultArtistContact !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            await this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update artistcontact
                var resultArtistContactUpdate = await ServiceShuttlesArtists.updateArtistContact(this.artistContactRecord);
                if (!resultArtistContactUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultArtistContactUpdate.success, resultArtistContactUpdate.id); // callback
                    if (resultArtistContactUpdate.success === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxFirstNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateArtistContactFirstName = async () => {
        if (this.textBoxFirstNameInstance.option('value') == null || this.textBoxLastNameInstance.option('value') == null) {
            return true;
        }

        var result = await ServiceShuttlesArtists.doesArtistContactFirstNameExist(this.textBoxFirstNameInstance.option('value'), this.textBoxLastNameInstance.option('value'), this.artistContactRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxFirstNameInstance.option('isValid', !result);
            this.textBoxLastNameInstance.option('isValid', !result);
          return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateArtistContactLastName = async () => {
        if (this.textBoxFirstNameInstance.option('value') == null || this.textBoxLastNameInstance.option('value') == null) {
            return true;
        }

        var result = await ServiceShuttlesArtists.doesArtistContactLastNameExist(this.textBoxFirstNameInstance.option('value'), this.textBoxLastNameInstance.option('value'), this.artistContactRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxFirstNameInstance.option('isValid', !result);
            this.textBoxLastNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    validateArtistContactEmail = async () => {
        var result = await ServiceShuttlesArtists.doesArtistContactEmailExist(this.textBoxEmailInstance.option('value'), this.artistContactRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxEmailInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1041}
                    height={312}
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div>
                                <div className="pac-top-div">
                                    <div className="pac-top-left-div">
                                        <div className="pac-avatar">
                                            <img id="artistContactImage" src="" alt="" />
                                        </div>
                                    </div>
                                    <div className="pac-top-middle-div">
                                        <div className="pac-dx-field">
                                            <div className="dx-field-label pac-top-label">First Name*</div>
                                            <div className="dx-field-value pac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onInitialized={this.textBoxFirstName_OnInitialized}
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateArtistContactFirstName} message="Full name exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="pac-dx-field">
                                            <div className="dx-field-label pac-top-label">Last Name*</div>
                                            <div className="dx-field-value pac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxLastName_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateArtistContactLastName} message="Full name exists" />
                                                        <ValidationRule type="required" message="Required" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="pac-dx-field">
                                            <div className="dx-field-label pac-top-label">Email</div>
                                            <div className="dx-field-value pac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxEmail_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                    <Validator>
                                                        <AsyncRule validationCallback={this.validateArtistContactEmail} message="Email exists" />
                                                        <EmailRule
                                                            message="Invalid email" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="pac-dx-field pac-dx-field-tel">
                                            <div className="dx-field-label pac-top-label">Tel</div>
                                            <div className="dx-field-value pac-top-input">
                                                <TextBox
                                                    width='250px'
                                                    onFocusIn={this.textBox_OnFocusIn}
                                                    onFocusOut={this.textBox_OnFocusOut}
                                                    onInitialized={this.textBoxTel_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </TextBox>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pac-top-right-div">
                                        <div className="pac-dx-field-notes">
                                            <div className="dx-field-value pac-top-input-notes">
                                                <HtmlEditor
                                                    width='100%'
                                                    height='200px'
                                                    placeholder='Notes'
                                                    onInitialized={this.htmlEditorNotes_OnInitialized}
                                                    onValueChanged={this.input_ValueChanged}
                                                >
                                                </HtmlEditor>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ValidationGroup>

                    <div className="pac-buttons">
                        <div className="pac-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='pac-buttons-update'>
                            <div className="pac-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon={globalIcons.save}
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
                <HxPopupSaveChanges
                    onInitialized={this.popupSaveChanges_onInitialized}
                    onClick={this.popupSaveChanges_onClick}
                />

            </React.Fragment>
        );
    }

}

export default PopupShuttlesArtistContacts

