import React from 'react';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import Validator, { AsyncRule, ValidationRule, EmailRule } from 'devextreme-react/validator';
import { Button } from 'devextreme-react/button';
import { ValidationGroup } from 'devextreme-react/validation-group';
import { CheckBox } from 'devextreme-react/check-box';
import DataGrid, {Editing, ColumnChooser, GroupPanel, FilterPanel, FilterRow, HeaderFilter, Paging, Pager, Scrolling, Column, SearchPanel, KeyboardNavigation, StateStoring, Sorting, LoadPanel} from 'devextreme-react/data-grid';
import HtmlEditor from 'devextreme-react/html-editor';
import { Icon } from '@iconify/react';

import HxToast from '../../../../custom-components/hx-toast/HxToast';

import HxPopupSaveChanges from '../../../../custom-components/hx-popup-save-changes/HxPopupSaveChanges'
import PopupShuttlesArtistContacts from './PopupShuttlesArtistContacts';
import PopupShuttlesArtistAvailableContacts from './PopupShuttlesArtistAvailableContacts';

import ServiceShuttlesArtists from '../../../../../api/services/ServiceShuttlesArtists'

import globalIcons from '../../../../../config/globalIcons.js';

import './PopupShuttlesArtists.scss'

// props
//
// callables
//      preload
//
// callbacks
//      onInitialized
//      onCreate
//      onUpdate
//      onDBError

const COLFIRST = <Column
    key='firstName'
    dataField='firstName'
    dataType='string'
    caption='First name'
    width='250px'
    allowEditing={false}
    allowExporting={true}
    allowFiltering={true}
    allowHeaderFiltering={true}
    showInColumnChooser={true}
/>;
const COLLAST = <Column
    key='lastName'
    dataField='lastName'
    dataType='string'
    caption='Last name'
    width='250px'
    allowEditing={false}
    allowExporting={true}
    allowFiltering={true}
    allowHeaderFiltering={true}
    showInColumnChooser={true}
/>;

class PopupShuttlesArtists extends React.Component {
    constructor(props) {
        super(props);

        this.artistId = -1;

        this.state = {
            artistContactsDataSource: [],
        };

        this.popupInstance = null;
        this.validationGroupInstance = null;

        this.textBoxArtistNameInstance = null;

        this.gridInstance = null;

        this.artistRecord = {};

        this.checkBoxKeepOpenInstance = null;
        this.buttonUpdateInstance = null;

        this.dataHasChanged = false;
        this.refreshOnClose = false;

        this.popupSaveChangesInstance = null;
        this.popupShuttlesArtistContactsInstance = null;
        this.popupShuttlesAvailableArtistContactsInstance = null;

        this.colsFirstLast = [COLFIRST, COLLAST];
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    onDBError = (message) => {
        if (this.props.onDBError) {
            this.props.onDBError(message);  // callback
        }
    }

    onCreate = (success) => {
        if (this.props.onCreate) {
            this.props.onCreate(success);  // callback
        }
    }

    onUpdate = (success) => {
        if (this.props.onUpdate) {
            this.props.onUpdate(success);  // callback
        }
    }

    onHidden = (refresh) => {
        if (this.props.onHidden) {
            this.props.onHidden(refresh);  // callback
        }
    }

    // CALLABLES
    preload = async (id) => {
        this.userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        this.colsFirstLast = this.userSettingsGeneral.reverseNameOrder === true ? [COLLAST, COLFIRST] : [COLFIRST, COLLAST];
        this.artistId = id;
        this.refreshOnClose = false;

        await this.fillPopup(id);
    }

    // EVENTS


    // DATA
    loadDataSourceArtist = async (artistId) => {
        var data = await ServiceShuttlesArtists.getArtist(artistId);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.artistRecord = data;
            this.setState({ artistContactsDataSource: this.artistRecord.artistContacts });
            this.gridInstance.refresh();
        } else {
            this.onDBError('Database error!');  // callback
        }
    }

    loadDataSourceArtistContact = async (artistContactId) => {
        let result = null;
        var data = await ServiceShuttlesArtists.getArtistContact(artistContactId);
        if (data !== null && !data.hasOwnProperty("error")) {
            result =  data;
        } else {
            this.onDBError('Database error!');  // callback
        }
        return result;
    }

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShowing = async (e) => {
        if (this.artistId === -1) {
            // new artist
            this.checkBoxKeepOpenInstance.option('visible', true);
            this.setPopupTitle();
        } else {
            // update artist
            this.checkBoxKeepOpenInstance.option('visible', false);
            this.setPopupTitle();
        }

        this.checkBoxKeepOpenInstance.option('value', false);
        this.buttonUpdateInstance.option('text', 'Save');
        this.buttonUpdateInstance.option('width', '80px');
    }

    popup_OnShown = async (e) => {
        this.textBoxArtistNameInstance.focus();
    }

    popup_OnHiding = (e) => {
        if (this.dataHasChanged && this.userSettingsGeneral.popupWarnIfDataHasChanged === true) {
            e.cancel = true;
            this.popupSaveChangesInstance.show();
        }
    }

    popup_OnHidden = (e) => {
       this.onHidden(this.refreshOnClose);
    }

    fillPopup = async (artistId) => {
        await this.loadDataSourceArtist(artistId);

        this.textBoxArtistNameInstance.option('value', this.artistRecord.artistName);
        this.htmlEditorNotesInstance.option('value', this.artistRecord.notes);

        this.dataHasChanged = false;

        await this.validationGroupInstance.validate();
    }

    resetPopup = async () => {
        this.artistId = -1;

        await this.fillPopup(this.artistId);

        this.checkBoxKeepOpenInstance.option('visible', true)
        this.setPopupTitle();

        await this.validationGroupInstance.validate();

        this.textBoxArtistNameInstance.focus();
    }

    setPopupTitle = () => {
        if (this.artistId === -1) {
            this.popupInstance.option('title', 'Create artist' + (this.dataHasChanged === true ? '*' : ''));
        } else {
            this.popupInstance.option('title', 'Update ' + this.artistRecord.artistName + (this.dataHasChanged === true ? '*' : ''));
        }
    }



    // POPUP ARTISTCONTACTS
    popupShuttlesArtistContacts_onInitialized = (instance) => {
        this.popupShuttlesArtistContactsInstance = instance;
    }

    popupShuttlesArtistContacts_onDBError = (message) => {
        this.onDBError(message);
    }

    popupShuttlesArtistContacts_OnCreate = async (id) => {
        if (id !== -1) {
            let rec = await this.loadDataSourceArtistContact(id);
            if (rec !== null) {
                if (this.artistRecord.artistContacts === null) {
                    this.artistRecord.artistContacts = [rec];
                } else {
                    let index = this.artistRecord.artistContacts.findIndex(x => x.id === rec.id);
                    if (index > -1) {
                        this.artistRecord.artistContacts.splice(index, 1);
                    };
                    this.artistRecord.artistContacts.push(rec);
                }
                this.setState({ artistContactsDataSource: this.artistRecord.artistContacts });
                this.gridInstance.refresh();
                this.dataHasChanged = true;
                this.setPopupTitle();      }
            HxToast.showToast('Contact created', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Create contact failed!', 'error', 'top right', 'up-stack');
        }
    }

    popupShuttlesArtistContacts_OnUpdate = async (success, id) => {
        if (success === true) {
            let rec = await this.loadDataSourceArtistContact(id);
            if (rec !== null) {
                if (this.artistRecord.artistContacts === null) {
                    this.artistRecord.artistContacts = [rec];
                } else {
                    let index = this.artistRecord.artistContacts.findIndex(x => x.id === rec.id);
                    if (index > -1) {
                        this.artistRecord.artistContacts.splice(index, 1);
                    };
                    this.artistRecord.artistContacts.push(rec);
                }
                this.setState({ artistContactsDataSource: this.artistRecord.artistContacts });
                this.gridInstance.refresh();
                this.dataHasChanged = true;
                this.setPopupTitle();    }
            HxToast.showToast('Contact updated', 'success', 'top right', 'up-stack');
        } else {
            HxToast.showToast('Update contact failed!', 'error', 'top right', 'up-stack');
        }
    }


    // POPUP AVAILABLE ARTISTCONTACTS
    popupShuttlesAvailableArtistContacts_onInitialized = (instance) => {
        this.popupShuttlesAvailableArtistContactsInstance = instance;
    }

    popupShuttlesAvailableArtistContacts_onDBError = (message) => {
        this.onDBError(message)
    }

    popupShuttlesAvailableArtistContacts_OnUpdate = async (items) => {
        if (items.length > 0) {
            for (let i = 0; i < items.length; i++) {
                 if (this.artistRecord.artistContacts === null) {
                    this.artistRecord.artistContacts = [items[i]];
                } else {
                     let index = this.artistRecord.artistContacts.findIndex(x => x.id === items[i].id);
                     if (index > -1) {
                         this.artistRecord.artistContacts.splice(index, 1);
                     }
                     this.artistRecord.artistContacts.push(items[i]);
                }
            }
            this.setState({ artistContactsDataSource: this.artistRecord.artistContacts });
            this.gridInstance.refresh();
            this.dataHasChanged = true;
            this.setPopupTitle();
        }
    }



    // POPUP SAVE CHANGES
    popupSaveChanges_onInitialized = (instance) => {
        this.popupSaveChangesInstance = instance;
    }

    popupSaveChanges_onClick = async (value) => {
        this.popupSaveChangesInstance.hide();

        switch (value.toUpperCase()) {
            case "YES":
                await this.updateArtist();
                break;
            case "NO":
                this.dataHasChanged = false;
                this.popupInstance.hide();
                break;
            case "CANCEL":
                break;
            default:
        }
    }

    // ALL INPUTS VALUE CHANGED
    input_ValueChanged = (e) => {
        this.dataHasChanged = true;
        this.setPopupTitle();
    }

    // ALL TEXTBOXES FOCUS IN AND OUT
    textBox_OnFocusIn = (e) => {
        if (this.userSettingsGeneral.inputFocusInSelectAll === true) {
            // select all text
            e.element.querySelector('.dx-texteditor-input').select();
        }
    }

    textBox_OnFocusOut = (e) => {
        const val = e.component.option('value');
        e.component.option('value', val !== null ? val.toString().trim() : val);
    }

    // TEXTBOX ARTIST NAME
    textBoxArtistName_OnInitialized = (e) => {
        this.textBoxArtistNameInstance = e.component;
    }

    // HTML EDITOR
    htmlEditorNotes_OnInitialized = (e) => {
        this.htmlEditorNotesInstance = e.component;
    }

    // BUTTON ADD AVAILABLE CONTACT
    buttonAddAvailableArtistContact_OnClick = async (e) => {
        let ids = '';
        if (this.artistRecord.artistContacts !== null) {
            ids = this.artistRecord.artistContacts.map((obj) => obj.id).toString();
        }
        await this.refPopupShuttlesAvailableArtistContacts.preload(ids);
        this.popupShuttlesAvailableArtistContactsInstance.show();
    }

    // DATAGRID
    gridOnInitialized = (e) => {
        this.gridInstance = e.component;
        this.gridInstance.option('loadPanel.enabled', false); 
    }

    gridOnCellClick = async (e) => {
        // if white space is clicked then e.column is undefined
        if (e.rowIndex === -1) {
            return;
        }

        if (e.rowType === 'header') {
            if (e.column.name.toUpperCase() === 'ACTIONCOLUMNCREATEUPDATE') {
                await this.refPopupShuttlesArtistContacts.preload(-1);
                this.popupShuttlesArtistContactsInstance.show();
            }
        } else if (e.rowType === 'data') {
            switch (e.column.name.toUpperCase()) {
                case 'ACTIONCOLUMNCREATEUPDATE':
                    await this.refPopupShuttlesArtistContacts.preload(e.data.id);
                    this.popupShuttlesArtistContactsInstance.show();
                    break;
                case 'ACTIONCOLUMNDELETE':
                    await this.deleteShuttlesArtistContact(e.data.id);
                    break;
                default:
                    break;
            }
        }
    }

    gridOnRowDblClick = async (e) => {
        const userSettingsGeneral = JSON.parse(localStorage.getItem('userSettingsGeneral'));
        if (userSettingsGeneral.allowDoubleClickOnGrid === true) {
            await this.refPopupShuttlesArtistContacts.preload(e.data.id);
            this.popupShuttlesArtistContactsInstance.show();
        }
    }

    deleteShuttlesArtistContact = async (id) => {
        let index = this.artistRecord.artistContacts.findIndex(x => x.id === id);
        this.artistRecord.artistContacts.splice(index, 1);
        this.setState({ artistContactsDataSource: this.artistRecord.artistContacts });
        this.gridInstance.refresh();
        this.dataHasChanged = true;
        this.setPopupTitle();
 }

    // CHECKBOX KEEP OPEN
    checkBoxKeepOpen_OnInitialized = (e) => {
        this.checkBoxKeepOpenInstance = e.component;
    }

    checkBoxKeepOpen_OnValueChanged = (e) => {
        this.buttonUpdateInstance.option('text', (e.value === true ? 'Save & new' : 'Save'));
        this.buttonUpdateInstance.option('width', (e.value === true ? '120px' : '80px'));
    }

    // BUTTON
    buttonUpdate_OnInitialized = (e) => {
        this.buttonUpdateInstance = e.component;
    }

    buttonUpdate_OnClick = async (e) => {
        await this.updateArtist();
    }

    updateArtist = async () => {
        // run async validation rules again because of bug in dxValidationGroup
        var resArtist = await this.validateArtistName();

        var res = await this.validationGroupInstance.validate();

        if (res.isValid === true && resArtist === true) {
            this.refreshOnClose = true;

            this.artistRecord.artistName = this.textBoxArtistNameInstance.option('value');
            this.artistRecord.notes = this.htmlEditorNotesInstance.option('value');

            if (this.artistId === -1) {
                // new artist
                var resultArtist = await ServiceShuttlesArtists.createArtist(this.artistRecord);
                if (!resultArtist.hasOwnProperty("error")) {
                    this.onCreate(resultArtist); // callback
                    if (resultArtist !== -1) {
                        if (this.checkBoxKeepOpenInstance.option('value') === false) {
                            this.dataHasChanged = false;
                            this.popupInstance.hide();
                        } else {
                            await this.resetPopup();
                            this.setPopupTitle();
                        }
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            } else {
                // update artist
                var resultArtistUpdate = await ServiceShuttlesArtists.updateArtist(this.artistRecord);
                if (!resultArtistUpdate.hasOwnProperty("error")) {
                    this.onUpdate(resultArtistUpdate); // callback
                    if (resultArtistUpdate === true) {
                        this.dataHasChanged = false;
                        this.popupInstance.hide();
                    }
                } else {
                    this.onDBError("Database error!");  // callback
                }
            }
        } else {
            this.textBoxArtistNameInstance.focus();
        }
    }

    // VALIDATION GROUP
    validationGroup_OnInitialized = (e) => {
        this.validationGroupInstance = e.component;
    }

    validateArtistName = async () => {
        var result = await ServiceShuttlesArtists.doesArtistNameExist(this.textBoxArtistNameInstance.option('value'), this.artistRecord.id);
        if (result !== null && !result.hasOwnProperty("error")) {
            this.textBoxArtistNameInstance.option('isValid', !result);
            return !result;
        } else {
            this.onDBError();  // callback
            return false;
        }
    }


    // RENDERING
    renderCreateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-14px', position: 'absolute', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:plus-thick" style={{ color: '#03a9f4', fontSize: '16px' }} /></div>
        </div>;
    }

    renderUpdateTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '-3px', cursor: 'pointer' }}>
            <div style={{ width: '30px' }}><Icon icon="mdi:pencil" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    renderDeleteTemplate = (e) => {
        return <div style={{ display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
            <div style={{ width: '30px', marginLeft: '-3px' }}><Icon icon="mdi:delete" style={{ color: '#03a9f4' }} /></div>
        </div>;
    }

    render() {
        return (
            <React.Fragment>
                <Popup
                    dragEnabled={false}
                    hideOnOutsideClick={false}
                    showTitle={true}
                    width={1050}
                    height='auto'
                    showCloseButton={true}
                    onInitialized={this.popup_OnInitialized}
                    onShowing={this.popup_OnShowing}
                    onShown={this.popup_OnShown}
                    onHiding={this.popup_OnHiding}
                    onHidden={this.popup_OnHidden}
                >
                    <ValidationGroup
                        onInitialized={this.validationGroup_OnInitialized}
                    >
                        <div>
                            <div className="psaea-top-div">
                                <div className="psaea-dx-field">
                                    <div className="dx-field-label psaea-label">Name*</div>
                                    <div className="dx-field-value psaea-input">
                                        <TextBox
                                            width='250px'
                                            onInitialized={this.textBoxArtistName_OnInitialized}
                                            onFocusIn={this.textBox_OnFocusIn}
                                            onFocusOut={this.textBox_OnFocusOut}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                            <Validator>
                                                <ValidationRule type="required" message="Required" />
                                                <AsyncRule validationCallback={this.validateArtistName} message="Artist exists" />
                                            </Validator>
                                        </TextBox>
                                    </div>
                                </div>
                            </div>

                            <div className="psaea-second-div">
                                <div className="psaea-dx-field psaea-dx-field-buttons-contacts psaea-buttons-contacts">
                                     <div className="psaea-button-add-available-contact">
                                        <Button
                                            icon={globalIcons.create}
                                            text="Add available contact"
                                            width={171}
                                            onClick={this.buttonAddAvailableArtistContact_OnClick}
                                        />
                                    </div>
                                </div>
                                <div className="psaea-dx-field">
                                    <div className="dx-field-label psaea-label psaea-label-alignment-auto">Contacts</div>
                                    <div className="dx-field-value psaea-input psaea-input-contacts">
                                        <DataGrid
                                            ref={ref => this.refGrid = ref}
                                            dataSource={this.state.artistContactsDataSource}
                                            key='id'
                                            height='100%'
                                            allowColumnReordering={false}
                                            allowColumnResizing={false}
                                            cacheEnabled={true}
                                            columnAutoWidth={false}
                                            columnResizingMode='widget'
                                            filterSyncEnabled={true}
                                            focusedRowEnabled={false}
                                            hoverStateEnabled={false}
                                            noDataText='No contacts found.'
                                            remoteOperations={true}
                                            repaintChangesOnly={true}
                                            showBorders={true}
                                            showColumnLines={true}
                                            showRowLines={true}
                                            wordWrapEnabled={false}
                                            onInitialized={this.gridOnInitialized}
                                            onCellClick={this.gridOnCellClick}
                                            onRowDblClick={this.gridOnRowDblClick}
                                        >
                                            <KeyboardNavigation
                                                enabled={false}
                                                editOnKeyPress={true}
                                                enterKeyAction='moveFocus'
                                                enterKeyDirection='column' />
                                            <LoadPanel enabled={false} />
                                            <StateStoring enabled={false} type="localStorage" storageKey="storageShuttlesAdminArtistsDataGrid" />
                                            <Sorting mode='multiple' />
                                            <ColumnChooser enabled={false} />
                                            <Paging enabled={false} defaultPageSize={20} />
                                            <FilterPanel visible={false} />
                                            <FilterRow visible={false} />
                                            <GroupPanel visible={false} />
                                            <HeaderFilter visible={false} />
                                            <SearchPanel visible={false} />
                                            <Scrolling
                                                mode="standard"
                                                columnRenderingMode="standard"
                                                rowRenderingMode="standard"
                                                showScrollBar='onHover'
                                                preloadEnabled={true}
                                            />
                                            <Column dataField="id" dataType="number" visible={false} />
                                            <Column
                                                name='ActionColumnCreateUpdate'
                                                caption=''
                                                allowEditing={false}
                                                allowExporting={false}
                                                allowFiltering={false}
                                                allowHeaderFiltering={false}
                                                width='30px'
                                                alignment='center'
                                                showInColumnChooser={false}
                                                cellRender={this.renderUpdateTemplate}
                                                headerCellRender={this.renderCreateTemplate} />
                                            {this.colsFirstLast}
                                            <Column dataField="email" dataType="string" caption='Email' width='250px' />
                                            <Column dataField="tel" dataType="string" caption='Tel' width='150px' />
                                            <Column
                                                name='ActionColumnDelete'
                                                caption=''
                                                allowEditing={false}
                                                allowExporting={false}
                                                allowFiltering={false}
                                                allowHeaderFiltering={false}
                                                width='30px'
                                                alignment='center'
                                                showInColumnChooser={false}
                                                cellRender={this.renderDeleteTemplate}
                                            />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>

                            <div className="psaea-third-div">
                                <div className="psaea-dx-field">
                                    <div className="dx-field-label psaea-label psaea-label-alignment-auto">Notes</div>
                                    <div className="dx-field-value psaea-input-notes">
                                        <HtmlEditor
                                            width='100%'
                                            height='200px'
                                            onInitialized={this.htmlEditorNotes_OnInitialized}
                                            onValueChanged={this.input_ValueChanged}
                                        >
                                        </HtmlEditor>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </ValidationGroup>

                    <div className="psaea-buttons">
                        <div className="psaea-buttons-checkbox">
                            <CheckBox
                                text="Keep open"
                                onInitialized={this.checkBoxKeepOpen_OnInitialized}
                                onValueChanged={this.checkBoxKeepOpen_OnValueChanged}
                            />
                        </div>
                        <div className='psaea-buttons-update'>
                            <div className="psaea-button-update">
                                <Button
                                    onInitialized={this.buttonUpdate_OnInitialized}
                                    icon={globalIcons.save}
                                    onClick={this.buttonUpdate_OnClick}
                                />
                            </div>
                        </div>
                    </div>
                </Popup>
                <HxPopupSaveChanges
                    onInitialized={this.popupSaveChanges_onInitialized}
                    onClick={this.popupSaveChanges_onClick}
                />
                <PopupShuttlesArtistContacts
                    ref={ref => this.refPopupShuttlesArtistContacts = ref}
                    onInitialized={this.popupShuttlesArtistContacts_onInitialized}
                    onDBError={this.popupShuttlesArtistContacts_onDBError}
                    onCreate={this.popupShuttlesArtistContacts_OnCreate}
                    onUpdate={this.popupShuttlesArtistContacts_OnUpdate}
                />
                <PopupShuttlesArtistAvailableContacts
                    ref={ref => this.refPopupShuttlesAvailableArtistContacts = ref}
                    onInitialized={this.popupShuttlesAvailableArtistContacts_onInitialized}
                    onDBError={this.popupShuttlesAvailableArtistContacts_onDBError}
                    onUpdate={this.popupShuttlesAvailableArtistContacts_OnUpdate}
                />              
            </React.Fragment>
        );
    }

}

export default PopupShuttlesArtists

