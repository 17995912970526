import React from 'react';
import ServiceShuttles from '../../../api/services/ServiceShuttles'

import globalConfig from '../../../config/global.js';

import './ShuttlesDriverSheet.scss'


// props
//      subtrips (required): array of subtrips
//
// callables
//      setData
//
// callbacks
//      onDBError

class ShuttlesDriverSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            driverSheetData: [],
        }

        this.tripRecord = [];

        this.dropoffDisplay = 'none';
        this.pickupWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';
        this.notesDisplay = 'none';
    }

    // CALLBACKS
    onDBError = () => {
        if (this.props.onDBError) {
            this.props.onDBError();  // callback
        }
    }

    // CALLABLE
    setData = async (id) => {
        await this.loadDataSourceTrip(id);

        this.setState({ driverSheetData: this.convertTripData() })
    }

    // EVENTS

    // DATA
    loadDataSourceTrip = async (id) => {
        var data = await ServiceShuttles.getTripInfo(id, false);
        if (data !== null && !data.hasOwnProperty("error")) {
            this.tripRecord = data;
        } else {
            this.onDBError();  // callback
        }
    }

    convertTripData = () => {
        let sheetData = {
            Trip: '',
            PickupDate: '',
            PickupLocationName: '',
            PickupLocationAddress: '',
            PickupWaitTime: '',
            DropOffLocationName: '',
            DropOffLocationAddress: '',
            DropOffWaitTime: '',
            DropOffLocationName2: '',
            DropOffLocationAddress2: '',
            DropOffWaitTime2: '',
            Artist: '',
            Driver: '',
            Car: '',
            Pax: '',
            TravelTime: '',
            Notes: '',
    }

        let trip = '';
        let pickupLocationAddress = '';
        for (var i = 0; i < this.tripRecord.subTrips.length; i++) {
            if (this.tripRecord.subTrips[i].isPickupDeparture === true) {
                trip = this.tripRecord.subTrips[i].locationDeparture;
                const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                let dt = new Date(this.tripRecord.subTrips[i].dateTimeDeparture);
               // sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '-' + month[dt.getMonth()] + '-' + dt.getFullYear() + ' - ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                sheetData.PickupDate = weekday[dt.getDay()] + ' ' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth()+1).toString().padStart(2, '0') + ' ' + dt.getHours().toString().padStart(2, '0') + ':' + dt.getMinutes().toString().padStart(2, '0');
                sheetData.PickupLocationName = this.tripRecord.subTrips[i].locationDeparture;
                pickupLocationAddress = (this.tripRecord.subTrips[i].locationDepartureStreet ? this.tripRecord.subTrips[i].locationDepartureStreet : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (this.tripRecord.subTrips[i].locationDepartureStreetNumber ? this.tripRecord.subTrips[i].locationDepartureStreetNumber : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ', ' : '') + (this.tripRecord.subTrips[i].locationDepartureZipCode ? this.tripRecord.subTrips[i].locationDepartureZipCode : '');
                pickupLocationAddress = pickupLocationAddress + (pickupLocationAddress !== '' ? ' ' : '') + (this.tripRecord.subTrips[i].locationDepartureCity ? this.tripRecord.subTrips[i].locationDepartureCity : '');
                sheetData.PickupLocationAddress = pickupLocationAddress;
                break;
            } 
        }
         
        let countDropoff = 0;
        let dropoffLocationAddress = '';
        let dropoffLocationAddress2 = '';
        let tripTimings = '';
        for (var i = 0; i < this.tripRecord.subTrips.length; i++) {
            if (this.tripRecord.subTrips[i].isDropOffArrival === true) {
                this.dropoffDisplay = 'none';
                trip += ' - ' + this.tripRecord.subTrips[i].locationArrival;
                countDropoff += 1;
                switch (countDropoff) {
                    case 1:
                        sheetData.DropOffLocationName = this.tripRecord.subTrips[i].locationArrival;
                        dropoffLocationAddress = (this.tripRecord.subTrips[i].locationArrivalStreet ? this.tripRecord.subTrips[i].locationArrivalStreet : '');
                        dropoffLocationAddress = dropoffLocationAddress +(dropoffLocationAddress !== '' ? ' ' : '') +  (this.tripRecord.subTrips[i].locationArrivalStreetNumber ? this.tripRecord.subTrips[i].locationArrivalStreetNumber : '');
                        dropoffLocationAddress = dropoffLocationAddress +(dropoffLocationAddress !== '' ? ', ' : '') +  (this.tripRecord.subTrips[i].locationArrivalZipCode ? this.tripRecord.subTrips[i].locationArrivalZipCode : '');
                        dropoffLocationAddress = dropoffLocationAddress + (dropoffLocationAddress !== '' ? ' ' : '') +  (this.tripRecord.subTrips[i].locationArrivalCity ? this.tripRecord.subTrips[i].locationArrivalCity : '');
                        sheetData.DropOffLocationAddress = dropoffLocationAddress;
                        tripTimings = 'trip 1: ' + (this.tripRecord.subTrips[i].minutesMin !== this.tripRecord.subTrips[i].minutesMax ? this.tripRecord.subTrips[i].minutesMin + ' - ' + this.tripRecord.subTrips[i].minutesMax + ' min' : this.tripRecord.subTrips[i].minutesMax + ' min');
                        break;
                    case 2:
                        sheetData.DropOffLocationName2 = this.tripRecord.subTrips[i].locationArrival;
                        dropoffLocationAddress2 = (this.tripRecord.subTrips[i].locationArrivalStreet ? this.tripRecord.subTrips[i].locationArrivalStreet : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 +(dropoffLocationAddress2 !== '' ? ' ' : '') + (this.tripRecord.subTrips[i].locationArrivalStreetNumber ? this.tripRecord.subTrips[i].locationArrivalStreetNumber : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 + (dropoffLocationAddress2 !== '' ? ', ' : '') + (this.tripRecord.subTrips[i].locationArrivalZipCode ? this.tripRecord.subTrips[i].locationArrivalZipCode : '');
                        dropoffLocationAddress2 = dropoffLocationAddress2 +  (dropoffLocationAddress2 !== '' ? ' ' : '') +  (this.tripRecord.subTrips[i].locationArrivalCity ? this.tripRecord.subTrips[i].locationArrivalCity : '');
                        sheetData.DropOffLocationAddress2 = dropoffLocationAddress2;
                        this.dropoffDisplay = 'flex';
                        tripTimings += ' / trip 2: ' + (this.tripRecord.subTrips[i].minutesMin !== this.tripRecord.subTrips[i].minutesMax ? this.tripRecord.subTrips[i].minutesMin + ' - ' + this.tripRecord.subTrips[i].minutesMax + ' min' : this.tripRecord.subTrips[i].minutesMax + ' min');
                        break;
                    default:
                        break;
                }
            }
        }

        if (tripTimings.search('trip 2:') === -1) {
            tripTimings = tripTimings.replace('trip 1: ', '');
        }

        let searchStart = false;
        let waitCount = 0;
        this.dropoffWaitTimeDisplay = 'none';
        this.dropoffWaitTimeDisplay2 = 'none';
        this.pickupWaitTimeDisplay = 'none'
        for (var i = 0; i < this.tripRecord.subTrips.length; i++) {
            if (this.tripRecord.subTrips[i].isPickupDeparture === true) {
                searchStart = true;
            }
            if (searchStart === true) {
                waitCount += 1;
                switch (waitCount) {
                    case 1:
                        sheetData.PickupWaitTime = this.tripRecord.subTrips[i].waitTimePickup
                        if (this.tripRecord.subTrips[i].waitTimePickup > 0) {
                            this.pickupWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 2:
                        sheetData.DropOffWaitTime = this.tripRecord.subTrips[i].waitTimePickup
                        if (this.tripRecord.subTrips[i].waitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay = 'flex';
                        }
                        break;
                    case 3:
                        sheetData.DropOffWaitTime2 = this.tripRecord.subTrips[i].waitTimePickup
                        if (this.tripRecord.subTrips[i].waitTimePickup > 0) {
                            this.dropoffWaitTimeDisplay2 = 'flex';
                        }
                        break;
                    default:
                        break;
                }
            }

        }
        sheetData.Trip = trip;
        sheetData.Artist = this.tripRecord.artistName;
        sheetData.Driver = this.tripRecord.driverName;
        sheetData.Car = this.tripRecord.carName;
        sheetData.Pax = this.tripRecord.pax;
        sheetData.TravelTime = tripTimings;
        sheetData.Notes = this.tripRecord.notes;

        this.notesDisplay = (this.tripRecord.notes !== null && this.tripRecord.notes !== '' ? 'block' : 'none');

        return sheetData;

    }
 
    renderNotes = () => {
        return <div dangerouslySetInnerHTML={{ __html: this.state.driverSheetData.notes }} />;
    }

    render() {
        return (
            <React.Fragment>
                <div className="sds-main-div">
                    <div className="sds-image-div">
                        <img src={globalConfig.settings.images.driverSheetImagesPath + "/skully_2024.png"} alt="GMM" />
                    </div>
                    <div className="sds-data-driver-div">Driver:&nbsp;{this.state.driverSheetData.Driver}</div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Trip</div>
                        <div className="sds-right-div">{this.state.driverSheetData.Trip}</div>
                    </div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Artist</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-artist">{this.state.driverSheetData.Artist}</div>
                            <div className="sds-right-div-pax">Pax: {this.state.driverSheetData.Pax}</div>
                        </div>
                    </div>
                    <div className="sds-data-3-lines-div">
                        <div className="sds-left-div">Pickup</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-pickup">{this.state.driverSheetData.PickupDate}<br />{this.state.driverSheetData.PickupLocationName}<br />{this.state.driverSheetData.PickupLocationAddress}</div>
                            <div className="sds-right-div-wait" style={{ display: this.pickupWaitTimeDisplay }}>Wait: {this.state.driverSheetData.PickupWaitTime} min</div>
                        </div>
                    </div>
                    <div className="sds-data-2-lines-div">
                        <div className="sds-left-div">Dropoff</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-dropoff">{this.state.driverSheetData.DropOffLocationName}<br />{this.state.driverSheetData.DropOffLocationAddress}</div>
                            <div className="sds-right-div-wait" style={{ display: this.dropoffWaitTimeDisplay }}>Wait: {this.state.driverSheetData.DropOffWaitTime} min</div>
                        </div>
                    </div>
                    <div className="sds-data-2-lines-div" style={{ display: this.dropoffDisplay }}>
                        <div className="sds-left-div">Dropoff</div>
                        <div className="sds-right-div">
                            <div className="sds-right-div-dropoff">{this.state.driverSheetData.DropOffLocationName2}<br />{this.state.driverSheetData.DropOffLocationAddress2}</div>
                            <div className="sds-right-div-wait" style={{ display: this.dropoffWaitTimeDisplay2 }}>Wait: {this.state.driverSheetData.DropOffWaitTime2} min</div>
                        </div>
                    </div>
                    <div className="sds-data-div">
                        <div className="sds-left-div">Car</div>
                        <div className="sds-right-div">{this.state.driverSheetData.Car}</div>
                    </div>
                    <div className="sds-data-travel-time-div">
                        <div className="sds-left-div">Traveltime</div>
                        <div className="sds-right-div">{this.state.driverSheetData.TravelTime}</div>
                    </div>
                    <div style={{ display: this.notesDisplay }}>
                        <div className="page-break" />
                        {this.renderNotes()}
                    </div>

                </div>
            </React.Fragment>
         );
    }
}

export default ShuttlesDriverSheet
