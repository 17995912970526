import Drawer from 'devextreme-react/drawer';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Header, SideNavigationMenu, Footer } from '../../components';
import './side-nav-outer-toolbar.scss';
import { useScreenSize } from '../../utils/media-query';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';




export default function SideNavOuterToolbar({ title, children }) {
    const scrollViewRef = useRef(null);
    const navigate = useNavigate();
    const { isXSmall, isLarge } = useScreenSize();
    const [patchCssClass, onMenuReady] = useMenuPatch();
    const [menuStatus, setMenuStatus] = useState(
        isLarge ? MenuStatus.Opened : MenuStatus.Closed
    );

    const toggleMenu = useCallback(({ event }) => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.Opened
                : MenuStatus.Closed
        );
        event.stopPropagation();
    }, []);


    //redirects
    const redirectShuttles = useCallback(() => {
        navigate('/shuttles');
        scrollViewRef.current.instance.scrollTo(0);
    }, [navigate]);

    const redirectArtists = useCallback(() => {
        navigate('/shuttlesadminartists');
        scrollViewRef.current.instance.scrollTo(0);
    }, [navigate]);

    const redirectDrivers = useCallback(() => {
        navigate('/shuttlesadmindrivers');
        scrollViewRef.current.instance.scrollTo(0);
    }, [navigate]);

    const redirectLocations = useCallback(() => {
        navigate('/shuttlesadminlocations');
        scrollViewRef.current.instance.scrollTo(0);
    }, [navigate]);

    const redirectFleet = useCallback(() => {
        navigate('/shuttlesadminfleet');
        scrollViewRef.current.instance.scrollTo(0);
    }, [navigate]);

    const temporaryOpenMenu = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus === MenuStatus.Closed
                ? MenuStatus.TemporaryOpened
                : prevMenuStatus
        );
    }, []);



    const onOutsideClick = useCallback(() => {
        setMenuStatus(
            prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
                ? MenuStatus.Closed
                : prevMenuStatus
        );
        return menuStatus === MenuStatus.Closed ? true : false;
    }, [isLarge, menuStatus]);

    const onNavigationChanged = useCallback(({ itemData, event, node }) => {
        if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
            event.preventDefault();
            return;
        }

        navigate(itemData.path);
        scrollViewRef.current.instance.scrollTo(0);

        if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
            setMenuStatus(MenuStatus.Closed);
            event.stopPropagation();
        }
    }, [navigate, menuStatus, isLarge]);

    return (
        <div className={'side-nav-outer-toolbar'}>
            <Header
                menuToggleEnabled
                toggleMenu={toggleMenu}
                title={title}
                redirectShuttles={redirectShuttles} 
                redirectArtists={redirectArtists}
                redirectDrivers={redirectDrivers}
                redirectLocations={redirectLocations}
                redirectFleet={redirectFleet}
            />
            <Drawer
                className={['drawer', patchCssClass].join(' ')}
                position={'before'}
                closeOnOutsideClick={onOutsideClick}
                openedStateMode={isLarge ? 'shrink' : 'overlap'}
                revealMode={isXSmall ? 'slide' : 'expand'}
                minSize={isXSmall ? 0 : 0}
                maxSize={250}
                shading={isLarge ? false : true}
                opened={menuStatus === MenuStatus.Closed ? false : true}
                template={'menu'}
            >
                <div className={'outer-container'}>
                    <ScrollView ref={scrollViewRef} className={'layout-body'}>
                        <div className={'content'}>
                            {React.Children.map(children, (item) => {
                                return item.type !== Footer && item;
                            })}
                        </div>
                        <div className={'content-block'}>
                            {React.Children.map(children, (item) => {
                                return item.type === Footer && item;
                            })}
                        </div>
                    </ScrollView>
                </div>
                <Template name={'menu'}>
                    <SideNavigationMenu
                        compactMode={menuStatus === MenuStatus.Closed}
                        selectedItemChanged={onNavigationChanged}
                        openMenu={temporaryOpenMenu}
                        onMenuReady={onMenuReady}
                    >
                    </SideNavigationMenu>
                </Template>
            </Drawer>
        </div>
    );
}

const MenuStatus = {
    Closed: 1,
    Opened: 2,
    TemporaryOpened: 3
};
