import React from 'react';
import { Users } from '../../components';

class users extends React.Component {
    render() {
        return (
            <Users />
        );
    }
}

export default users