import defaultUser from '../utils/default-user';
import ServiceUsers from './services/ServiceUsers';
import ServiceUserSettings from './services/ServiceUserSettings';
import globalConfig from '../config/global.js';

// USED WHEN SIGNING IN 
export async function signIn(email, password) {
  try {
      var user = await ServiceUsers.getUserByEmailPassword(email, password);

      if (user !== null && user !== '' && !user.hasOwnProperty("error")) {
          user.avatarUrl = globalConfig.settings.images.userImagesPath + user.avatarUrl;

          var result = await ServiceUsers.getUserAuthorizationByEmail(email);
          sessionStorage.setItem('userAuth', JSON.stringify(result));
          var resultUSG = await ServiceUserSettings.getUsersSettingsGeneralInfoByUserId(result.id);
          localStorage.setItem('userSettingsGeneral', JSON.stringify(resultUSG));
          var resultUSP = await ServiceUserSettings.getUsersSettingsInfoByUserId(result.id);
          localStorage.setItem('userSettingsPages', JSON.stringify(resultUSP));

          return {
              isOk: true,
              data: user
          };
      } else {
          return {
              isOk: false,
              message: "Authentication failed"
          };
      }
  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}


export async function getUser(id) {
    var user = await ServiceUsers.getUser(id);

    if (user !== null && user !== '' && !user.hasOwnProperty("error")) {
        user.avatarUrl = globalConfig.settings.images.userImagesPath + user.avatarUrl;

        var result = await ServiceUsers.getUserAuthorizationByEmail(user.email);
        sessionStorage.setItem('userAuth', JSON.stringify(result));
        var resultUSG = await ServiceUserSettings.getUsersSettingsGeneralInfoByUserId(result.id);
        localStorage.setItem('userSettingsGeneral', JSON.stringify(resultUSG));
        var resultUSP = await ServiceUserSettings.getUsersSettingsInfoByUserId(result.id);
        localStorage.setItem('userSettingsPages', JSON.stringify(resultUSP));

        return {
            isOk: true,
            data: user
        };
    } else {
        sessionStorage.removeItem('userAuth');
        localStorage.removeItem('userSettingsGeneral');
        localStorage.removeItem('userSettingsPages');
        return {
            isOk: false,
            message: "Failed to get user!"
        };
    }

}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
