import React from 'react';
import globalConfig from '../../../config/global.js';
import Map from 'devextreme-react/map';

import './HxMap.scss'

const mapHost = globalConfig.settings.geoCoding.geoCodingHost;
const mapKey = globalConfig.settings.geoCoding.geoCodingMapKey;
const mapProvider = globalConfig.settings.geoCoding.geoCodingMapProvider;

const defaultCenter = { lat: 51.2330401000, lng: 5.0641351000 };
const width = '200px';
const height = '200px';
const routes = [];
const markers = [];
const defaultZoom = 10;

class HxMap extends React.Component { 
    constructor(props) {
        super(props);

        this.state = {
            properties : {
                width: width,
                height: height,
                defaultCenter: defaultCenter,
                routes: routes,
                defaultZoom: defaultZoom,
                markers: markers
            }
        }
    } 

    // CALLABLES
    initialize = (width, height, defaultCenter, routes, defaultZoom, markers) => {
        const st = {
            width: width,
            height: height,
            defaultCenter: defaultCenter,
            routes: routes,
            defaultZoom: defaultZoom,
            markers: markers,
        }

        this.setState({ properties: st });
    }

    setCenter = (defaultCenter, markers) => {
        const st = this.state.properties;
        st.defaultCenter = defaultCenter;
        st.markers = markers;
        this.setState({ properties: st });
    }

    // RENDER
    render() {
        return (
            <React.Fragment>
                <div>
                    <Map
                        defaultCenter={this.state.properties.defaultCenter}
                        width={this.state.properties.width}
                        height={this.state.properties.height}
                        routes={this.state.properties.routes}
                        defaultZoom={this.state.properties.defaultZoom}
                        markers={this.state.properties.markers}
                        apiKey={mapKey}
                        provider={mapProvider}
                        type="roadmap">
                    </Map>
                </div>
            </React.Fragment>
        );
    }

}

export default HxMap;