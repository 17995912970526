//        host: "https://gmm.crossingcamel.be"
//        host: "https://localhost:44463"
//        apihost: "https://localhost:7196"

const globalConfig = {
    settings: {
        host: "https://www.gmmplanner.be",
        apihost: "https://www.gmmplanner.be",
        images: {
            userImagesPath: '/assets/images/users/',
            driverImagesPath: '/assets/images/drivers/',
            artistContactImagesPath: '/assets/images/artist-contacts/',
            driverSheetImagesPath: '/assets/images/shuttles/driver-sheet/',
        },
        login: {
            allowCreateAccount: false,
            allowResetPassword: false,
            allowChangePassword: true
        },
        geoCoding: {
            geoCodingHost: "https://dev.virtualearth.net/REST/v1",
            geoCodingMapProvider: 'bing',
            geoCodingMapKey: 'aT1auSQRpZwluq9PAQQc~6YZtMXnPRqzPDVevj7aeSw~AuINK7XZ8EJubeTRmdcB_Jo_kICaeo5sOjv3zcxm-WfZjtDWrkxSuuEF90h4TaKU',
        },
        shuttles: {
            startHour: 5,
        }
    }
};

export default globalConfig;