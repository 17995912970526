import React, { useEffect, useState } from 'react';
import GeneralFunctions from '../../../helpers/GeneralFunctions';
import './HxTags.scss';

// props
//      tags
//      title
//      useHoverStyleOnSelected
// callables
//      setTags(tags)
//      setStyle(style)
//      setHoverStyle(style)
//      setSelectedStyle(style)
//      setComponentStyle(style)
//      getContextMenuTagInfo()

class HxTags extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            enabled: true,
        };
        
        this.contextMenuModel = {
            Id: -1,
            Name: '',
        }

        this.initialStyle = {
            width: 'auto',
            height: 'auto',
            backgroundColor: 'rgba(255, 255, 255, 255)',
            color: 'rgba(255, 87, 34, 255)',
            border: '1px solid rgba(255, 87, 34, 255)',
        };
        this.hoverStyle = {
            width: 'auto',
            height: 'auto',
            backgroundColor: 'rgba(235, 187, 144, 255)',
            color: 'rgba(255, 255, 255, 255)',
            border: '1px solid rgba(255, 87, 34, 255)',
        };
        this.selectedStyle = {
            width: 'auto',
            height: 'auto',
            backgroundColor: 'rgba(255, 87, 34, 255)',
            color: 'rgba(255, 255, 255, 255)',
            border: '1px solid rgba(255, 87, 34, 255)',
        };
        this.componentStyle = {};

        // set initial style
        if (this.props.style) {
            this.setStyle(this.props.style);
        }

        // set initial hover style
        if (this.props.hoverStyle) {
            this.setHoverStyle(this.props.hoverStyle);
        }

        // set initial selected style
        if (this.props.selectedStyle) {
            this.setSelectedStyle(this.props.selectedStyle);
        }

        // set initial component style
        if (this.props.componentStyle) {
            this.setComponentStyle(this.props.componentStyle);
        }

        this.keyPrefix = this.props.keyPrefix ? this.props.keyPrefix : GeneralFunctions.generateRandomString;

    }

    componentDidMount = async () => {
        // load tags
        this.setTags(this.props.tags ? this.props.tags : []);
    }

    setStyle = (style) => {
        if (style) {
            for (const [key, value] of Object.entries(style)) {
                this.initialStyle[key] = value;
            }
        }
    }

    setHoverStyle = (style) => {
        if (style) {
            for (const [key, value] of Object.entries(style)) {
                this.hoverStyle[key] = value;
            }
        }
    }

    setSelectedStyle = (style) => {
        if (style) {
            for (const [key, value] of Object.entries(style)) {
                this.selectedStyle[key] = value;
            }
        }
    }

    setComponentStyle = (style) => {
        if (style) {
            for (const [key, value] of Object.entries(style)) {
                this.componentStyle[key] = value;
            }
        }
    }

    getContextMenuTagInfo = () => {
        return this.contextMenuModel;
    }

    setTags = (tags) => {
        this.setState({ tags: tags });

       // this.forceUpdate();
    }

    disableTags = () => {
        this.setState({ enabled: false });
    }

    enableTags = () => {
        this.setState({ enabled: true });
    }

    onClick = (e) => {
        var tagsArray = this.state.tags;
        let selected = false;

        let tag = tagsArray.find(x => x.id === parseInt(e.currentTarget.id, 10));
        if (tag) {
            tag.selected = !tag.selected;
            this.setState({ tags: tagsArray });
            selected = tag.selected;
        }

        if (this.props.onClick) {
            this.props.onClick(e, selected);
        }
    }

    onDoubleClick = (e) => {
        var tagsArray = this.state.tags;
        let tag = null;
        let selected = false;
        for (var i = 0; i < tagsArray.length; i++) {
            if (tagsArray[i].id == parseInt(e.currentTarget.id, 10)) {
                tagsArray[i].selected = true;
                selected = true;
                tag = tagsArray[i];
            } else {
                tagsArray[i].selected = false;
            }
        }
        this.setState({ tags: tagsArray });

         if (this.props.onDoubleClick) {
             this.props.onDoubleClick(e, tag, selected);
        }
    }

    onMouseOver = (e) => {
        let tag = this.state.tags.find(x => x.id === parseInt(e.currentTarget.id, 10));
        if (tag.selected) {
            if (this.selectedStyle.width) {
                e.currentTarget.style.width = (this.props.useHoverStyleOnSelected ? this.hoverStyle.width : this.selectedStyle.width);
            }
            if (this.selectedStyle.height) {
                e.currentTarget.style.height = (this.props.useHoverStyleOnSelected ? this.hoverStyle.height : this.selectedStyle.height);
            }
            if (this.selectedStyle.backgroundColor) {
                e.currentTarget.style.backgroundColor = (this.props.useHoverStyleOnSelected ? this.hoverStyle.backgroundColor : this.selectedStyle.backgroundColor);
            }
            if (this.selectedStyle.color) {
                e.currentTarget.style.color = (this.props.useHoverStyleOnSelected ? this.hoverStyle.color : this.selectedStyle.color);
            }
            if (this.selectedStyle.border) {
                e.currentTarget.style.border = (this.props.useHoverStyleOnSelected ? this.hoverStyle.border : this.selectedStyle.border);
            }
        } else {
            if (this.hoverStyle.width) {
                e.currentTarget.style.width = this.hoverStyle.width;
            }
            if (this.hoverStyle.height) {
                e.currentTarget.style.height = this.hoverStyle.height;
            }
            if (this.hoverStyle.backgroundColor) {
                e.currentTarget.style.backgroundColor = this.hoverStyle.backgroundColor;
            }
            if (this.hoverStyle.color) {
                e.currentTarget.style.color = this.hoverStyle.color;
            }
            if (this.hoverStyle.border) {
                e.currentTarget.style.border = this.hoverStyle.border;
            }
        }
        e.currentTarget.classList.add('hx-tags-display-context')
    }

    onMouseOut = (e) => {
        let tag = this.state.tags.find(x => x.id === parseInt(e.currentTarget.id, 10));
        if (tag.selected) {
            if (this.selectedStyle.width) {
                e.currentTarget.style.width = this.selectedStyle.width;
            }
            if (this.selectedStyle.height) {
                e.currentTarget.style.height = this.selectedStyle.height;
            }
            if (this.selectedStyle.backgroundColor) {
                e.currentTarget.style.backgroundColor = this.selectedStyle.backgroundColor;
            }
            if (this.selectedStyle.color) {
                e.currentTarget.style.color = this.selectedStyle.color;
            }
            if (this.selectedStyle.border) {
                e.currentTarget.style.border = this.selectedStyle.border;
            }
        } else {
            if (this.initialStyle.width) {
                e.currentTarget.style.width = this.initialStyle.width;
            }
            if (this.initialStyle.height) {
                e.currentTarget.style.height = this.initialStyle.height;
            }
            if (this.initialStyle.backgroundColor) {
                e.currentTarget.style.backgroundColor = this.initialStyle.backgroundColor;
            }
            if (this.initialStyle.color) {
                e.currentTarget.style.color = this.initialStyle.color;
            }
            if (this.initialStyle.border) {
                e.currentTarget.style.border = this.initialStyle.border;
            }
        }
        e.currentTarget.classList.remove('hx-tags-display-context')

    }

    onMouseDown = (e) => {
        if (e.button == 2) {
            console.log(e.currentTarget.innerText);
            this.contextMenuModel = {
                Id: e.currentTarget.id,
                Name: e.currentTarget.innerText,
            }
        }
    }

    // return rendering
    renderTags = () => {

        let tags = [];
        let tagsArr = [];
        let className = '';
        const keyTitle = this.keyPrefix + 'title';
        const keyTags = this.keyPrefix + 'tags';

        if (this.props.title && this.props.title.length > 0) {
            tags.push(<div key={keyTitle} id={keyTitle} className='hx-tags-title'>{this.props.title}:</div>);
        }

        for (let i = 0; i < this.state.tags.length; i++) {
            const key = this.keyPrefix + this.state.tags[i].id;
            if (this.state.tags[i].selected === true) {
                className = this.state.enabled == true ? 'hx-tags-enabled-selected hx-tags-display-context hx-tags-noselect' : 'hx-tags-disabled hx-tags-noselect';
                tagsArr.push(<div key={key} id={this.state.tags[i].id} onClick={this.onClick} onDoubleClick={this.onDoubleClick} style={this.selectedStyle} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onMouseDown={this.onMouseDown} className={className}>{this.state.tags[i].text}</div>);
            } else {
                className = this.state.enabled == true ? 'hx-tags-enabled-not-selected hx-tags-noselect' : 'hx-tags-disabled hx-tags-noselect';
                tagsArr.push(<div key={key} id={this.state.tags[i].id} onClick={this.onClick} onDoubleClick={this.onDoubleClick } style={this.initialStyle} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} onMouseDown={this.onMouseDown} className={className}>{this.state.tags[i].text}</div>);
            }
        }

        tags.push(<div id={keyTags} key={keyTags} className='hx-tags' style={this.componentStyle}>{tagsArr}</div>)

        const tagsOut = <div style={{ display: 'flex', alignItems: 'center' }}>{tags}</div>;


        return tagsOut;
    }

    render() {
        return (
            <React.Fragment>
                {this.renderTags()}
            </React.Fragment>
        );

    }

}

export default HxTags

