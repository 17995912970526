import React from 'react';
import { UserProfile } from '../../../components';

class userProfile extends React.Component {
    render() {
        return (
            <UserProfile />
        );
    }
}

export default userProfile