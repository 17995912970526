import React from 'react';
import HxToast from '../../custom-components/hx-toast/HxToast';

import UserSettingsGeneral from "./sub-components/UserSettingsGeneral";

import './UserSettings.scss'

class UserSettings extends React.Component {
    constructor(props) {
        super(props);

        // filters
        this.loadedUserFilterValue = [];
    }
 
    userSettingsGeneral_OnDBError = (message) => {
        HxToast.showToast(message, 'error', 'top center', 'up-stack');
    }

    render() {

        return (
            <React.Fragment>
                <div className='content-block'>
                    <div className="">
                        <UserSettingsGeneral
                            onDBError={this.userSettingsGeneral_OnDBError}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default UserSettings

