import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import ReactToPrint from 'react-to-print';

import ShuttlesDriverSheet from './ShuttlesDriverSheet';

import './PopupPrintDriverSheet.scss';

// props
//
// callables
//      preload
//
// callbacks
//      onInitialized

class PopupPrintDriverSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
        }
 
        this.popupInstance = null;
        this.timing = '';
    }

    // CALLBACKS
    onInitialized = (instance) => {
        if (this.props.onInitialized) {
            this.props.onInitialized(instance);  // callback
        }
    }

    // CALLABLE
    preload = async (data) => {
        this.setState({ dataSource: data });

        let start = new Date(data.dateTimeDeparture);
        let end = new Date(data.dateTimeArrival);
        this.timing = start.getHours().toString().padStart(2, '0') + ':' + start.getMinutes().toString().padStart(2, '0') + ' - ' + end.getHours().toString().padStart(2, '0') + ':' + end.getMinutes().toString().padStart(2, '0');
    }

    // EVENTS

    // DATA

    // POPUP
    popup_OnInitialized = (e) => {
        this.popupInstance = e.component;

        // set callback
        this.onInitialized(e.component);
    }

    popup_OnShown = async (e) => {
        await this.refDriverSheet.setData(this.state.dataSource.id);
    }

    refButtonPrint_OnClick = (e) => {
        this.popupInstance.hide();
    }

    render() {
        return (
            <Popup
                width={250}
                height={165}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                onInitialized={this.popup_OnInitialized}
                onShown={this.popup_OnShown}
                title='Driversheet'
            >
                <div>
                    <div>{this.state.dataSource.artistName}</div>
                    <div>{this.state.dataSource.locationDepartureName} - {this.state.dataSource.locationArrivalName}</div>
                    <div>{this.timing}</div>
                    <ReactToPrint
                        trigger={() => <div style={{ display: 'flex', justifyContent: 'end' }}><Button
                            ref={(ref) => this.refButtonPrint = ref}
                            icon='print'
                            stylingMode='text'
                            text=''
                            onClick={this.refButtonPrint_OnClick}
                        /></div>}
                        content={() => this.refDriverSheet}
                        removeAfterPrint={true}
                    />
                    <div style={{ display: "none" }}>
                        <ShuttlesDriverSheet
                            ref={ref => (this.refDriverSheet = ref)}
                        />
                    </div>

                </div>
            </Popup>
        );
    }
}

export default PopupPrintDriverSheet
